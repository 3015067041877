import React from 'react'
import '../../assets/css/main.css'
import '../../assets/css/withBanner.css'
import withBanner1Image from '../../assets/images/with_banner_1.svg'
import withBanner2Image from '../../assets/images/with_banner_2.svg'
import withBanner3Image from '../../assets/images/with_banner_3.svg'
import analysisMobileImage from '../../assets/images/analysis_mobile.png'
import analysisImage from '../../assets/images/analysis.jpg'

import analysis_1 from '../../assets/images/Analysis_1.png'
import analysis_2 from '../../assets/images/Analysis_2.png'
import analysis_3 from '../../assets/images/Analysis_3.png'
import analysisMobile_1 from '../../assets/images/AnalysisMobile_1.png'
import Container from "../Container";
import {useTranslation} from "react-i18next";


const WithBanner = () => {
	const {t, i18n} = useTranslation('translation', {keyPrefix: 'main.withBanner'})
	return (
		<div className="pb-10 md:pb-32 pt-8 relative">
			<div className='hidden xl:block absolute top-[-2em] left-[calc(50%-22em)]'>
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
						 xmlns="http://www.w3.org/2000/svg">
					<circle cx="12" cy="12" r="12" fill="#50D3FF"/>
				</svg>
			</div>
			<div className='hidden xl:block absolute top-[-2em] left-[calc(50%-44em)]'>
				<svg width="267" height="267" viewBox="0 0 267 267" fill="none"
						 xmlns="http://www.w3.org/2000/svg">
					<circle cx="133.5" cy="133.5" r="133.5" fill="#D1F7DC"/>
				</svg>
			</div>
			<Container>
				<div className="flex xl:flex-row flex-col-reverse relative">
					<div
						className="select-none relative flex gap-6 md:gap-14 flex-col justify-center w-full xl:basis-[50%]">
                        <span
													className='font-bold text-[16px] md:text-[36px] text-[#1B1818] text-left md:leading-[42.3px] leading-[18.8px]'>
                            <span
															className={`text-[#393DA1] font-bold text-[16px] md:text-[40px]`}>
                                {t('title.0')}</span> {t('title.1')}
                        </span>
						<div className="flex flex-col d:grid md:grid-rows-3 gap-y-8 pointer-events-none select-none">
							<div className='flex items-center flex-row gap-4 md:gap-8'>
								<img src={withBanner1Image} className='size-[30px] md:size-[50px]'/>
								<div
									className='text-left font-normal text-[12px] md:text-[20px] md:leading-[23.5px] leading-[14.1px] text-[#3F3F3F]/80'>
									1.&nbsp;{t('defines.0')}
								</div>
							</div>
							<div className='flex items-center flex-row gap-4 md:gap-8'>
								<img src={withBanner2Image} className='size-[30px] md:size-[50px]'/>
								<div
									className='text-left font-normal text-[12px] md:text-[20px] md:leading-[23.5px] leading-[14.1px] text-[#3F3F3F]/80'>
									2.&nbsp;{t('defines.1')}
								</div>
							</div>
							<div className='flex items-center flex-row gap-4 md:gap-8'>
								<img src={withBanner3Image} className='size-[30px] md:size-[50px]'/>
								<div
									className='text-left font-normal text-[12px] md:text-[20px] md:leading-[23.5px] leading-[14.1px] text-[#3F3F3F]/80'>
									3.&nbsp;{t('defines.2')}
								</div>
							</div>
						</div>
					</div>
					{/* картинки и кружочки рядом для XL+ */}
					<div
						className="pointer-events-none select-none xl:block 3xl:scale-100 2xl:scale-[95%] scale-[68%] hidden absolute right-0 inset-y-0 top-[3em]">
						<img src={analysis_3}
								 className='relative top-[-6em] h-[12em] z-[20]'/>
						<img src={analysis_2}
								 className='relative top-[-7em] left-[-10em] h-[17em] z-[20]'/>
						<img src={analysis_1}
								 className='relative right-[-8em] bottom-[26em] h-[20em] z-[20]'/>
						<div
							className="absolute rounded-full bg-[#47DAAC] size-[24px] bottom-[5em] left-[-12em] z-[10]"></div>
						<div
							className="absolute rounded-full bg-[#FFD542] size-[73px] bottom-[-2em] left-[3em] z-[10]"></div>
						<div
							className="absolute rounded-full bg-[#EB5652] size-[30px] bottom-[5em] right-[-10em] z-[10]"></div>
						<div
							className="absolute rounded-full bg-[#393DA1] size-[264px] top-[-6em] right-[-10em] z-[10]"></div>
					</div>

					{/* картинка для мобилки */}
					<div
						className="xl:hidden relative pt-4 h-[70vw]">
						<div className={`h-[25vw] relative w-fit left-[22%] top-0`}>
							<img src={analysis_3}
									 className='relative z-[20] h-full object-cover'/>
						</div>
						<div className={`h-[35vw] relative w-fit left-0 bottom-[2em]`}>
							<img src={analysis_2}
									 className='relative z-[21] h-full object-cover'/>
						</div>
						<div className={`h-[35vw] relative w-fit left-[45%] bottom-[40vw]`}>
							<img src={analysis_1}
									 className='relative z-[21] h-full object-cover'/>
						</div>
					</div>
				</div>
			</Container>
		</div>
	);
}

export default WithBanner;
