function validateName(name, errors, messages) {
    if (name.trim().length < 1) errors.name.push(messages["blankName"])
    else if (name.trim().length > 30) errors.name.push(messages["makeNameShorter"])
}

function validateSurname(surname, errors, messages) {
    if (surname.trim().length < 1) errors.surname.push(messages["blankSurname"])
    else if (surname.trim().length > 30) errors.surname.push(messages["makeSurnameShorter"])
}

function validatePhone(phone, errors, messages) {
    const phoneRegex = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/
    if (phone.trim().length < 1) errors.phone.push(messages["blankPhone"])
    else if (!phoneRegex.test(phone)) errors.phone.push(messages["incorrectPhone"]);
}

function validateEmail(email, errors, messages) {
    const emailRegex = /^(?=.{1,256})(?=.{1,64}@.{1,255}$)[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/gm
    if (email.trim().length < 1) errors.email.push(messages["blankEmail"])
    else if (!emailRegex.test(email)) errors.email.push(messages["incorrectEmail"]);
}

export function validateForm(name, surname, phone, email, validationMessages) {
    let errors = {
        name: [],
        surname: [],
        phone: [],
        email: []
    }
    validateName(name, errors, validationMessages.name)
    validateSurname(surname, errors, validationMessages.surname)
    validatePhone(phone, errors, validationMessages.phone)
    validateEmail(email, errors, validationMessages.email)
    return errors
}