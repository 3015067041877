import React from 'react';

function ArticleFragment({fragmentName, fragmentContent, hasImage, fragmentImage}, key) {
    return (
        <div className={`flex flex-col gap-4`}>
            <div className="w-full flex justify-center">
                {
                    hasImage && fragmentImage ?
                        <img loading={`lazy`} src={fragmentImage}
                             className='pointer-events-none select-none rounded-[12px] object-cover 3xl:w-4/5 mx-auto'
                             alt={`Article image of fragment ${key}`}/> :
                        (hasImage && !fragmentImage) &&
                        <div className={`bg-gray-300 animate-pulse w-full h-[50vh] rounded-[12px]`}></div>
                }
            </div>
            <div className="flex flex-col gap-2 text-[#3E3232]">
                <span
                    className='font-[700] text-[20px] leading-[23.5px]'>{fragmentName}</span>
                <ul className={`text-[18px] font-[400] leading-[30px] text-justify flex flex-col gap-2`}>
                    {fragmentContent.map((contentPart, index) => {
                        return (<div key={index}>
                            <li>{contentPart}</li>
                        </div>)
                    })}
                </ul>
            </div>
        </div>
    )
}

export default ArticleFragment;