import EditProfService from "../services/editProf.service";
import {
	EDIT_FAILURE,
	EDIT_SUCCESS,
} from "./actionTypes/editProfActionTypes";

export const editProf = (userData) => (dispatch) => {
	return EditProfService.edit(userData).then(
		(data) => {
			dispatch({
				type: EDIT_SUCCESS,
				payload: {user: data},
			});

			return Promise.resolve();
		},
		(error) => {
			const message =
				(error.response &&
					error.response.data &&
					error.response.data.message) ||
				error.message ||
				error.toString();

			dispatch({
				type: EDIT_FAILURE,
				error: message
			});

			return Promise.reject();
		}
	);
};