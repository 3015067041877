import React from 'react'
import {useState} from 'react'
import '../../assets/css/loginStudent.css'
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/24/solid'
import LoginStudentMobile from './LoginStudentMobile'
import LoginProfMobile from './LoginProfMobile'
import Container from "../Container";
import {useTranslation} from "react-i18next";

const LoginMobile = ({switchForm}) => {
    const {t, i18n} = useTranslation('translation', {keyPrefix: 'login'})

    const [isStudentLogin, setIsStudentLogin] = useState(switchForm);

    const changeLoginMobile = () => {
        setIsStudentLogin(_ => !isStudentLogin);
    };

    return (
        <>
            <div className='flex flex-col bg-[#DBF7FF] py-5 text-left'>
                <Container>
                    <span className='font-bold text-[16px]'>{isStudentLogin ? t('isProf') : t('isNotProf')}</span>
                    <div className=''>
                        <p className={`text-[14px]`}>
                            {isStudentLogin ? t('ifProf') : t('ifNotProf')}
                        </p>
                    </div>
                    <div className='flex justify-end'>
                        <button id="loginToggleButton" onClick={changeLoginMobile}
                                className={`flex items-center rounded-full mt-4 text-[#FFF] bg-[#426BD4] font-[600]`}
                        >{isStudentLogin
                            ?
                            <div className={`flex flex-row gap-1 py-2 px-4 items-center`}>
                                {t('toProf')}<ChevronRightIcon className="size-8 text-white"/>
                            </div>
                            :
                            <div className={`flex flex-row gap-1 py-2 px-4 items-center`}>
                                <ChevronLeftIcon className="size-8 text-white"/>{t('toStudent')}
                            </div>
                        }
                        </button>
                    </div>
                </Container>
            </div>
            {isStudentLogin ? <LoginStudentMobile/> : <LoginProfMobile/>}
        </>
    );
};

export default LoginMobile;
