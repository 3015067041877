import carouselHuman_3 from '../../../assets/images/carouselHuman_3.png'
import circle1 from '../../../assets/images/slide3_circle1.png'
import circle2 from '../../../assets/images/slide3_circle2.png'
import circle3 from '../../../assets/images/slide3_circle3.png'

import Slide from "./Slide";
import React from 'react';
import carouselIntersect_3 from "../../../assets/images/carouselIntersect_3.png";
import {useTranslation} from "react-i18next";


export default function ThirdSlide() {
	const {t, i18n} = useTranslation('translation', {keyPrefix: 'main.thirdSlide'})

	return (
		<Slide>
			<div
				className={`lg:block hidden relative select-none fhd:px-[14em] px-[6em]`}>
				<div
					className="flex flex-col gap-8 relative fhd:max-w-[1600px] qhd:max-w-[1700px] mx-auto pt-[5em] pb-[1em] text-left z-[10]">
					<div className={`font-bold text-[60px] text-[#464255] uppercase leading-[70.5px]`}>
						{t('name.0')}<span className={`text-[#393DA1]`}>{t('name.1')}</span><br/>{t('name.2')}
					</div>
					<div className={`font-montserrat font-[500] text-[20px] leading-[34px] text-[#464255]`}>
						{t('speech.0')}<span className={`text-[#393DA1] font-bold`}>{t('speech.1')}</span>
						{t('speech.2')}
					</div>
					<div
						className={`3xl:w-[60%] w-full relative *:bg-[#F4F4F4] *:rounded-[15px] *:p-[20px] min-h-[35em]`}>
						<div
							className={`w-[294px] absolute left-0 top-[10em]`}>
							<div className="flex flex-col gap-4">
								<div className="font-bold text-[20px] leading-[23.5px]">{t('card1.title')}</div>
								<div className="text-[12px] leading-[14.52px]">
									{t('card1.text')}
								</div>
							</div>
							<div
								className="absolute bottom-[75%] left-[85%] rounded-full size-[156px] bg-[#F4F5F5]">
								<img src={circle1} alt=""
										 className={`size-[109px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 drop-shadow-md`}/>
								<div
									className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col`}>
									<div className={`font-extrabold text-[20px] leading-[23.5px]`}>35%</div>
									<div className={`font-[500] text-[12px] leading-[14.1px]`}>{t('card1.circle')}</div>
								</div>
							</div>
						</div>
						<div
							className={`xl:w-[386px] w-[28vw] absolute right-0 xl:right-auto xl:left-[36em] top-0`}>
							<div className="flex flex-col gap-4">
								<div className="font-bold text-[20px] leading-[23.5px]">{t('card2.title')}</div>
								<div className="text-[12px] leading-[14.52px]">
									{t('card2.text')}
								</div>
							</div>
							<div
								className="absolute top-1/2 -translate-y-1/2 right-[calc(100%-20px)] rounded-full size-[150px] bg-[#F4F5F5]">
								<img src={circle2} alt=""
										 className={`size-[104.8px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 drop-shadow-md`}/>
								<div
									className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col`}>
									<div className={`font-extrabold text-[20px] leading-[23.5px]`}>42%</div>
									<div className={`font-[500] text-[12px] leading-[14.1px]`}>{t('card3.circle')}</div>
								</div>
							</div>
						</div>
						<div
							className={`w-[294px] absolute top-[16em] left-[50%] xl:left-[30em]`}>
							<div className="flex flex-col gap-4">
								<div
									className="font-bold text-[20px] leading-[23.5px] ps-[1em]">{t('card3.title')}</div>
								<div className="text-[12px] leading-[14.52px]">
									{t('card3.text')}
								</div>
							</div>
							<div
								className="absolute bottom-[75%] right-[85%] rounded-full size-[118px] bg-[#F4F5F5]">
								<img src={circle3} alt=""
										 className={`size-[82.45px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 drop-shadow-md`}/>
								<div
									className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col`}>
									<div className={`font-extrabold text-[20px] leading-[23.5px]`}>27%</div>
									<div className={`font-[500] text-[12px] leading-[14.1px]`}>{t('card3.circle')}</div>
								</div>
							</div>
						</div>
					</div>
					<img src={carouselHuman_3} alt=""
							 style={{transform: 'rotateY(180deg)'}}
							 className="absolute bottom-0 right-[0em] fhd:right-[-8em] object-cover hidden 2xl:block h-[55%] 3xl:h-[60%] fhd:h-[70%]"/>
				</div>
			</div>

			{/* mobile */}
			<div className={`bg-white lg:hidden relative select-none`}>
				<div className={`relative mx-auto sm:px-4 px-2 container`}>
					<div
						className="flex flex-col gap-4 relative sm:px-[3em] pt-[2.5em] pb-[5em] text-left z-[10] h-fit">
						<div className="flex flex-row w-full">
							<div className="flex flex-col gap-4 w-full *:w-full">
								<div
									className={`font-bold text-[20px] md:text-[40px] md:leading-[46px] text-[#464255] uppercase leading-[23.5px] `}>
									{t('name.0')}<span
									className={`text-[#393DA1]`}>{t('name.1')}</span>
									<div className={`pe-[2em]`}>{t('name.2')}</div>
								</div>
								<div
									className={`font-[500] text-[12px] md:text-[18px] leading-[17px] md:leading-[24px] text-[#464255] pe-[2.9em]`}>
									{t('speech.0')}<span className={`text-[#393DA1] font-bold`}>{t('speech.1')}</span>
									{t('speech.2')}
								</div>
							</div>
							<div className={`basis-[50%] relative md:scale-[150%] md:translate-y-[2.5em]`}>
								<div className={`absolute size-[166px] xs:right-0 right-[-37px]`}>
									<div
										className={`bg-[#50D3FF] rounded-full size-[166px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`}></div>
									<div
										className={`bg-white rounded-full size-[162px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`}></div>
									<div
										className={`bg-[#393DA1] rounded-full size-[150px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`}></div>
									<img src={carouselIntersect_3} alt=""
											 className={`absolute h-[175px] top-1/2 left-1/2 -translate-x-[calc(50%+2px)] -translate-y-[calc(50%+12px)]`}/>
								</div>
							</div>
						</div>
						<div className="grid grid-rows-1 gap-10 z-[10] mt-16 md:mt-32 text-black ps-2">
							<div className="p-2 rounded-[15px] bg-[#F4F4F4] relative">
								<div
									className={`absolute top-[-2.2em] left-[-.6em] bg-[#F4F4F4] rounded-full size-[98px]`}>
									<img src={circle1} alt=""
											 className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 size-[66px]`}/>
									<div
										className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
                                        font-sans text-[10px] leading-[11.75px] text-center flex flex-col`}>
										<span className={`font-[800]`}>35%</span>
										<span className={`font-[500]`}>{t('card1.circle')}</span>
									</div>
								</div>
								<div
									className={`rounded-full bg-transparent w-[98px] h-[53px] float-left`}
									style={{shapeOutside: 'circle(50%)'}}></div>
								<span
									className={`font-bold text-[16px] md:text-[20px] leading-[18.8px]`}>{t('card1.title')}</span>
								<p className={`font-normal text-[12px] leading-[14.52px] text-justify`}>
									{t('card1.text')}
								</p>
							</div>
							<div className="p-2 rounded-[15px] bg-[#F4F4F4] relative">
								<div
									className={`absolute top-[-2.2em] right-[-.6em] bg-[#F4F4F4] rounded-full size-[98px]`}>
									<img src={circle2} alt=""
											 className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 size-[66px]`}/>
									<div
										className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
                                        font-sans text-[10px] leading-[11.75px] text-center flex flex-col`}>
										<span className={`font-[800]`}>42%</span>
										<span className={`font-[500]`}>{t('card2.circle')}</span>
									</div>
								</div>
								<div
									className={`rounded-full bg-transparent w-[98px] h-[53px] float-right`}
									style={{shapeOutside: 'circle(50%)'}}></div>
								<span
									className={`font-bold text-[16px] leading-[18.8px]`}>{t('card2.title')}</span>
								<p className={`font-normal text-[12px] leading-[14.52px] text-justify`}>
									{t('card2.text')}
								</p>
							</div>
							<div className="p-2 rounded-[15px] bg-[#F4F4F4] relative">
								<div
									className={`absolute top-[-2.2em] left-[-.6em] bg-[#F4F4F4] rounded-full size-[98px]`}>
									<img src={circle3} alt=""
											 className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 size-[66px]`}/>
									<div
										className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
                                        font-sans text-[10px] leading-[11.75px] text-center flex flex-col`}>
										<span className={`font-[800]`}>27%</span>
										<span className={`font-[500]`}>{t('card3.circle')}</span>
									</div>
								</div>
								<div
									className={`rounded-full bg-transparent w-[98px] h-[53px] float-left`}
									style={{shapeOutside: 'circle(50%)'}}></div>
								<span
									className={`font-bold text-[16px] leading-[18.8px]`}>{t('card3.title')}</span>
								<p className={`font-normal text-[12px] leading-[14.52px] text-justify`}>
									{t('card3.text')}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Slide>
	);
}
