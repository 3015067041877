import React, {useState} from 'react'
import '../../assets/css/faq.css'
import Navbar from './Navbar';
import {ChevronRightIcon} from '@heroicons/react/24/solid'
import Container from "../Container";
import Footer from "../main/Footer";
import {useTranslation} from "react-i18next";

const FAQ = () => {
	const {t, i18n} = useTranslation('translation', {keyPrefix: 'faq'})

	const [isOpen, setIsOpen] = useState({
		0: false,
		1: false,
		2: false,
		3: false
	});

	// Функция для переключения состояния блока
	const toggleOpen = (index) => {
		setIsOpen(prevState => ({
			...prevState,
			[index]: !prevState[index]
		}));
	};

	// Массив с текстами для каждого блока FAQ
	const faqQuestions = t('questions', {returnObjects: true});

	const faqAnswers = t('answers', {returnObjects: true});

	return (
		<div className={`bg-[#EAF8FC]`}>
			{/*<Navbar/>*/}
			<Container>
				<div className="flex flex-col md:gap-8 gap-4 min-h-[calc(100vh-8em)] pb-12">
					<h1 className='font-bold text-[20px] leading-[23.5px] md:text-[36px]'>{t('title')}</h1>
					<div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
						<div className="lg:col-span-2 flex flex-col gap-4">
							{faqQuestions.map((question, index) => (
								<div key={index}
										 className={`bg-white text-left transition-all rounded-xl items-center overflow-hidden shadow-lg flex flex-col justify-between
                                     ${isOpen[index] && 'ring-1 md:ring-2 ring-[#393DA1]'}`}>
									<div
										className={`flex-row break-word w-full text-left md:px-8 md:pt-8 px-4 pt-4 flex justify-between
                                        ${!isOpen[index] ? 'pb-4 md:pb-8' : 'pb-2'}`}>
										<p
											className="text-[#1B1818] font-bold text-[18px] md:text-[22px] leading-[28px] pe-[1em] break-words">{question}</p>
										<div>
											<button
												className={`shadow-[0px_5px_16px_0px_rgba(8,_15,_52,_0.06)] flex justify-center transition-all  items-center rounded-full min-h-10 min-w-10 size-10 text-[20px]
                                            ${isOpen[index] && 'bg-[#393DA1]'}`}
												onClick={() => toggleOpen(index)}>
												<ChevronRightIcon className={`size-6 text-[#393DA1] transition-all 
                                             ${isOpen[index] && 'rotate-90  text-white'}`}/>
											</button>
										</div>
									</div>
									<ul
										className={`text-gray-500 ${isOpen[index] ? '' : 'hidden'} w-full md:px-8 md:pb-8 px-4 pb-4 text-[18px] leading-[30px]`}>{
										faqAnswers[index].map((answer, index) => {
											return <li key={index}>{answer}</li>
										})
									}</ul>
								</div>
							))}
						</div>
						<div className="lg:col-span-1">
							<div
								className="select-none p-4 text-left flex flex-col gap-5 bg-white rounded-xl overflow-hidden shadow-lg">
								<h2 className="font-bold text-[18px] md:text-[24px] text-center">
									{t('form.title')}</h2>
								<div className="text-[16px] flex flex-col gap-2">
									<label htmlFor="question" className="block font-bold">{t('form.email')}</label>
									<input type="text" placeholder="email@example.com" id="question" name="question"
												 className="text-sm p-2 placeholder:text-[#848484] border-none rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 bg-[#F5F8FF]"/>
								</div>
								<div className="text-[16px] flex flex-col gap-2">
									<label htmlFor="email" className="block font-bold">{t('form.question')}</label>
									<textarea placeholder={t('form.questionPlaceholder')} id="email" name="email"
														className="text-sm p-2 placeholder:text-[#848484] border-none rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 bg-[#F5F8FF]"
														rows="4"></textarea>
								</div>
								<div className="flex justify-end w-full">
									<button
										className="text-[16px] rounded-[56px] px-8 py-4 md:px-[32px]  md:py-[16px] font-[700] w-fit bg-[#393DA1] text-white hover:bg-[#393DA1]/80 transition-colors focus:outline-none">
										{t('form.submit')}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Container>
		</div>
	);
}

export default FAQ;
