const initialState = {
	userAdded: false,
	loading: false,
	error: null,
}

export default function (state = initialState, action) {
	const {type, payload} = action;

	switch (type) {
		case 'REGISTER_SUCCESS':
			return {
				...state,
				userAdded: true,
			};
		case 'REGISTER_FAILURE':
			return {
				...state,
				userAdded: false,
				error: action.error
			};
		default:
			return state;
	}
}
