import React, {useState} from 'react';
import '../../../assets/css/main.css';
import {ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/24/solid';
import {Link} from 'react-router-dom';
import {useTranslation} from "react-i18next";


const SaveProfile = () => {
	const {t, i18n} = useTranslation('translation', {keyPrefix: 'dashboard.changeProfile'})
	
	const [isOpen, setIsOpen] = useState(false);

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div className="container-dashboard mx-auto flex flex-col">
			<div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-40">
				<div className="col-span-1 md:col-span-2">
					<h3 className=''>{ t('backToProfile') }</h3>
				</div>
				<div className="col-span-1 md:col-span-1">
					<div className="bg-white p-6 rounded-lg shadow-md">
						<span className="text-[20px] font-[600] mb-3">{ t('initialScoring') }</span>
						<div className='flex flex-col justify-center'>
							<div className='flex justify-between my-2'>
								<span className=''>{ t('businessAndAdministration') }</span>
								<input type="text" className='w-7 px-1 border-solid border-2 rounded-lg' value="4"/>
							</div>
							<div className='flex justify-between my-2'>
								<span className=''>{ t('healthcare') }</span>
								<input type="text" className='w-7 px-1 border-solid border-2 rounded-lg' value="4"/>
							</div>
							<div className='flex justify-between my-2'>
								<span className=''>{ t('education') }</span>
								<input type="text" className='w-7 px-1 border-solid border-2 rounded-lg' value="4"/>
							</div>
							<div className='flex justify-between my-2'>
								<span className=''>{ t('sciMathStat') }</span>
								<input type="text" className='w-7 px-1 border-solid border-2 rounded-lg' value="4"/>
							</div>
							<div className='flex justify-between my-2'>
								<span className=''>{ t('engineering') }</span>
								<input type="text" className='w-7 px-1 border-solid border-2 rounded-lg' value="4"/>
							</div>
							<div className='flex justify-between my-2'>
								<span className=''>{ t('IT') }</span>
								<input type="text" className='w-7 px-1 border-solid border-2 rounded-lg' value="4"/>
							</div>
							<div className='flex justify-between my-2'>
								<span className=''>{ t('socHuman') }</span>
								<input type="text" className='w-7 px-1 border-solid border-2 rounded-lg' value="4"/>
							</div>
							<div className='flex justify-between my-2'>
								<span className=''>{ t('arts') }</span>
								<input type="text" className='w-7 px-1 border-solid border-2 rounded-lg' value="4"/>
							</div>
							<div className='flex justify-between my-2'>
								<span className=''>{ t('sport') }</span>
								<input type="text" className='w-7 px-1 border-solid border-2 rounded-lg' value="4"/>
							</div>
							<div className='flex justify-between my-2'>
								<span className=''>{ t('services') }</span>
								<input type="text" className='w-7 px-1 border-solid border-2 rounded-lg' value="4"/>
							</div>
							<div className='flex justify-between my-2'>
								<span className=''>{ t('agriculture') }</span>
								<input type="text" className='w-7 px-1 border-solid border-2 rounded-lg' value="4"/>
							</div>
							<div className='flex justify-between my-2'>
								<span className=''>{ t('natDefense') }</span>
								<input type="text" className='w-7 px-1 border-solid border-2 rounded-lg' value="4"/>
							</div>
						</div>
					</div>
				</div>

				<div className="col-span-1 md:col-span-1">
					<div className="bg-white p-6 rounded-lg shadow-md">
						<span className="text-[20px] font-[600] mb-3">{ t('selectedSpheres') }</span>
						<div className='flex flex-col justify-center'>
							<div className='flex justify-between my-2'>
								<label
									className="inline-block pl-[0.15rem] hover:cursor-pointer"
									htmlFor="checkboxDefault">
									{ t('businessAndAdministration') }
								</label>
								<input
									className="accent-pink-500"
									type="checkbox"
									value=""
									id="checkboxDefault"/>
							</div>
							<div className='flex justify-between my-2'>
								<label
									className="inline-block pl-[0.15rem] hover:cursor-pointer"
									htmlFor="2">
									{ t('healthcare') }
								</label>
								<input
									className="accent-pink-500"
									type="checkbox"
									value=""
									id="2"/>
							</div>
							<div className='flex justify-between my-2'>
								<label
									className="inline-block pl-[0.15rem] hover:cursor-pointer"
									htmlFor="3">
									{ t('education') }
								</label>
								<input
									className="accent-pink-500"
									type="checkbox"
									value=""
									id="3"/>
							</div>
							<div className='flex justify-between my-2'>
								<label
									className="inline-block pl-[0.15rem] hover:cursor-pointer"
									htmlFor="4">
									{ t('sciMathStat') }
								</label>
								<input
									className="accent-pink-500"
									type="checkbox"
									value=""
									id="4"/>
							</div>
							<div className='flex justify-between my-2'>
								<label
									className="inline-block pl-[0.15rem] hover:cursor-pointer"
									htmlFor="5">
									{ t('engineering') }
								</label>
								<input
									className="accent-pink-500"
									type="checkbox"
									value=""
									id="5"/>
							</div>
							<div className='flex justify-between my-2'>
								<label
									className="inline-block pl-[0.15rem] hover:cursor-pointer"
									htmlFor="6">
									{ t('IT') }
								</label>
								<input
									className="accent-pink-500"
									type="checkbox"
									value=""
									id="6"/>
							</div>
							<div className='flex justify-between my-2'>
								<label
									className="inline-block pl-[0.15rem] hover:cursor-pointer"
									htmlFor="7">
									{ t('socHuman') }
								</label>
								<input
									className="accent-pink-500"
									type="checkbox"
									value=""
									id="7"/>
							</div>
							<div className='flex justify-between my-2'>
								<label
									className="inline-block pl-[0.15rem] hover:cursor-pointer"
									htmlFor="8">
									{ t('arts') }
								</label>
								<input
									className="accent-pink-500"
									type="checkbox"
									value=""
									id="8"/>
							</div>
							<div className='flex justify-between my-2'>
								<label
									className="inline-block pl-[0.15rem] hover:cursor-pointer"
									htmlFor="9">
									{ t('sport') }
								</label>
								<input
									className="accent-pink-500"
									type="checkbox"
									value=""
									id="9"/>
							</div>
							<div className='flex justify-between my-2'>
								<label
									className="inline-block pl-[0.15rem] hover:cursor-pointer"
									htmlFor="10">
									{ t('services') }
								</label>
								<input
									className="accent-pink-500"
									type="checkbox"
									value=""
									id="10"/>
							</div>
							<div className='flex justify-between my-2'>
								<label
									className="inline-block pl-[0.15rem] hover:cursor-pointer"
									htmlFor="11">
									{ t('agriculture') }
								</label>
								<input
									className="accent-pink-500"
									type="checkbox"
									value=""
									id="11"/>
							</div>
							<div className='flex justify-between my-2'>
								<label
									className="inline-block pl-[0.15rem] hover:cursor-pointer"
									htmlFor="12">
									{ t('natDefense') }
								</label>
								<input
									className="accent-pink-500"
									type="checkbox"
									value=""
									id="12"/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="flex justify-end mt-4 md:justify-end px-40">
				<Link to="/profile/edit/form">
					<button className='bg-[#2DBA8E] py-2 px-4 text-white rounded-lg'>{ t('edit') }</button>
				</Link>
			</div>
		</div>
	);
}

export default SaveProfile;
