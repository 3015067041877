import React from 'react';
import Navbar from "./menu/Navbar";
import Footer from "./main/Footer";

function Layout({children}) {
	return (
		<div className="text-center selection:text-white selection:bg-[#6164b4]">
			<Navbar/>
			{children}
			<Footer/>
		</div>
	);
}

export default Layout;