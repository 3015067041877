import React from 'react'

import FirstSlide from "./carouselSlides/FirstSlide"
import SecondSlide from "./carouselSlides/SecondSlide"
import ThirdSlide from "./carouselSlides/ThirdSlide"

import {useEffect} from 'react';
import 'swiper/swiper-bundle.css';
import '../../assets/css/carousel.css'; // свой CSS должен импортироваться ПОСЛЕ swiper/swiper-bundle.css

import Swiper from 'swiper/bundle';
import carouselBg from "../../assets/images/carouselBg.jpg";


export default function CarouselBlock() {
	useEffect(() => {
		const swiper = new Swiper('.swiper', {
			// Optional parameters
			loop: true,
			slidesPerView: 1,
			allowTouchMove: false,
			navigation: {
				nextEl: '.buttonNext',
				prevEl: '.buttonPrev',
			},
			pagination: {
				el: '.pagination',
				clickable: false,
				type: 'bullets',
				bulletClass: 'bullet',
				renderBullet: function (index, className) {
					return '<div class="' + className + '"> </div>';
				},
			},
			autoHeight: true,
		});
	}, []);

	return (
		<div className={``}>
			<div className="swiper">
				<img src={carouselBg} alt="background image"
						 className="lg:block hidden absolute object-cover blur-xl w-full brightness-[110%] pointer-events-none select-none"/>
				<div className="swiper-wrapper">
					<FirstSlide/>
					<SecondSlide/>
					<ThirdSlide/>
				</div>
				{/* Pagination */}
				<div
					className="flex pagination absolute flex-row justify-center pb-4 gap-3.5 z-[10] *:lg:size-[16px] *:size-[10px] lg:*:ring-[#393DA1] lg:*:ring-1 *:bg-[#D9DAFF] lg:*:bg-white"></div>

				{/* Navigation */}
				<div
					className={`lg:grid hidden buttonPrev qhd:size-[75px] size-[50px] rounded-[5px] ring-[#474BB1] ring-[1.5px] bg-white/50 absolute top-1/2 -translate-y-1/2 left-[1em] 3xl:left-[2em] z-[10] place-items-center`}>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1}
							 stroke="currentColor" className="qhd:size-[60px] size-[40px] text-[#474BB1]">
						<path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5"/>
					</svg>
				</div>
				<div
					className={`lg:grid hidden buttonNext qhd:size-[75px] size-[50px] rounded-[5px] ring-[#474BB1] ring-[1.5px] bg-white/50 absolute top-1/2 -translate-y-1/2 right-[1em] 3xl:right-[2em] z-[10] place-items-center`}>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1}
							 stroke="currentColor" className="qhd:size-[60px] size-[40px] text-[#474BB1]">
						<path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"/>
					</svg>
				</div>

				{/* Mobile navigation */}
				<div
					className={`lg:hidden flex flex-row justify-between w-full absolute sm:top-1/2 sm:-translate-y-1/2 sm:bottom-auto sm:pb-0 bottom-0 pb-4 z-[10] px-2 sm:px-4`}>
					<div
						className={`grid buttonPrev size-[37px] rounded-[5px] ring-[#474BB1] ring-[1.5px] bg-white/50 place-items-center`}>
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1}
								 stroke="currentColor" className="size-[30px] text-[#474BB1]">
							<path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5"/>
						</svg>
					</div>
					<div
						className={`grid buttonNext size-[37px] rounded-[5px] ring-[#474BB1] ring-[1.5px] bg-white/50 place-items-center`}>
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1}
								 stroke="currentColor" className="size-[30px] text-[#474BB1]">
							<path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"/>
						</svg>
					</div>
				</div>
			</div>
		</div>
	)
}
