import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import Container from "../../Container";
import {editProf} from "../../../actions/editProfActions";

const EditProf = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [city, setCity] = useState('');
    const [school, setSchool] = useState('');

    const [photo, setPhoto] = useState(null);

    const [somethingIsBlank, setSomethingIsBlank] = useState(true);
    const [phoneValid, setPhoneValid] = useState(true);
    const [showWarning, setShowWarning] = useState(false);

    const checkBlank = () => {
        if (name !== '' && phone !== '' && city !== '' && school !== '') {
            setSomethingIsBlank(false);
        } else setSomethingIsBlank(true);
    };

    const validatePhone = () => {
        const phoneRegex = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;
        return phoneRegex.test(phone);
    };

    useEffect(() => {
        checkBlank();
    }, [name, phone, city, school]);

    useEffect(() => {
        setPhoneValid(validatePhone());
    }, [phone]);

    const handleSubmit = () => {
        setShowWarning(true);
        checkBlank();

        if (!somethingIsBlank && phoneValid) {
            const formData = new FormData();
            formData.append('name', name);
            formData.append('phone', phone);
            formData.append('city', city);
            formData.append('schools', school);
            if (photo) {
                formData.append('photo', photo);
            }

            dispatch(editProf(formData));
            navigate("/dashboard");
        }
    };


    useEffect(() => {
        setName(state.name);
        setPhone(state.phone);
        setCity(state.city);
        setSchool(state.schools);
    }, [state]);

    return (
        <div className="bg-[#eaf8fc] min-h-screen">
            <Container>
                <div className="pb-4 rounded-[15px] lg:max-w-[40em] w-full select-none mx-auto">
                    <div className="no-scrollbar bg-white relative rounded-[15px] gap-4 shadow-md flex flex-col md:p-6 p-3 text-left">
                        <span className="font-bold text-[24px] md:text-[32px] text-center">Редактировать профиль</span>
                        <form className="flex flex-col gap-4" autoComplete="off">
                            <div className="flex flex-col gap-1">
                                <label htmlFor="name" className="text-[16px]">Имя</label>
                                <input aria-autocomplete="list" type="text" id="name" placeholder='Введите имя'
                                       defaultValue={name}
                                       onChange={(evt) => setName(evt.target.value)}
                                       className="shadow-none outline-none focus:border-[#4285F4] placeholder:text-[14px] placeholder:font-light font-light px-4 py-3 rounded-[9px] border border-[#ADADAD]"/>
                            </div>
                            <div className="flex flex-col gap-1">
                                <label htmlFor="phone" className="text-[16px]">Номер телефона</label>
                                <input aria-autocomplete="list" type="text" id="phone" placeholder='Введите номер телефона'
                                       defaultValue={phone}
                                       onChange={(evt) => setPhone(evt.target.value)}
                                       className={`shadow-none outline-none focus:border-[#4285F4] placeholder:text-[14px] placeholder:font-light font-light px-4 py-3 rounded-[9px] border border-[#ADADAD] 
                                       ${(!phoneValid && showWarning) && 'focus:border-red-500 border-red-500'}`}/>
                                {(!phoneValid && showWarning) && (
                                    <div className="text-[14px] text-red-500">Некорректный номер телефона</div>
                                )}
                            </div>
                            <div className="flex flex-col gap-1">
                                <label htmlFor="city" className="text-[16px]">Город</label>
                                <input aria-autocomplete="list" type="text" id="city" placeholder='Введите город'
                                       defaultValue={city}
                                       onChange={(evt) => setCity(evt.target.value)}
                                       className="shadow-none outline-none focus:border-[#4285F4] placeholder:text-[14px] placeholder:font-light font-light px-4 py-3 rounded-[9px] border border-[#ADADAD]"/>
                            </div>
                            <div className="flex flex-col gap-1">
                                <label htmlFor="school" className="text-[16px]">Школа</label>
                                <input aria-autocomplete="list" type="text" id="school" placeholder='Введите школу'
                                       defaultValue={school}
                                       onChange={(evt) => setSchool(evt.target.value)}
                                       className="shadow-none outline-none focus:border-[#4285F4] placeholder:text-[14px] placeholder:font-light font-light px-4 py-3 rounded-[9px] border border-[#ADADAD]"/>
                            </div>
                            <div className="flex flex-col gap-1">
                                <label htmlFor="photo" className="text-[16px]">Фото</label>
                                <div className="flex flex-row gap-4 justify-between items-center w-full">
                                    <input aria-autocomplete="list"
                                           accept="image/*"
                                           type="file"
                                           onChange={e => setPhoto(e.target.files[0])}
                                           className="shadow-none outline-none focus:border-[#4285F4] font-light px-4 py-3 rounded-[9px] border border-[#ADADAD] w-full"/>
                                    {photo &&
                                        <div
                                            className="size-[3em] min-h-[3em] min-w-[3em] rounded-full overflow-hidden ring-[#ADADAD] ring-1">
                                            <img src={photo ? URL.createObjectURL(photo) : null} alt="" className="size-full object-cover"/>
                                        </div>
                                    }
                                </div>
                            </div>
                            {(somethingIsBlank && showWarning) && (
                                <div className="w-full text-red-600 text-[16px] text-center">Заполните все поля!</div>
                            )}
                        </form>
                        <button
                            onClick={handleSubmit}
                            className="font-bold text-[16px] bg-[#1AAD7F] hover:bg-[#1AAD7F]/80 transition-colors w-full rounded-[10px] text-white py-3">
                            Сохранить изменения
                        </button>
                        <button
                            onClick={() => navigate(-1)}
                            className="font-bold text-[16px] text-[#1AAD7F] ring-2 ring-[#1AAD7F] hover:ring-[#1AAD7F]/50 transition-all w-full rounded-[10px] py-3">
                            Назад
                        </button>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default EditProf;
