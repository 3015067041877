import React from 'react';
import RobotoHeadingWithMark from "./RobotoHeadingWithMark";
import WhitePanel from "./WhitePanel";
import {useTranslation} from "react-i18next";

function NewsTags({tagList = []}) {
    const {t, i18n} = useTranslation('translation', {keyPrefix: 'news'})

    return (
        <WhitePanel>
            <RobotoHeadingWithMark>{t('tags')}</RobotoHeadingWithMark>
            <div className={`flex flex-wrap flex-row gap-2 font-[500] text-[14px] md:text-[13px] text-[#3E3232]/75`}>
                {
                    tagList.map((tag, idx) => {
                        return <span className={`hover:text-[#eb1c3c] cursor-default transition-colors`}
                                     key={idx}>{tag}</span>
                    })
                }
            </div>
        </WhitePanel>
    );
}

export default NewsTags;