export const GALLUP_LIST_FETCHED_SUCCESS = "GALLUP_LIST_FETCHED_SUCCESS";
export const GALLUP_LIST_FETCHED_FAILURE = "GALLUP_LIST_FETCHED_FAILURE";
export const GALLUP_LIST_FETCHED_BEGIN = "GALLUP_LIST_FETCHED_BEGIN";
export const GALLUP_BY_ID_FETCHED_SUCCESS = "GALLUP_BY_ID_FETCHED_SUCCESS";
export const GALLUP_BY_ID_FETCHED_BEGIN = "GALLUP_BY_ID_FETCHED_BEGIN";
export const GALLUP_BY_ID_FETCHED_FAILURE = "GALLUP_BY_ID_FETCHED_FAILURE";
export const GALLUP_PROF_REPORTS_FETCHED_SUCCESS = "GALLUP_PROF_REPORTS_FETCHED_SUCCESS";
export const GALLUP_PROF_REPORTS_FETCHED_BEGIN = "GALLUP_PROF_REPORTS_FETCHED_BEGIN";
export const GALLUP_PROF_REPORTS_FETCHED_FAILURE = "GALLUP_PROF_REPORTS_FETCHED_FAILURE";
export const GALLUP_PROF_REPORTS_NEW_FETCHED_SUCCESS = "GALLUP_PROF_REPORTS_NEW_FETCHED_SUCCESS";
export const GALLUP_PROF_REPORTS_NEW_FETCHED_BEGIN = "GALLUP_PROF_REPORTS_NEW_FETCHED_BEGIN";
export const GALLUP_PROF_REPORTS_NEW_FETCHED_FAILURE = "GALLUP_PROF_REPORTS_NEW_FETCHED_FAILURE";
export const GALLUP_FIELDS_CHOSEN_BEGIN = 'GALLUP_FIELDS_CHOSEN_BEGIN';
export const GALLUP_FIELDS_CHOSEN_SUCCESS = 'GALLUP_FIELDS_CHOSEN_SUCCESS';
export const GALLUP_FIELDS_CHOSEN_FAILURE = 'GALLUP_FIELDS_CHOSEN_FAILURE';