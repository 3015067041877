const accessToken = localStorage.getItem("access_token")

const initialState = accessToken
  ? { isLoggedIn: true, accessToken, error: null, }
  : { isLoggedIn: false, accessToken: null, error: null, };

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isLoggedIn: true,
        accessToken: action.accessToken,
      };
    case 'LOGIN_REFRESH':
      return {
        ...state,
      };
    case 'LOGIN_FAILURE':
      return {
        ...state,
        isLoggedIn: false,
        accessToken: null,
        refreshToken: null,
        error: action.error
      };
    case 'PROFILE':
      return {
        ...state,
        accessToken: null,
        error: action.error
      };
    case 'PROFILE_SUCCESS':
      return {
        ...state,
        accessToken: null,
        error: action.error
      };
    case 'PROFILE_FAILURE':
      return {
        ...state,
        accessToken: null,
        error: action.error
      };
    default:
      return state;
  }
}
