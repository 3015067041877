import React from 'react';

function Slide2Card({icon, heading, cardNumber, children}) {
    return (
        <div className={`flex flex-row gap-2 md:gap-4 p-2 md:p-6 bg-white ring-1 ring-[#C7C7C7] rounded-[20px]`}>
            <div
                className={`md:min-w-[55px] min-w-[36px] size-[36px] md:size-[55px] rounded-[10px] md:rounded-[20px] grid place-items-center
                ${cardNumber === 1 ? 'bg-[#5BA6FE]' : cardNumber === 2 ? 'bg-[#61D98A]' : 'bg-[#E76161]'}`}>
                <img src={icon} className={`size-[20px] md:size-[39px] select-none pointer-events-none`}/>
            </div>
            <div className="flex flex-col gap-2 select-none">
                <div className={`font-bold text-[16px] md:text-[20px] leading-[16px] md:leading-[26px]
                ${cardNumber === 1 ? 'text-[#393DA1]' : cardNumber === 2 ? 'text-[#03511E]' : 'text-[#780000]'}`}>{heading}</div>
                <div
                    className={`text-[#555555] text-[12px] md:text-[16px] leading-[14.1px] md:leading-[26px]`}>{children}</div>
            </div>
        </div>
    );
}

export default Slide2Card;