import ReportService from "../services/report.service";
import {
  FETCH_REPORTS_SUCCESS,
  FETCH_REPORTS_FAILURE,
  FETCH_REPORTS_BEGIN
} from "./actionTypes/reportActionTypes";

export const fetchReportsBegin = () => ({
  type: FETCH_REPORTS_BEGIN
});

export const fetchReports = (gallupId, reportId, isBusiness, regenerate = false) => (dispatch) => {
  dispatch(fetchReportsBegin());
  return ReportService.fetchReports(gallupId, reportId, isBusiness, regenerate).then(
    (data) => {
      dispatch({
        type: FETCH_REPORTS_SUCCESS,
        reports: data
      });
	  // console.info(data)
      return Promise.resolve();
    },
    (error) => {
      let errorMessage = "general";
      if (error.hasOwnProperty('code')) {
        errorMessage = "networkError";
      } else {
        errorMessage = "pending";
      }

      dispatch({
        type: FETCH_REPORTS_FAILURE,
        error: errorMessage
      });

      return Promise.reject("Error");
    }
  );
};

export const fetchReportsFailure = error => ({
  type: FETCH_REPORTS_FAILURE,
  payload: { error }
});