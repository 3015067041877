import AuthService from "../services/auth.service";
import {
	LOGIN_SUCCESS,
	LOGIN_FAILURE,
	REGISTER_SUCCESS,
	REGISTER_FAILURE,
	LOGOUT,
	PROFILE_FAILURE,
	PROFILE_SUCCESS
} from "./actionTypes/authActionTypes";


export const login = (username, password) => (dispatch) => {
	return AuthService.login(username, password).then(
		(data) => {
			dispatch({
				type: LOGIN_SUCCESS,
				payload: {user: data},
			});

			return Promise.resolve();
		},
		(error) => {
			const message =
				(error.response &&
					error.response.data &&
					error.response.data.message) ||
				error.message ||
				error.toString();

			let errors = "";
			if (error.response.data && error.response.data.hasOwnProperty('detail')) {
				errors = error.response.data.detail;
			}

			dispatch({
				type: LOGIN_FAILURE,
				error: errors
			});

			return Promise.reject();
		}
	);
};

export const loginRefresh = () => (dispatch) => {
	return AuthService.loginRefresh().then(
		(data) => {
			dispatch({
				type: LOGIN_SUCCESS,
				payload: {user: data},
			});

			return Promise.resolve();
		},
		(error) => {
			const message =
				(error.response &&
					error.response.data &&
					error.response.data.message) ||
				error.message ||
				error.toString();

			let errors = "";

			dispatch({
				type: LOGIN_FAILURE,
				error: errors
			});

			return Promise.reject();
		}
	);
};

export const register = (username, password1, password2, firstName, lastName) => (dispatch) => {
	return AuthService.register(username, password1, password2, firstName, lastName).then(
		(data) => {
			dispatch({
				type: REGISTER_SUCCESS,
				payload: {user: data},
			});

			return Promise.resolve();
		},
		(error) => {
			const message =
				(error.response &&
					error.response.data &&
					error.response.data.message) ||
				error.message ||
				error.toString();

			dispatch({
				type: REGISTER_FAILURE,
				error: error.response.data
			});

			return Promise.reject();
		}
	);
};

export const logout = () => (dispatch) => {
	AuthService.logout();

	dispatch({
		type: LOGOUT
	});
};
