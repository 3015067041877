import React from 'react';

function TopPost({heading, subheading, image, setCurrentArticle, id}) {
    return (
        <div
            className="text-left flex gap-4 items-center select-none font-roboto cursor-pointer
            [&>div>img]:hover:scale-[150%] [&>div>div>span:nth-child(1)]:hover:text-[#eb1c3c]"
            onClick={() => setCurrentArticle(id)}>
            <div className="rounded-[12px] size-[87px] min-w-[87px] min-h-[87px] overflow-hidden">
                <img src={image} className='scale-[120%] pointer-events-none transition-transform'/>
            </div>
            <div>
                <div className='flex flex-col gap-1'>
                    <span
                        className='transition-colors text-[13px] font-[500] leading-[20px] text-[#3E3232]'>{heading}</span>
                    <span className='text-[12px] leading-[18px] text-[#3E3232]/75'>{subheading}</span>
                </div>
            </div>
        </div>
    );
}

export default TopPost;