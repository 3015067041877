import React from 'react'
import {Circles} from 'react-loader-spinner'


const Loader = ({children}) => {
	return (
		<div className='min-h-screen flex justify-center items-center'>
			<div className={`flex justify-center items-center`}>
				<Circles
					Circlespe="Puff"
					color="#cdebff"
					height={100}
					width={100}
					timeout={3000}
				/>
			</div>
		</div>
	);
};

export default Loader;