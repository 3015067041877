const initialState = {
  userProfile: null,
  loading: false,
  error: null,
}

export default function (state = initialState, action) {
  const { type } = action;

  switch (type) {
    case 'PROFILE_FETCHED_BEGIN':
      return {
        ...state,
        loading: true,
        error: null
    };
    case 'PROFILE_FETCHED_SUCCESS':
      return {
        ...state,
        userProfile: action.profile,
        loading: false,
      };
    case 'PROFILE_FETCHED_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}
