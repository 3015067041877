const initialState = {
	userEdited: false,
	loading: false,
	error: null,
}

export default function (state = initialState, action) {
	const {type, payload} = action;

	switch (type) {
		case 'EDIT_SUCCESS':
			return {
				...state,
				userEdited: true,
			};
		case 'EDIT_FAILURE':
			return {
				...state,
				userEdited: false,
				error: action.error
			};
		default:
			return state;
	}
}
