import React from 'react'
import '../../assets/css/main.css'
import intersectImage from '../../assets/images/intersect.png'
import lampImage from '../../assets/images/lamp.png'
import portfelImage from '../../assets/images/portfel.png'
import commentsImage from '../../assets/images/comments.png'
import feedbacksImage from '../../assets/images/feedbacks_icon.png'
import professionsImage from '../../assets/images/professions_icon.png'
import headerTieImage1 from '../../assets/images/header_tie.png'
import {ChevronRightIcon} from '@heroicons/react/24/solid'
import {useNavigate} from 'react-router-dom';
import Navbar from '../menu/Navbar'
import Container from "../Container";
import {useTranslation} from "react-i18next";


const Header = ({showModal}) => {
	const navigate = useNavigate();
	const {t, i18n} = useTranslation('translation', {keyPrefix: 'main.header'})
	const handleLogin = () => {
		navigate('/login');
	};

	return (
		<>
			<div className={`bg-[#EAF8FC] md:rounded-b-[40%]`}>
				{/*<Navbar/>*/}
				<div className="hidden md:block">
					<img src={headerTieImage1} width={350} height={200}
							 className="absolute top-[200px] scale-125 select-none left-0 hidden 3xl:block"/>
					<div className="hidden md:block">
						<Container>
							<div
								className="2xl:block hidden absolute top-10 qhd:left-[900px] fhd:left-[600px] left-[500px] rounded-full size-4 bg-[#EB5652]"></div>
							<div
								className="2xl:block hidden absolute top-20 qhd:right-[1100px] fhd:right-[800px] right-[600px] rounded-full size-8 bg-[#FFD542]"></div>
							<div
								className="2xl:block hidden absolute bottom-[350px] qhd:left-[900px] left-[600px] rounded-full size-3 bg-[#50D3FF]"></div>
							<div className="2xl:block hidden absolute top-8 qhd:right-[500px] right-40">
								<svg width="22" height="22" viewBox="0 0 22 22" fill="none"
										 xmlns="http://www.w3.org/2000/svg">
									<circle cx="11" cy="11" r="11" fill="#47DAAC"/>
								</svg>
							</div>

							{/*блок с главным текстом */}
							<div className='flex justify-between flex-row pt-[5em] relative'>
								<div className='flex flex-col gap-8 select-none w-full lg:basis-[]'>
									<div className='leading-tight text-left font-bold text-[64px]'><span
										className='text-[#50D3FF]'>{t("hero.heading.0")}</span> {t("hero.heading.1")}
									</div>
									<div className='text-start justify-start leading-tight text-[20px]'>{t("hero.text")}
									</div>
									<button
										onClick={showModal}
										className='flex w-fit items-center rounded-full justify-center px-10 py-4 font-bold text-white bg-[#393DA1] hover:bg-[#393DA1]/80 transition-colors gap-2 text-[24px]'>
										<span>{t("hero.button")}</span>
										<ChevronRightIcon className="size-7 text-white"/>
									</button>
								</div>

								{/* Блок с двумя карточками вместо женщины */}
								<div className='relative basis-[40%] 2xl:hidden hidden lg:block'>
									<div
										className={`select-none pointer-events-none relative top-[20%] left-[10%] w-[90%]`}>
										<img src={feedbacksImage}
												 className='absolute size-16 top-0 left-0 z-[111]'/>
										<div
											className='ring-black/20 ring-1 bg-white top-[1em] left-[1em] pl-12 py-6 pr-6 text-left absolute flex flex-col z-10 rounded-2xl'>
											<div className='text-[#393DA1] text-[32px] font-[700]'>150к</div>
											<div className={`text-[20px]`}>{t("hero.redCard")}</div>
										</div>
									</div>
									<div
										className={`select-none pointer-events-none w-fit relative flex z-[111] ring-black/20 ring-1 items-center px-6 py-4 bg-white rounded-xl flex-row gap-4
										top-[50%] ${i18n.resolvedLanguage === 'kz' ? 'xl:left-[40%] left-[20%]' : 'left-[40%]'}`}>
										<img src={professionsImage}
												 className=' object-cover aspect-square size-16'/>
										<div
											className='text-left flex flex-col'>
											<div className='text-[#393DA1] text-[32px] font-[700]'>600+</div>
											<div className={`text-[20px]`}>{t("hero.greenCard")}</div>
										</div>
									</div>
								</div>

								{/* Блок с женщиной */}
								<div
									className='relative basis-[40%] 2xl:block hidden scale-[85%] 3xl:scale-100'>
									<div
										className={`select-none pointer-events-none relative top-[90%] left-[-10%]`}>
										<img src={feedbacksImage}
												 className='absolute size-16 top-0 left-0 z-[111]'/>
										<div
											className='ring-black/20 ring-1 bg-white top-[1em] left-[1em] pl-12 py-6 pr-6 text-left absolute flex flex-col z-10 rounded-2xl'>
											<div className='text-[#393DA1] text-[32px] font-[700]'>150к</div>
											<div className={`text-[20px]`}>{t("hero.redCard")}</div>
										</div>
									</div>
									<div
										className={`select-none pointer-events-none w-fit relative flex z-[111] ring-black/20 ring-1 items-center p-4 bg-white rounded-xl flex-row gap-4
										3xl:top-[18%] 2xl:top-[30%]
                    ${i18n.resolvedLanguage === 'kz' ?
											'2xl:left-[95%] 3xl:left-[90%]' :
											'2xl:left-[110%] 3xl:left-[96%]'}`}>
										<img src={professionsImage}
												 className=' object-cover aspect-square size-16'/>
										<div
											className='text-left flex flex-col'>
											<div className='text-[#393DA1] text-[32px] font-[700]'>600+</div>
											<div className={`text-[20px]`}>{t("hero.greenCard")}</div>
										</div>
									</div>
									<div
										className="rounded-full absolute bottom-[-150px] left-[50px] bg-[#50D3FF] size-[600px]"></div>
									<div
										className="rounded-full absolute bottom-[-145px] left-[55px]  bg-[#EAF8FC] size-[590px]"></div>
									<div
										className="rounded-full absolute bottom-[-120px] left-[80px]  bg-[#393DA1] size-[540px]"></div>
									<img src={intersectImage}
											 className='3xl:hidden absolute pointer-events-none object-cover h-[650px] bottom-[-118px] left-[80px]'/>
									<img src={intersectImage}
											 className='3xl:block hidden absolute pointer-events-none object-cover h-[650px] bottom-[-120px] left-[80px]'/>
								</div>
							</div>
							<div
								className='relative py-12 px-10 2xl:mt-36 select-none rounded-3xl bg-[#393DA1] grid gap-14 grid-cols-1 gap-y-8 xl:grid-cols-3 top-[6em]'>
								<div className='flex gap-3'>
									<div className=''>
										<div
											className='bg-[#FFFFFF33] size-24 rounded-3xl flex justify-center items-center'>
											<img src={lampImage} className='pointer-events-none size-16'
													 alt="React Logo"/>
										</div>
									</div>
									<div className=''>
										<div className='text-justify flex flex-col gap-2'>
                                    <span
																			className='text-white text-[22px] font-semibold break-keep'>{t('blue.0.title')}</span>
											<p className={`text-[16px] text-white/70`}>
												{t('blue.0.text')}</p>
										</div>
									</div>
								</div>
								<div className='flex gap-3'>
									<div className=''>
										<div
											className='bg-[#FFFFFF33] size-24 rounded-3xl flex justify-center items-center'>
											<img src={portfelImage} className='pointer-events-none size-16'
													 alt="React Logo"/>
										</div>
									</div>
									<div className=''>
										<div className='text-justify flex flex-col gap-2'>
                                    <span
																			className='text-white text-[22px] font-semibold break-keep'>{t('blue.1.title')}</span>
											<p className={`text-[16px] text-white/70`}>
												{t('blue.1.text')}</p>
										</div>
									</div>
								</div>
								<div className='flex gap-3'>
									<div className=''>
										<div
											className='bg-[#FFFFFF33] size-24 rounded-3xl flex justify-center items-center'>
											<img src={commentsImage} className='pointer-events-none size-16'
													 alt="React Logo"/>
										</div>
									</div>
									<div className=''>
										<div className='text-justify flex flex-col gap-2'>
                                    <span
																			className='text-white text-[22px] font-semibold break-keep'>{t('blue.2.title')}</span>
											<p className={`text-[16px] text-white/70`}>
												{t('blue.2.text')}</p>
										</div>
									</div>
								</div>
							</div>
						</Container>
					</div>
				</div>

				{/* mobile */}
				<div className={`md:hidden pt-[8vw]`}>
					<Container>
						<div className="flex flex-col gap-8">
							<div className='relative'>
								<div
									className={`select-none pointer-events-none absolute bottom-[15%] sm:left-[10%]`}>
									<img src={feedbacksImage} className='size-[29px] z-[111] absolute'/>
									<div
										className='ring-black/20 ring-1 bg-white top-[1em] left-[1em] pl-4 py-2 pe-2 text-left relative flex flex-col z-10 rounded-[10px]'>
										<div className='text-[#393DA1] text-[16px] font-[700]'>150к</div>
										<div className={`text-[12px] leading-[14.1px]`}>{t('hero.redCard')}</div>
									</div>
								</div>
								<div
									className={`select-none pointer-events-none w-fit absolute flex items-center z-[111] ring-black/20 ring-1
                                     p-2 bg-white rounded-[10px] flex-row gap-2 top-[20%] right-0 sm:right-[10%]`}>
									<img src={professionsImage}
											 className=' object-cover aspect-square size-[26px]'/>
									<div
										className='text-left flex flex-col'>
										<div className='text-[#393DA1] text-[14px] font-[700]'>600+</div>
										<div className={`text-[12px]`}>{t('hero.greenCard')}</div>
									</div>
								</div>
								<div
									className="size-[280px] bg-[#50D3FF] rounded-full absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 sm:scale-[120%]"></div>
								<div
									className="size-[276px] bg-[#EAF8FC] rounded-full absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 sm:scale-[120%]"></div>
								<div
									className="size-[252px] bg-[#393DA1] rounded-full absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 sm:scale-[120%]"></div>
								<img src={intersectImage} alt=""
										 className={`relative h-[280px] object-cover bottom-[12px] sm:bottom-[16px]
                                     left-[calc(50%-16px)] sm:left-[calc(50%-20px)] -translate-x-1/2 sm:scale-[120%]`}/>
							</div>
							<div className='flex flex-col items-start gap-4'>
								<div className='font-bold text-left text-[24px] leading-[33px]'><span
									className='text-[#50D3FF]'>{t('hero.heading.0')}</span>&nbsp;{t('hero.heading.1')}
								</div>
								<div className='font-medium text-left leading-tight text-[12px]'>
									{t('hero.text')}
								</div>
								<button
									className='rounded-[92px] px-6 py-2  text-white bg-[#393DA1] font-bold text-[14px]'
									onClick={showModal}>
									{t('hero.button')}
								</button>
							</div>
						</div>
						<div
							className='relative md:hidden py-6 px-4 select-none rounded-2xl bg-[#393DA1] grid gap-2 grid-cols-1 gap-y-8 xl:grid-cols-3 top-[2em]'>
							<div className='flex gap-3'>
								<div className=''>
									<div
										className='bg-[#FFFFFF33] size-20 rounded-3xl flex justify-center items-center'>
										<img src={lampImage} className='pointer-events-none size-14'
												 alt="React Logo"/>
									</div>
								</div>
								<div className=''>
									<div className='text-left flex flex-col gap-2'>
                                    <span
																			className='text-white text-[16px] font-semibold break-keep'>{t('blue.0.title')}</span>
										<p className={`text-[12px] text-white/70`}>
											{t('blue.0.text')}</p>
									</div>
								</div>
							</div>
							<div className='flex gap-3'>
								<div className=''>
									<div
										className='bg-[#FFFFFF33] size-20 rounded-3xl flex justify-center items-center'>
										<img src={portfelImage} className='pointer-events-none size-14'
												 alt="React Logo"/>
									</div>
								</div>
								<div className=''>
									<div className='text-left flex flex-col gap-2'>
                                    <span
																			className='text-white text-[16px] font-semibold break-keep'>{t('blue.1.title')}</span>
										<p className={`text-[12px] text-white/70`}>
											{t('blue.1.text')}</p>
									</div>
								</div>
							</div>
							<div className='flex gap-3'>
								<div className=''>
									<div
										className='bg-[#FFFFFF33] size-20 rounded-3xl flex justify-center items-center'>
										<img src={commentsImage} className='pointer-events-none size-14'
												 alt="React Logo"/>
									</div>
								</div>
								<div className=''>
									<div className='text-left flex flex-col gap-2'>
                                    <span
																			className='text-white text-[16px] font-semibold break-keep'>{t('blue.2.title')}</span>
										<p className={`text-[12px] text-white/70`}>
											{t('blue.2.text')}</p>
									</div>
								</div>
							</div>
						</div>
					</Container>
				</div>
			</div>
		</>
	);
}

export default Header;
