function validateCompany(company, errors) {
	if (company.trim().length < 1) errors.company.push("Company name field cannot be empty.")
	else if (company.trim().length > 40) errors.company.push("Company name field must be shorter than 40 characters.")
}

function validateName(name, errors) {
	if (name.trim().length < 1) errors.name.push("Name field cannot be empty.")
	else if (name.trim().length > 40) errors.name.push("Name field must be shorter than 40 characters.")
}

function validateExpertiseRequired(expertiseRequired, errors) {
	if (expertiseRequired.trim().length < 1) errors.expertiseRequired.push("Expertise required field cannot be empty.")
	else if (expertiseRequired.trim().length > 40) errors.expertiseRequired.push("Expertise required field must be shorter than 40 characters.")
}

function validateInfo(info, errors) {
	if (info.trim().length < 1) errors.info.push("Info field cannot be empty.")
	else if (info.trim().length > 400) errors.info.push("Info field must be shorter than 400 characters.")
}

function validateGrade(grade, errors) {
	if (grade.trim().length < 1) errors.grade.push("Grade field cannot be empty.")
	else if (grade.trim().length > 4) errors.grade.push("Grade field must be shorter than 5 characters.")
}

function validateDateOfBirth(date, errors) {
	if (date.trim().length < 1) errors.dateOfBirth.push("Date of birth field cannot be empty.")
}


export function validateForm(company, name, expertiseRequired, info) {
	let errors = {
		company: [],
		name: [],
		expertiseRequired: [],
		info: []
	}
	validateCompany(company, errors)
	validateName(name, errors)
	validateExpertiseRequired(expertiseRequired, errors)
	validateInfo(info, errors)
	return errors
}

export function validateStudentForm(grade, name, dateOfBirth) {
	let errors = {
		grade: [],
		name: [],
		dateOfBirth: []
	}
	validateGrade(grade, errors)
	validateName(name, errors)
	validateDateOfBirth(dateOfBirth, errors)
	return errors
}
