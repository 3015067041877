import image0_0 from "../../assets/images/news/0/0.jpg"
import image0_1 from "../../assets/images/news/0/1.jpg"
import image0_2 from "../../assets/images/news/0/2.jpg"

import image1_0 from "../../assets/images/news/1/0.png"
import image1_1 from "../../assets/images/news/1/1.png"

export const news = [
    {
        id: 0,
        heading: "GALLUP КАСЫМ ЖОМАРТА ТОКАЕВА ПРЕЗИДЕНТА КАЗАХСТАНА",
        subheading: "САБХЕНДИНГ",
        heroImage: image0_0,
        heroProvided: true,
        fragments: [
            {
                name: "В чем успех нашего президента? Какие таланты помогают ему в государственном и политическом пути? Мы проанализировали его деятельность и выявили топ талантов, благодаря которым он успешно прошел путь от дипломата до президента и руководит нашей страной.",
                content: ["Много интересного - ниже."],
                hasImage: false,
            },
            {
                name: "",
                content: [],
                hasImage: true,
                image: image0_1
            },
            {
                name: "FUTURISTIC + ANALYTICAL + CONSISTENCY",
                content: ["Токаев использует свой аналитический подход для глубокого понимания вызовов, перед которыми стоит страна, и выработки стратегий, которые учитывают как текущие тренды, так и будущие возможности.",
                    "Последовательное применение реформ гарантирует, что политика будет справедлива и прозрачна для всех граждан, в то время как футуристическое видение направляет эти усилия на достижение прогрессивного и устойчивого будущего для Казахстана."],
                hasImage: false,
            },
            {
                name: "RESPONSIBILITY + RESTORATIVE + BELIEF",
                content: ["Токаев способен не только эффективно решать текущие проблемы, но и делать это с высокой степенью ответственности и в соответствии со своими убеждениями и ценностями. Это позволяет ему вести страну через сложные времена, сохраняя при этом доверие населения и укрепляя основы государства."],
                hasImage: false,
            },
            {
                name: "DELIBERATIVE + COMMUNICATION + HARMONY",
                content: ["Токаев демонстрирует мастерство в управлении международными отношениями Казахстана, используя осторожный и в то же время активный подход к дипломатии. Он стремится к созданию условий для эффективного диалога и сотрудничества, при этом учитывая потенциальные риски и стремясь к гармонии в международных отношениях.",
                    "Эта комбинация талантов позволяет Токаеву успешно защищать интересы Казахстана, способствуя его устойчивому развитию и укреплению позиций на международной арене."],
                hasImage: false,
            },
            {
                name: "STRATEGIC + ARRANGER + COMMAND",
                content: ["Комбинируя эти таланты, Токаев демонстрирует способность не только эффективно реагировать на непосредственные угрозы, но и вести страну через кризисы с учетом долгосрочной перспективы.",
                    "Его лидерские качества обеспечивают четкое направление и уверенность в сложных ситуациях, организаторские способности позволяют мобилизовать и оптимально использовать ресурсы, а стратегическое мышление направляет Казахстан к устойчивому развитию и благополучию в будущем."],
                hasImage: false,
            },
            {
                name: "",
                content: [],
                hasImage: true,
                image: image0_2,
            },
        ],
        tags: [
            "#КасымЖомартТокаев",
            "#ЛидерствоКазахстан",
            "#ТалантыGallup",
            "#ПолитическиеТаланты",
            "#БудущееКазахстана"
        ]
    },
    {
        id: 1,
        heading: "Почему у вас все по полочкам и заранее все продумано?",
        subheading: "САБХЕНДИНГ",
        heroImage: image1_0,
        heroProvided: true,
        fragments: [
            {
                name: "У людей с развитым блоком STRATEGIC не просто все по полочкам:",
                content: ["- Дети, проявляющие эти таланты, любознательны и генерируют новые идеи;",
                    "- Взрослые - умеют аналитически мыслить, легко улавливают причинно-следственные связи и выделяют ключевые моменты в большем объеме информации",
                    "Подробнее о пути стратегов читайте ниже."],
                hasImage: false,
            },
            {
                name: "",
                content: [],
                hasImage: true,
                image: image1_1
            },
            {
                name: "КАКИЕ У НЕГО СЛАБОСТИ?",
                content: ["Его тяга к сбору информации и анализу может привести к перегрузке и затруднить принятие решений.",
                    "Из-за постоянных глубоких размышлений он может долго решаться на действия.",
                    "Он может увлекаться планированием и идеальными решениями, упуская простые или спонтанные возможности."],
                hasImage: false,
            },
            {
                name: "КАКОЙ ОН?",
                content: ["Истинный мыслитель и постоянный исследователь. Он любит учиться, размышлять и собирать информацию, всегда ищет новые знания и идеи. Его ум способен видеть связи между прошлым и настоящим, и он использует это понимание для планирования будущего. Этот человек любит глубокие разговоры и всегда стремится найти наилучший путь в любой ситуации."],
                hasImage: false,
            },
            {
                name: "КАКОЙ СОВЕТ ВЫ БЫ ДАЛИ ТАКОМУ",
                content: ["Выбирать главное и действовать, не увязая в деталях и не ждать идеального момента."],
                hasImage: false,
            },
        ],
        tags: [
            "#СтратегическоеМышление",
            "#Лидерство",
            "#Аналитика",
            "#ТалантыGallup",
        ]
    },
]