import ProfileService from "../services/profile.service";
import {
	PROFILE_FETCHED_SUCCESS,
	PROFILE_FETCHED_FAILURE,
	PROFILE_FETCHED_BEGIN,
	DELETE_USER_SUCCESS,
	DELETE_USER_FAILURE,
	DELETE_USER_BEGIN
} from "./actionTypes/profileActionTypes";
import {LOGIN_REFRESH} from "./actionTypes/authActionTypes";
import {loginRefresh} from './authActions';


export const fetchUserProfileBegin = () => ({
	type: PROFILE_FETCHED_BEGIN
});


export const fetchUserProfile = () => (dispatch) => {
	dispatch(fetchUserProfileBegin());
	return ProfileService.fetchUserProfile().then(
		(data) => {
			dispatch({
				type: PROFILE_FETCHED_SUCCESS,
				profile: data,
			});

			return Promise.resolve();
		},
		(error) => {
			dispatch({
				type: PROFILE_FETCHED_FAILURE,
				error: error.response.data
			});

			return Promise.reject();
		});
};

export const fetchUserProfileFailure = error => ({
	type: PROFILE_FETCHED_FAILURE,
	payload: {error}
});


export const deleteUserBegin = () => ({
	type: DELETE_USER_BEGIN
});


export const deleteUser = (gallupId) => (dispatch) => {
	dispatch(deleteUserBegin());
	return ProfileService.deleteUser(gallupId).then(
		(data) => {
			dispatch({
				type: DELETE_USER_SUCCESS,
			});

			return Promise.resolve();
		},
		(error) => {
			dispatch({
				type: DELETE_USER_FAILURE,
				error: error.response.data
			});

			return Promise.reject();
		});
};

export const deleteUserFailure = error => ({
	type: DELETE_USER_FAILURE,
	payload: {error}
});
