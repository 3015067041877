import React, {useEffect} from 'react'
import '../../assets/css/sidebar.css'
import {useNavigate} from 'react-router-dom';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {fetchUserProfile} from "../../actions/profileActions";


const Sidebar = ({isOpen, loggedIn, onLanding, toLogin, toProfile, logout}) => {
	const {t, i18n} = useTranslation()
	const profile = useSelector(state => state.profile.userProfile);
	const dispatch = useDispatch()

	useEffect(() => {
		if (loggedIn) {
			dispatch(fetchUserProfile());
		}
	}, [dispatch]);

	const locales = {
		ru: {title: 'RU'},
		en: {title: 'EN'},
		kz: {title: 'KZ'}
	}

	const switchLang = async (key) => {
		localStorage.setItem('savedLang', key);
		await i18n.changeLanguage(key)
	}


	return (
		<div
			className={`sidebar bg-[#393DA1]/60 h-full w-1/2 -left-1/2 transition-all duration-200
            backdrop-blur-xl top-0 fixed z-[1111] text-left text-white  ${isOpen && 'open'} p-6`}>
			<div className={`flex flex-col *:py-4 hover:[&>*:not(:nth-child(4))]:translate-x-4 *:transition-all`}>
				<Link to="/" className={``}>{t("navbar.links.0")}</Link>
				<Link to="/faq">{t("navbar.links.1")}</Link>
				<Link to="/news">{t("navbar.links.2")}</Link>
				<div className={`flex flex-row gap-2 hover:*:text-white/70`}>
					{Object.keys(locales).map(locale => {
						return <button key={locale}
													 className={`font-bold text-[16px] text-black
                                                   ${i18n.resolvedLanguage === locale ? 'text-white' : 'text-white/50'}`}
													 onClick={() => switchLang(locale)}>{locales[locale].title}</button>
					})}
				</div>
				<button onClick={loggedIn ?
					onLanding ? toProfile
						: logout
					: toLogin} className={`text-left`}>{loggedIn ?
					onLanding ?
						<div className={``}>{profile && profile.name}</div>
						: t("navbar.button.logout")
					: t("navbar.button.login")}
				</button>
			</div>
		</div>
	);
};

export default Sidebar;
