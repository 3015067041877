import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import Container from "../../Container";
import {ChevronDownIcon} from "@heroicons/react/24/solid";
import {editStudent} from "../../../actions/editUserActions";

function EditStudent() {
	const mbtiFirstParts = ['IN', 'IS', 'EN', 'ES']
	const mbtiSecondParts = ['TJ', 'TP', 'FJ', 'FP']

	const dispatch = useDispatch()
	const navigate = useNavigate();
	const location = useLocation()
	const state = location.state;

	const [name, setName] = useState('');
	const [grade, setGrade] = useState('');
	const [school, setSchool] = useState('');
	const [dateOfBirth, setDateOfBirth] = useState('');
	const [mbti, setMbti] = useState('');
	const [mbtiPart1, setMbtiPart1] = useState('');
	const [mbtiPart2, setMbtiPart2] = useState('');
	const [mit, setMit] = useState({
		"Linguistic": 8,
		"Logical-mathematical": 8,
		"Musical": 8,
		"Bodily-kinesthetic": 8,
		"Spatial": 8,
		"Interpersonal": 8,
		"Intra-personal": 8,
		"Naturalistic": 8,
		"Existential": 8
	});
	const [spheres, setSpheres] = useState({
		"Бизнес и управление": 1,
		"Здравоохранение": 1,
		"Образование": 1,
		"Естественные науки, математика и статистика": 1,
		"Инженерия": 1,
		"ИТ": 1,
		"Социальные науки": 1,
		"Гуманитарные науки": 1,
		"Искусство": 1,
		"Услуги": 1,
		"Сельское хозяйство и биоресурсы": 1,
		"Национальная оборона и безопасность": 1
	});

	const [sphereSelectOpened, setSphereSelectOpened] = useState(false);
	const [mitSelectOpened, setMitSelectOpened] = useState(false);
	const [somethingIsBlank, setSomethingIsBlank] = useState(true);
	const [showWarning, setShowWarning] = useState(false);

	const checkBlank = () => {
		if (name !== '' && grade !== '' &&
			school !== '' && dateOfBirth !== '' &&
			mbti !== '' && mit !== '' && spheres !== ''
		) {
			setSomethingIsBlank(false)
		} else setSomethingIsBlank(true)
	}

	const changeSphereValue = (eValue, key) => {
		let newSpheres = {...spheres}
		if (eValue === '') {
			newSpheres[key] = 1
			setSpheres(newSpheres)
			return
		}
		if (eValue.length === 2 && eValue[0] === '1') {
			newSpheres[key] = Number(eValue[1])
			setSpheres(newSpheres)
		}
		if (parseInt(eValue) >= 0 && parseInt(eValue) <= 10) {
			newSpheres[key] = parseInt(eValue)
			setSpheres(newSpheres)
		}
	}
	const changeMitValue = (eValue, key) => {
		let newMit = {...mit}
		if (eValue === '') {
			newMit[key] = 0
			setMit(newMit)
			return
		}
		if (eValue.length === 2 && eValue[0] === '0') {
			newMit[key] = Number(eValue[1])
			setMit(newMit)
		}
		if (parseInt(eValue) >= 0 && parseInt(eValue) <= 100) {
			newMit[key] = parseInt(eValue)
			setMit(newMit)
		}
	}

	const handleSubmit = () => {
		setShowWarning(true)
		checkBlank()

		if (!somethingIsBlank) {
			const formData = {
				'user_id': state.user_id,
				'Name': name,
				'Grade': grade,
				'School': school,
				'Date_of_birth': dateOfBirth,
				'MBTI': mbti,
				'MIT': JSON.stringify(mit),
				'proInput': JSON.stringify(spheres)
			}
			
			
			dispatch(editStudent(formData))
			navigate(`/profile/${state.user_id}`);
		}
	}

	useEffect(() => {
		setName(state.name)
		setGrade(state.grade)
		setSchool(state.school)
		setMbti(state.mbti)
		setMbtiPart1(`${state.mbti[0]}${state.mbti[1]}`)
		setMbtiPart2(`${state.mbti[2]}${state.mbti[3]}`)

		const regex = /^(\d{2})-(\d{2})-(\d{4})$/;
		if (regex.test(state.dateOfBirth)) {
			const [day, month, year] = state.dateOfBirth.split('-');
			const date = new Date(`${year}-${month}-${day}`);
			const formattedDate = date.toISOString().split('T')[0];
			setDateOfBirth(formattedDate)
		} else setDateOfBirth(state.dateOfBirth)

		try {
			let parsedMit = JSON.parse(state.mit)
			Object.keys(parsedMit).forEach(key => parsedMit[key] = parseInt(parsedMit[key]));
			setMit(parsedMit)
		} catch (exc) {
			setMit({
				"Linguistic": 0,
				"Logical-mathematical": 0,
				"Musical": 0,
				"Bodily-kinesthetic": 0,
				"Spatial": 0,
				"Interpersonal": 0,
				"Intra-personal": 0,
				"Naturalistic": 0,
				"Existential": 0
			})
		}
		try {
			setSpheres(JSON.parse(state.spheres))
		} catch (exc) {
			setSpheres({
				"Бизнес и управление": 1,
				"Здравоохранение": 1,
				"Образование": 1,
				"Естественные науки, математика и статистика": 1,
				"Инженерия": 1,
				"ИТ": 1,
				"Социальные науки": 1,
				"Гуманитарные науки": 1,
				"Искусство": 1,
				"Услуги": 1,
				"Сельское хозяйство и биоресурсы": 1,
				"Национальная оборона и безопасность": 1
			})
		}
	}, [state]);

	useEffect(() => {
		checkBlank()
	}, [name, grade, school, dateOfBirth, mbti, mit, spheres]);

	useEffect(() => {
		setMbti(`${mbtiPart1}${mbtiPart2}`)
	}, [mbtiPart1, mbtiPart2]);


	return (
		<div className={`bg-[#eaf8fc]  min-h-screen`}>
			<Container>
				<div
					className={`pb-4 rounded-[15px] lg:max-w-[40em] w-full select-none mx-auto`}>
					<div
						className={`no-scrollbar bg-white relative rounded-[15px] gap-4 shadow-md flex flex-col md:p-6 p-3 text-left`}>
						<span className={`font-bold text-[24px] md:text-[32px] text-center`}>Редактировать профиль</span>
						<form className={`flex flex-col gap-4`} autoComplete="off">
							<div className={`flex flex-col gap-1`}>
								<label htmlFor="" className={`text-[16px]`}>Имя</label>
								<input aria-autocomplete="list" type="text" id={``} placeholder='Введите имя'
											 defaultValue={name}
											 onChange={(evt) => setName(evt.target.value)}
											 className={` shadow-none outline-none focus:border-[#4285F4] placeholder:text-[14px] placeholder:font-light font-light px-4 py-3 rounded-[9px] border border-[#ADADAD]`}/>
							</div>
							<div className={`flex flex-col gap-1`}>
								<label htmlFor="" className={`text-[16px]`}>Класс</label>
								<input aria-autocomplete="list" type="text" id={``} placeholder='Введите класс'
											 defaultValue={grade}
											 onChange={(evt) => setGrade(evt.target.value)}
											 className={` shadow-none outline-none focus:border-[#4285F4] placeholder:text-[14px] placeholder:font-light font-light px-4 py-3 rounded-[9px] border border-[#ADADAD]`}/>
							</div>
							<div className={`flex flex-col gap-1`}>
								<label htmlFor="" className={`text-[16px]`}>Школа</label>
								<input aria-autocomplete="list" type="text" id={``} placeholder='Введите школу'
											 defaultValue={school}
											 onChange={(evt) => setSchool(evt.target.value)}
											 className={` shadow-none outline-none focus:border-[#4285F4] placeholder:text-[14px] placeholder:font-light font-light px-4 py-3 rounded-[9px] border border-[#ADADAD]`}/>
							</div>
							<div className={`flex flex-col gap-1`}>
								<label htmlFor="" className={`text-[16px]`}>Дата рождения</label>
								<input aria-autocomplete="list" type="date" id={``}
											 defaultValue={dateOfBirth}
											 onChange={(evt) => setDateOfBirth(evt.target.value)}
											 className={` shadow-none outline-none focus:border-[#4285F4] placeholder:text-[14px] placeholder:font-light font-light px-4 py-3 rounded-[9px] border border-[#ADADAD]`}/>
							</div>
							<div className={`flex flex-col gap-1`}>
								<label htmlFor="" className={`text-[16px]`}>MBTI</label>
								<div className={`flex flex-row gap-2 
								*:shadow-none *:outline-none *:focus:border-[#4285F4] *:font-light *:px-4 *:py-3 *:rounded-[9px] *:border *:border-[#ADADAD] *:w-full`}>
									<select name="mbti1" id="mbti1"
													onChange={e => setMbtiPart1(e.target.value)}
													value={mbtiPart1}>
										{mbtiFirstParts.map((part, idx) => (
											<option value={part} key={idx}>{part}</option>
										))}
									</select>
									<select name="mbti2" id="mbti2"
													onChange={e => setMbtiPart2(e.target.value)}
													value={mbtiPart2}>
										{mbtiSecondParts.map((part, idx) => (
											<option value={part} key={idx}>{part}</option>
										))}
									</select>
								</div>
							</div>
							<div className={`flex flex-col gap-1`}>
								<label htmlFor="" className={`text-[16px]`}>Сферы</label>
								<div
									className={`h-full focus:border-[#4285F4] font-light rounded-[9px] border border-[#ADADAD] flex flex-col items-center`}>
									<div
										className={`px-4 py-3 flex flex-row justify-between w-full cursor-pointer rounded-[9px] text-[#767676]
										${sphereSelectOpened ? 'bg-white' : 'bg-[#F7F7F7]'}`}
										onClick={() => setSphereSelectOpened(!sphereSelectOpened)}>
										<span>Показать сферы</span>
										<ChevronDownIcon
											className={`text-[#767676] size-6 transition-transform  ${sphereSelectOpened && 'rotate-180'}`}/>
									</div>
									<div
										className={`flex-col gap-2 w-full py-2 ${sphereSelectOpened ? 'flex' : 'hidden'}`}>
										{Object.keys(spheres).map((sphereKey, idx) => (
											<div
												className={`px-4 flex flex-row justify-between items-center`}
												key={idx}>
												<div>{sphereKey}</div>
												<input type="text"
															 className={`outline-none max-w-[4em] px-[18px] py-[10px] ring-[#B9B9B9] ring-[1px] rounded-[5px]
															 text-[#767676] text-[16px] font-[500] text-center`}
															 placeholder={'0-10'}
															 value={Object.values(spheres)[idx]}
															 onChange={e => changeSphereValue(e.target.value, sphereKey)}/>
											</div>
										))}
									</div>
								</div>
							</div>
							<div className={`flex flex-col gap-1`}>
								<label htmlFor="" className={`text-[16px]`}>MIT</label>
								<div
									className={`h-full focus:border-[#4285F4] font-light rounded-[9px] border border-[#ADADAD] flex flex-col items-center`}>
									<div
										className={`px-4 py-3 flex flex-row justify-between w-full cursor-pointer rounded-[9px] text-[#767676]
										${mitSelectOpened ? 'bg-white' : 'bg-[#F7F7F7]'}`}
										onClick={() => setMitSelectOpened(!mitSelectOpened)}>
										<span>Показать MIT</span>
										<ChevronDownIcon
											className={`text-[#767676] size-6 transition-transform  ${mitSelectOpened && 'rotate-180'}`}/>
									</div>
									<div
										className={`flex-col gap-2 w-full py-2 ${mitSelectOpened ? 'flex' : 'hidden'}`}>
										{Object.keys(mit).map((mitKey, idx) => (
											<div
												className={`px-4 flex flex-row justify-between items-center`}
												key={idx}>
												<div>{mitKey} (%)</div>
												<input type="text"
															 className={`outline-none max-w-[4em] px-[18px] py-[10px] ring-[#B9B9B9] ring-[1px] rounded-[5px]
															 text-[#767676] text-[16px] font-[500] text-center`}
															 placeholder={'0-100'}
															 value={Object.values(mit)[idx]}
															 onChange={e => changeMitValue(e.target.value, mitKey)}/>
											</div>
										))}
									</div>
								</div>
							</div>
							{(somethingIsBlank && showWarning) && (
								<div className={`w-full text-red-600 text-[16px] text-center`}>Заполните все поля!</div>
							)}
						</form>
						<button
							type={"button"}
							onClick={handleSubmit}
							className={`font-bold text-[16px] bg-[#1AAD7F] hover:bg-[#1AAD7F]/80 transition-colors w-full rounded-[10px] text-white py-3`}>
							Сохранить изменения
						</button>
						<button
							onClick={() => navigate(-1)}
							className={`font-bold text-[16px] text-[#1AAD7F] ring-2 ring-[#1AAD7F] hover:ring-[#1AAD7F]/50 transition-all w-full rounded-[10px] py-3`}>
							Назад
						</button>
					</div>
				</div>
			</Container>
		</div>
	);
}

export default EditStudent;
