import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import translationRU from './locales/ru/translation.json'
import translationEN from './locales/en/translation.json'
import translationKZ from './locales/kz/translation.json'

const getSavedLanguage = () => {
    const savedLanguage = localStorage.getItem('savedLang');
    return savedLanguage || 'ru'; // Возвращаем 'ru', если сохраненного языка нет
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        debug: true,
        fallbackLng: "ru",
        lng: getSavedLanguage(),

        interpolation: {
            escapeValue: false
        },
        resources: {
            ru: {
                translation: translationRU
            },
            en: {
                translation: translationEN
            },
            kz: {
                translation: translationKZ
            }
        }
    });

export default i18n;