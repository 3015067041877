import React from 'react';

function RobotoHeadingWithMark({children}) {
    return (
        <span
            className={`select-none relative text-[#3E3232] font-roboto font-[500] text-[16px] md:text-[20px] before:-translate-y-1/2
            before:top-1/2 before:left-[-12px] ml-[12px] w-fit before:bg-[#393DA1] before:w-[4px] before:h-[10px] before:rounded-[12px] before:absolute`}>{children}
            </span>
    );
}

export default RobotoHeadingWithMark;