import React from 'react'
import '../../assets/css/main.css'
import '../../assets/css/uniqueness.css'
import uniquenessImage from '../../assets/images/uniqueness.png'
import {ChevronRightIcon} from '@heroicons/react/24/solid'
import Container from "../Container";
import {useTranslation} from "react-i18next";


const Uniqueness = () => {
    const {t, i18n} = useTranslation('translation', {keyPrefix: 'main.uniqueness'})
    return (
        <div className="xl:mt-[12em] md:mt-[8em] mt-[4em] text-start relative">
            <div className={`absolute xl:block hidden top-0 right-0 3xl:left-[calc(50%+30em)] `}>
                <svg width="234" height="234" viewBox="0 0 234 234" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M22.0456 129.585C23.3158 130.176 25.0782 129.474 26.0189 128.019C37.3591 110.993 50.3089 99.5387 64.4518 93.875C78.2232 88.3619 93.0339 88.5466 108.44 94.3359C111.858 95.5782 113.937 95.8453 118.999 100.198C113.999 108.198 111.754 116.646 111.445 122.422C110.991 130.951 118.499 137.198 123.146 139.182C127.792 141.166 130.604 141.316 133 140.922C136.066 140.413 140.448 139.173 144.499 133.698C148.939 127.698 150.155 120.186 147 112.922C145.336 109.105 137.694 100.958 134.499 97.6983C137.5 94.3359 141.992 92.4679 146.499 91.1983C156.031 88.4511 164.499 87.922 176.999 90.9219C186.741 93.26 207.55 100.838 214.999 105.422C221.499 109.422 223.541 111.03 226.499 112.422C231.033 114.592 230.945 110.922 229.999 106.422C226.499 96.9216 203.999 91.3377 193.999 86.3374C178.999 80.4221 161.51 77.6405 146.114 81.6983C138.902 83.6312 130.857 86.61 126.499 91.784C120.94 87.5728 117.489 85.6653 113.829 84.4425C95.7589 78.64 78.5947 79.3501 62.9706 86.3372C47.3607 93.3097 33.2234 106.492 21.4443 125.867C20.5149 127.333 20.7755 128.994 22.0456 129.585ZM132.999 111.198C135.499 113.698 137.449 116.493 137.999 118.698C138.694 121.505 137.998 126.036 135.884 128.887C135.524 129.365 134.499 131.698 129.999 132.198C123.146 131.198 122.263 125.334 121.999 123.698C120.499 116.698 124.499 110.698 126.999 105.698C129.499 107.698 132.83 110.917 132.999 111.198Z"
                          fill="#47DAAC"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M129.066 130.085C128.768 130.049 128.488 130.061 128.541 130.256C128.614 130.5 128.791 130.387 129.066 130.085Z"
                          fill="#47DAAC"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M27.3745 121.568C28.2403 118.376 29.0359 115.03 29.4964 111.956C30.1954 107.167 30.1722 103.043 28.6082 100.643C28.0376 99.3646 26.378 99.0787 24.9305 100.005C23.4716 100.92 22.7568 102.712 23.3418 103.976C23.5286 104.647 23.3811 105.477 23.2615 106.392C22.9976 108.541 22.0476 111.047 21.0108 113.754C19.6846 117.209 18.0013 120.8 16.5965 124.063C15.316 127.018 14.2824 129.699 13.6771 131.742C12.7199 134.958 12.9967 137.237 13.5137 138.435C13.6542 138.745 17.5027 142.677 22.4003 138.609C24.1503 137.148 28.4455 133.261 28.6576 133.069C31.2118 130.565 35.9673 125.983 40.669 122.726C42.1543 121.693 43.5935 120.73 44.9506 120.144C45.0412 120.234 45.1319 120.323 45.2369 120.397C46.7768 121.598 49.3192 121.032 50.955 119.144C51.8756 118.186 52.9428 116.083 53.1461 114.992C53.5323 112.877 52.548 111.679 50.5109 111.165C47.7369 110.47 44.0267 111.406 39.9887 113.309C35.7476 115.329 31.1025 118.642 27.3745 121.568ZM24.1794 131.929C23.9615 132.604 23.9218 133.052 24.3468 132.983C24.8231 132.907 24.4023 132.292 24.1794 131.929Z"
                          fill="#47DAAC"/>
                </svg>
            </div>
            <div className='xl:block hidden absolute top-[8em] left-[calc(50%+5em)] -translate-x-1/2'>
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <circle cx="7.50049" cy="7.50189" r="7.5" fill="#EB5652"/>
                </svg>
            </div>
            <div className='xl:block hidden  absolute top-[12.5em] 3xl:left-[calc(50%+34em)] right-[12.5em]'>
                <svg width="63" height="63" viewBox="0 0 63 63" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <circle cx="26.5" cy="26.5018" r="26.5" fill="#FFD542"/>
                </svg>
            </div>
            <div className='3xl:block hidden absolute bottom-[10em] 3xl:left-[calc(50%+40em)] right-[12.5em]'>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <circle cx="11.9995" cy="12.0019" r="12" fill="#47DAAC"/>
                </svg>
            </div>
            <Container>
					<span className="font-bold text-[42px] select-none xl:block hidden">
						{t('title')}
					</span>
                <div className="flex justify-between gap-10 xl:py-24 flex-col xl:flex-row select-none">
                    <div className="w-[55%] xl:block hidden">
                        <img src={uniquenessImage} className={`object-cover pointer-events-none`}/>
                    </div>
                    <div className="relative gap-6 flex flex-col 3xl:pt-12">
                        <div className='text-left text-[16px] md:text-[32px] font-bold'>
                            {t('subtitle.0')}<span className={`text-[#393DA1]`}> {t('subtitle.1')}</span>
                        </div>
                        <p className='text-left  text-[12px] md:text-[18px] text-[#646565]'>
                            1.&nbsp;{t('reasons.0')}<br/><br/>
                            2.&nbsp;{t('reasons.1')}<br/><br/>
                            3.&nbsp;{t('reasons.2')}<br/><br/>
                            4.&nbsp;{t('reasons.3')}<br/><br/>
                        </p>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default Uniqueness;
