import React from 'react';
import RobotoHeadingWithMark from "./RobotoHeadingWithMark";
import WhitePanel from "./WhitePanel";
import TopPost from "./TopPost";
import {useTranslation} from "react-i18next";
import {news} from "./news"

function TopPosts({topPostList, setCurrentArticle}) {
    const {t, i18n} = useTranslation('translation', {keyPrefix: 'news'})

    return (
        <WhitePanel>
            <RobotoHeadingWithMark>{t('topPosts')}</RobotoHeadingWithMark>
            {topPostList.map((postIndex) => {
                return <TopPost heading={news[postIndex].heading}
                                subheading={news[postIndex].subheading}
                                image={news[postIndex].heroImage}
                                key={news[postIndex].id}
                                id={news[postIndex].id}
                                setCurrentArticle={setCurrentArticle}></TopPost>
            })}
        </WhitePanel>
    );
}

export default TopPosts;