const initialState = {
	testResults: [],
	loading: false,
	error: null,
}

export default function (state = initialState, action) {
	const {type} = action;

	switch (type) {
		case 'FETCH_TEST_RESULTS_BEGIN':
			return {
				...state,
				loading: true,
				error: null
			};
		case 'FETCH_TEST_RESULTS_SUCCESS':
			return {
				...state,
				testResults: action.testResults,
				loading: false,
			};
		case 'FETCH_TEST_RESULTS_FAILURE':
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
}
