import GallupService from "../services/gallup.service";
import {
	GALLUP_LIST_FETCHED_SUCCESS,
	GALLUP_LIST_FETCHED_FAILURE,
	GALLUP_LIST_FETCHED_BEGIN,
	GALLUP_BY_ID_FETCHED_SUCCESS,
	GALLUP_BY_ID_FETCHED_BEGIN,
	GALLUP_BY_ID_FETCHED_FAILURE,
	GALLUP_PROF_REPORTS_FETCHED_SUCCESS,
	GALLUP_PROF_REPORTS_FETCHED_BEGIN,
	GALLUP_PROF_REPORTS_FETCHED_FAILURE,
	GALLUP_PROF_REPORTS_NEW_FETCHED_SUCCESS,
	GALLUP_PROF_REPORTS_NEW_FETCHED_BEGIN,
	GALLUP_PROF_REPORTS_NEW_FETCHED_FAILURE,
	GALLUP_FIELDS_CHOSEN_SUCCESS,
	GALLUP_FIELDS_CHOSEN_BEGIN,
	GALLUP_FIELDS_CHOSEN_FAILURE
} from "./actionTypes/gallupActionTypes";


export const fetchGallupListBegin = () => ({
	type: GALLUP_LIST_FETCHED_BEGIN
});


export const fetchGallupList = () => (dispatch) => {
    dispatch(fetchGallupListBegin());
    return GallupService.fetchGallupList().then(
        (data) => {

            dispatch({
                type: GALLUP_LIST_FETCHED_SUCCESS,
                gallupList: data,
            });

            return Promise.resolve();
        },
        (error) => {
            dispatch({
                type: GALLUP_LIST_FETCHED_FAILURE,
                error: error.response.data
            });

            return Promise.reject();
        }
    );
};

export const fetchGallupListFailure = error => ({
	type: GALLUP_LIST_FETCHED_FAILURE,
	payload: {error}
});


export const fetchGallupByIdBegin = () => ({
	type: GALLUP_BY_ID_FETCHED_BEGIN
});


export const fetchGallupById = (gallupId) => (dispatch) => {
	dispatch(fetchGallupByIdBegin());
	return GallupService.fetchGallupById(gallupId).then(
		(data) => {
			dispatch({
				type: GALLUP_BY_ID_FETCHED_SUCCESS,
				gallupObject: data,
			});

			return Promise.resolve();
		},
		(error) => {
			dispatch({
				type: GALLUP_BY_ID_FETCHED_FAILURE,
				error: error.response.data
			});

			return Promise.reject();
		});
};

export const fetchGallupById_new = (gallupId) => (dispatch) => {
	dispatch(fetchGallupByIdBegin());
	return GallupService.fetchGallupById(gallupId).then(
		(data) => {
			dispatch({
				type: GALLUP_BY_ID_FETCHED_SUCCESS,
				gallupObject: data,
			});
			return Promise.resolve(data); 
		},
		(error) => {
			dispatch({
				type: GALLUP_BY_ID_FETCHED_FAILURE,
				error: error.response.data
			});
			return Promise.reject(error);
		});
};

export const fetchGallupByIdFailure = error => ({
	type: GALLUP_BY_ID_FETCHED_FAILURE,
	payload: {error}
});

export const fetchGallupProfReportsBegin = () => ({
	type: GALLUP_PROF_REPORTS_FETCHED_BEGIN
});


export const fetchGallupProfReports = (gallupId, regenerate = false) => (dispatch) => {
	dispatch(fetchGallupProfReportsBegin());
	return GallupService.fetchProfReports(gallupId, regenerate).then(
	  (data) => {
		dispatch({
		  type: GALLUP_PROF_REPORTS_FETCHED_SUCCESS,
		  profReports: data,
		});
  
		return Promise.resolve();
	  },
	  (err) => {
		dispatch(fetchGallupProfReportsFailure());
  
		return Promise.reject(err);
	  }
	);
  };

export const fetchGallupProfReportsNewBegin = () => ({
	type: GALLUP_PROF_REPORTS_NEW_FETCHED_BEGIN
});

export const fetchGallupProfReportsNewFailure = error => ({
	type: GALLUP_PROF_REPORTS_NEW_FETCHED_FAILURE,
	payload: {error}
});

export const fetchGallupProfReportsNew = (gallupId, regenerate = false) => (dispatch) => {
	dispatch(fetchGallupProfReportsNewBegin());
	return GallupService.fetchProfReportsNew(gallupId, regenerate).then(
	  (data) => {
		dispatch({
		  type: GALLUP_PROF_REPORTS_NEW_FETCHED_SUCCESS,
		  profReportsNew: data,
		});
		console.info(data)
		return Promise.resolve();
	  },
	  (err) => {
		dispatch(fetchGallupProfReportsNewFailure());
  
		return Promise.reject(err);
	  }
	);
  };

export const downloadFile = (gallupId) => (dispatch) => {
    return GallupService.downloadFile(gallupId).then(
        (url) => {
            const cleanUrl = url.replace(/^"|"$/g, '');
            window.open(cleanUrl, '_blank');
        },
        (error) => {
            console.error(error);
        }
    );
};

export const downloadFile_New = (gallupId) => (dispatch) => {
    return GallupService.downloadFile_New(gallupId).then(
        (url) => {
            const cleanUrl = url.replace(/^"|"$/g, '');
            window.open(cleanUrl, '_blank');
        },
        (error) => {
            console.error(error);
        }
    );
};

export const fetchGallupProfReportsFailure = error => ({
	type: GALLUP_PROF_REPORTS_FETCHED_FAILURE,
	payload: {error}
});

export const chooseProfFieldsBegin = () => ({
	type: GALLUP_FIELDS_CHOSEN_BEGIN,
});

export const chooseProfFieldsSuccess = (data) => ({
	type: GALLUP_FIELDS_CHOSEN_SUCCESS,
	payload: { data },
});

export const chooseProfFieldsFailure = (error) => ({
	type: GALLUP_FIELDS_CHOSEN_FAILURE,
	payload: { error },
});

export const chooseProfFields = (gallupId, fields) => (dispatch) => {
	dispatch(chooseProfFieldsBegin());
	return GallupService.chooseProfFields(gallupId, fields).then(
		(data) => {
			dispatch(chooseProfFieldsSuccess(data));
			return Promise.resolve();
		},
		(error) => {
			dispatch(chooseProfFieldsFailure(error));
			return Promise.reject(error);
		}
	);
};




