const initialState = {
	reports: [],
	loading: false,
	error: null,
}

export default function (state = initialState, action) {
	const {type} = action;

	switch (type) {
		case 'FETCH_REPORTS_BEGIN':
			return {
				...state,
				loading: true,
				error: null
			};
		case 'FETCH_REPORTS_SUCCESS':
			return {
				...state,
				reports: action.reports,
				loading: false,
			};
		case 'FETCH_REPORTS_FAILURE':
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
}
