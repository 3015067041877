const initialState = {
	gallupList: [],
	gallupObject: [],
	profReports: [],
	profReportsNew: [],
	loading: false,
	error: null,
}

export default function (state = initialState, action) {
	const {type} = action;

	switch (type) {
		case 'GALLUP_LIST_FETCHED_BEGIN':
			return {
				...state,
				loading: true,
				error: null
			};
		case 'GALLUP_LIST_FETCHED_SUCCESS':
			return {
				...state,
				gallupList: action.gallupList,
				loading: false,
			};
		case 'GALLUP_LIST_FETCHED_FAILURE':
			return {
				...state,
				loading: false,
				error: action.error
			};

		case 'GALLUP_BY_ID_FETCHED_BEGIN':
			return {
				...state,
				loading: true,
				error: null
			};
		case 'GALLUP_BY_ID_FETCHED_SUCCESS':
			return {
				...state,
				gallupObject: action.gallupObject,
				loading: false,
			};
		case 'GALLUP_BY_ID_FETCHED_FAILURE':
			return {
				...state,
				loading: false,
				error: action.error
			};

		case 'GALLUP_PROF_REPORTS_FETCHED_BEGIN':
			return {
				...state,
				loading: true,
				error: null
			};
		case 'GALLUP_PROF_REPORTS_FETCHED_SUCCESS':
			return {
				...state,
				profReports: action.profReports,
				loading: false,
			};
		case 'GALLUP_PROF_REPORTS_FETCHED_FAILURE':
			return {
				...state,
				loading: false,
				error: action.error
			};

		case 'GALLUP_PROF_REPORTS_NEW_FETCHED_BEGIN':
			return {
				...state,
				loading: true,
				error: null
			};
		case 'GALLUP_PROF_REPORTS_NEW_FETCHED_SUCCESS':
			return {
				...state,
				profReportsNew: action.profReportsNew,
				loading: false,
			};
		case 'GALLUP_PROF_REPORTS_NEW_FETCHED_FAILURE':
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
}
