import carouselHuman_2 from '../../../assets/images/carouselHuman_2.png'
import icon1 from '../../../assets/images/slide2_icon1.png'
import icon2 from '../../../assets/images/slide2_icon2.png'
import icon3 from '../../../assets/images/slide2_icon3.png'

import Slide from "./Slide";
import React from 'react';
import Slide2Card from "./Slide2Card";
import carouselIntersect_2 from "../../../assets/images/carouselIntersect_2.png";
import {useTranslation} from "react-i18next";

export default function SecondSlide() {
	const {t, i18n} = useTranslation('translation', {keyPrefix: 'main.secondSlide'})

	return (
		<Slide>
			<div
				className={`lg:block hidden relative select-none fhd:px-[14em] px-[6em]`}>
				<div
					className="flex flex-col gap-8 relative fhd:max-w-[1600px] qhd:max-w-[1700px] mx-auto py-[5em] text-left z-[10]">
					<div className={`font-bold text-[60px] text-[#464255] uppercase leading-[70.5px]`}>
						{t('name.0')}<span className={`text-[#393DA1]`}>{t('name.1')}</span><br/>{t('name.2')}
					</div>
					<div className={`font-montserrat font-[500] text-[20px] leading-[34px] text-[#464255]`}>
						{t('speech')}
					</div>
					<div className={`grid grid-rows-3 gap-4 xl:w-[60%] w-full z-[10]`}>
						<Slide2Card icon={icon1}
												heading={'Input + Relator + Self-Assurance'}
												cardNumber={1}>
							{t('cardText.0')}
						</Slide2Card>
						<Slide2Card icon={icon2}
												heading={'Learner + Responsibility + Achiever'}
												cardNumber={2}>
							{t('cardText.1')}
						</Slide2Card>
						<Slide2Card icon={icon3}
												heading={'Connectedness + Focus + Activator'}
												cardNumber={3}>
							{t('cardText.2')}
						</Slide2Card>
					</div>
					<img src={carouselHuman_2} alt=""
							 className="absolute bottom-0 right-0 fhd:right-[-4em] object-cover hidden xl:block h-[90%] select-none pointer-events-none"/>
				</div>
			</div>

			{/* mobile */}
			<div className={`bg-white lg:hidden relative select-none`}>
				<div className={`relative mx-auto sm:px-4 px-2 container`}>
					<div
						className="flex flex-col gap-4 relative sm:px-[3em] pt-[2.5em] pb-[5em] text-left z-[10] h-fit">
						<div className="flex flex-row w-full">
							<div className="flex flex-col gap-4 w-full *:w-full">
								<div
									className={`font-bold text-[20px] md:text-[40px] text-[#464255] uppercase leading-[23.5px] md:leading-[46px]`}>
									{t('name.0')}<span
									className={`text-[#393DA1]`}>{t('name.1')}</span>
									<div className={`pe-[2em]`}>{t('name.2')}</div>
								</div>
								<div
									className={`font-[500] text-[12px] md:text-[18px] leading-[17px] md:leading-[24px] text-[#464255] pe-[2.9em] `}>
									{t('speech')}
								</div>
							</div>
							<div className={`basis-[50%] relative md:scale-[150%] md:translate-y-[2.5em]`}>
								<div className={`absolute size-[166px] xs:right-0 right-[-37px]`}>
									<div
										className={`bg-[#50D3FF] rounded-full size-[166px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`}></div>
									<div
										className={`bg-white rounded-full size-[162px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`}></div>
									<div
										className={`bg-[#393DA1] rounded-full size-[150px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`}></div>
									<img src={carouselIntersect_2} alt=""
											 className={`absolute h-[175px] top-1/2 left-1/2 -translate-x-[calc(50%-4px)] -translate-y-[calc(50%+11px)]`}/>
								</div>
							</div>
						</div>
						<div className="grid grid-rows-3 gap-2 z-[10] mt-4">
							<Slide2Card icon={icon1}
													heading={'Input + Relator + Self-Assurance'}
													cardNumber={1}>
								{t('cardText.0')}
							</Slide2Card>
							<Slide2Card icon={icon2}
													heading={'Learner + Responsibility + Achiever'}
													cardNumber={2}>
								{t('cardText.1')}
							</Slide2Card>
							<Slide2Card icon={icon3}
													heading={'Connectedness + Focus + Activator'}
													cardNumber={3}>
								{t('cardText.2')}
							</Slide2Card>
						</div>
					</div>
				</div>
			</div>
		</Slide>
	);
}
