import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {uploadUserData} from "../../../actions/uploadUserDataActions";
import Container from "../../Container";
import {ChevronDownIcon} from "@heroicons/react/24/solid";

function Upload() {
    const mbtiFirstParts = ['IN', 'IS', 'EN', 'ES']
    const mbtiSecondParts = ['TJ', 'TP', 'FJ', 'FP']

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const params = useParams()

    const [gallupFile, setGallupFile] = useState(null);
    const [mbti, setMbti] = useState('');
    const [mbtiPart1, setMbtiPart1] = useState(mbtiFirstParts[0]);
    const [mbtiPart2, setMbtiPart2] = useState(mbtiSecondParts[0]);
    const [mit, setMit] = useState({
        "Linguistic": 0,
        "Logical–mathematical": 0,
        "Musical": 0,
        "Bodily–kinesthetic": 0,
        "Spatial": 0,
        "Interpersonal": 0,
        "Intra–personal": 0,
        "Naturalistic": 0,
        "Existential": 0
    });

    const [mitSelectOpened, setMitSelectOpened] = useState(true);
    const [somethingIsBlank, setSomethingIsBlank] = useState(true);
    const [showWarning, setShowWarning] = useState(false);
    const [loading, setLoading] = useState(false);  // New state for loading

    const checkBlank = () => {
        if (mbti !== '' && JSON.stringify(mit) !== '' && gallupFile) {
            setSomethingIsBlank(false)
        } else setSomethingIsBlank(true)
    }

    const changeMitValue = (eValue, key) => {
        let newMit = {...mit}
        if (eValue === '') {
            newMit[key] = 0
            setMit(newMit)
            return
        }
        if (eValue.length === 2 && eValue[0] === '0') {
            newMit[key] = Number(eValue[1])
            setMit(newMit)
        }
        if (parseInt(eValue) >= 0 && parseInt(eValue) <= 100) {
            newMit[key] = parseInt(eValue)
            setMit(newMit)
        }
    }

    const handleSubmit = () => {
        setShowWarning(true);
        checkBlank();

        if (!somethingIsBlank) {
            setLoading(true);  // Set loading to true when submission starts
            let stringMit = mit;
            Object.keys(stringMit).map((key) => stringMit[key] = stringMit[key].toString());

            const formData = new FormData();
            formData.append('userId', params.userId);
            formData.append('file', gallupFile);
            formData.append('MBTI', mbti);
            formData.append('MIT', JSON.stringify(stringMit));

            // Dispatch and navigate after the action completes
            dispatch(uploadUserData(formData))
                .then(() => {
                    setLoading(false);  // Set loading to false when submission completes
                    navigate(`/dashboard`);
                })
                .catch((error) => {
                    setLoading(false);  // Set loading to false if there's an error
                    // Handle error if needed
                    console.error("Error uploading data:", error);
                });
        }
    };

    useEffect(() => {
        checkBlank()
    }, [mbti, mit, gallupFile]);

    useEffect(() => {
        setMbti(`${mbtiPart1}${mbtiPart2}`)
    }, [mbtiPart1, mbtiPart2]);

    return (
        <div className={`bg-[#eaf8fc]  min-h-screen`}>
            <Container>
                <div
                    className={`pb-4 rounded-[15px] lg:max-w-[40em] w-full select-none mx-auto`}>
                    <div
                        className={`no-scrollbar bg-white relative rounded-[15px] gap-4 shadow-md flex flex-col md:p-6 p-3 text-left`}>
                        <span
                            className={`font-bold text-[24px] md:text-[32px] text-center`}>Загрузите результаты ваших тестов</span>
                        <form className={`flex flex-col gap-4`} autoComplete="off">
							<div className={`flex flex-col gap-1`}>
								<label htmlFor="gallupFile" className={`text-[16px] font-semibold`}>Загрузите ваш результат Gallup
									теста</label>
								<input aria-autocomplete="list"
											 itemID={'gallupFile'}
											 accept=".pdf,.csv"
											 type="file" id={``}
											 onChange={e => setGallupFile(e.target.files[0])}
											 className={`appearance-none shadow-none outline-none focus:border-[#4285F4] font-light px-4 py-3 rounded-[9px] border border-[#ADADAD] w-full`}/>

							</div>
							<div className={`flex flex-col gap-1`}>
								<label htmlFor="" className={`text-[16px] font-semibold`}>Результаты индикатора Майерс-Бриггс
									(MBTI)</label>
								<div className={`flex flex-row gap-2 
								*:shadow-none *:outline-none *:focus:border-[#4285F4] *:font-light *:px-4 *:py-3 *:rounded-[9px] *:border *:border-[#ADADAD] *:w-full`}>
									<select name="mbti1" id="mbti1"
													onChange={e => setMbtiPart1(e.target.value)}
													value={mbtiPart1}>
										{mbtiFirstParts.map((part, idx) => (
											<option value={part} key={idx}>{part}</option>
										))}
									</select>
									<select name="mbti2" id="mbti2"
													onChange={e => setMbtiPart2(e.target.value)}
													value={mbtiPart2}>
										{mbtiSecondParts.map((part, idx) => (
											<option value={part} key={idx}>{part}</option>
										))}
									</select>
								</div>
							</div>
							<div className={`flex flex-col gap-1`}>
								<label htmlFor="" className={`text-[16px] font-semibold`}>Результаты теста на тип интеллекта
									(MIT)</label>
								<div
									className={`h-full focus:border-[#4285F4] font-light rounded-[9px] border border-[#ADADAD] flex flex-col items-center`}>
									<div
										className={`px-4 py-3 flex flex-row justify-between w-full cursor-pointer rounded-[9px] text-[#767676]
										${mitSelectOpened ? 'bg-white' : 'bg-[#F7F7F7]'}`}
										onClick={() => setMitSelectOpened(!mitSelectOpened)}>
										<span>Показать MIT</span>
										<ChevronDownIcon
											className={`text-[#767676] size-6 transition-transform  ${mitSelectOpened && 'rotate-180'}`}/>
									</div>
									<div
										className={`flex-col gap-2 w-full py-2 ${mitSelectOpened ? 'flex' : 'hidden'}`}>
										{Object.keys(mit).map((mitKey, idx) => (
											<div
												className={`px-4 flex flex-row justify-between items-center`}
												key={idx}>
												<div>{mitKey} (%)</div>
												<input type="text"
															 className={`outline-none max-w-[4em] px-[18px] py-[10px] ring-[#B9B9B9] ring-[1px] rounded-[5px]
															 text-[#767676] text-[16px] font-[500] text-center`}
															 placeholder={'0-100'}
															 value={Object.values(mit)[idx]}
															 onChange={e => changeMitValue(e.target.value, mitKey)}/>
											</div>
										))}
									</div>
								</div>
							</div>
							{(somethingIsBlank && showWarning) && (
								<div className={`w-full text-red-600 text-[16px] text-center`}>Заполните все поля!</div>
							)}
                        </form>
                        <button
                            type={"button"}
                            onClick={handleSubmit}
                            className={`font-bold text-[16px] bg-[#1AAD7F] hover:bg-[#1AAD7F]/80 transition-colors w-full rounded-[10px] text-white py-3`}>
                            Полный разбор
                        </button>
                    </div>
                </div>
                {loading && (  // Display loading message when loading is true
                    <div className={`text-center text-[18px] text-blue-600 mt-4`}>
                        Идет загрузка данных...
                    </div>
                )}
            </Container>
        </div>
    );
}

export default Upload;