import NewUserService from "../services/newUser.service";
import {
	REGISTER_SUCCESS,
	REGISTER_FAILURE,
} from "./actionTypes/newUserActionTypes";

export const registerUser = (userData) => (dispatch) => {
	return NewUserService.register(userData).then(
		(data) => {
			dispatch({
				type: REGISTER_SUCCESS,
				payload: {user: data},
			});

			return Promise.resolve(data);
		},
		(error) => {
			const message =
				(error.response &&
					error.response.data &&
					error.response.data.message) ||
				error.message ||
				error.toString();

			dispatch({
				type: REGISTER_FAILURE,
				error: message
			});

			return Promise.reject();
		}
	);
};