import TestResultService from "../services/testResult.service";
import {
    FETCH_TEST_RESULTS_BEGIN,
    FETCH_TEST_RESULTS_SUCCESS,
    FETCH_TEST_RESULTS_FAILURE
} from "./actionTypes/testResultActionTypes";


export const fetchTestResultsBegin = () => ({
	type: FETCH_TEST_RESULTS_BEGIN
});

export const fetchTestResults = (gallupId) => (dispatch) => {
	dispatch(fetchTestResultsBegin());
	return TestResultService.fetchTestResults(gallupId).then(
		(data) => {
			dispatch({
				type: FETCH_TEST_RESULTS_SUCCESS,
                testResults: data
			});

			return Promise.resolve();
		},
		(error) => {
            debugger
            let errorMessage = "general";
            if (error.hasOwnProperty('code')) {
                errorMessage = "networkError";
            }
            else {
                errorMessage = "pending";
            }

			dispatch({
				type: FETCH_TEST_RESULTS_FAILURE,
				error: errorMessage
			});

			return Promise.reject("Error");
		});
};

export const fetchTestResultsFailure = error => ({
	type: FETCH_TEST_RESULTS_FAILURE,
	payload: {error}
});
