import React, {useState} from 'react'
import Header from './main/Header'
import Footer from './main/Footer'
import Uniqueness from './main/Uniqueness'
import WithBanner from './main/WithBanner';
import CarouselBlock from './main/CarouselBlock';
import InteractiveMap from './main/InteractiveMap';
import RequestBlock from './main/RequestBlock';
import HelpBlock from './main/HelpBlock';
import SignUpModal from "./main/SignUpModal";

const Main = () => {
	const [isModalShown, setIsModalShown] = useState(false);
	const showModal = () => setIsModalShown(true)
	const closeModal = () => setIsModalShown(false)
	return (
		<>
			{isModalShown && <SignUpModal closeModal={closeModal}/>}
			<Header showModal={showModal}/>
			<Uniqueness/>
			<WithBanner/>
			<HelpBlock showModal={showModal}/>
			<CarouselBlock/>
			<InteractiveMap/>
			<RequestBlock showModal={showModal}/>
		</>
	);
}

export default Main;
