import React from 'react';
import BusinessIcon from "../../assets/images/Business.png";

function HelpBlockCardMobile({icon, title = '', content = '', iconBgColor}) {
    return (
        <div className='bg-white flex flex-col gap-4 w-full overflow-hidden ring-[#CACACA] ring-1 rounded-[20px] p-4'>
            <div className='flex items-center gap-2'>
                <div
                    className={`relative grid place-items-center min-w-fit p-[11px] md:p-[15px] rounded-[11px]
                    bg-[${iconBgColor}]`}>
                    <img src={icon} className='pointer-events-none size-[24px] md:size-[51px]'/>
                </div>
                <div className='text-left pl-2 tetx-[16px] md:text-[24px] font-bold'>{title}</div>
            </div>
            <div
                className='font-[500] text-left text-[12px] leading-[14.1px] md:leading-[18.8px] md:text-[16px] text-[#16172A]/70'>
                {content}
            </div>
        </div>
    );
}

export default HelpBlockCardMobile;