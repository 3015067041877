import React, {useState} from 'react'
import '../../assets/css/main.css'
import '../../assets/css/helpBlock.css'

import BusinessIcon from '../../assets/images/Business.png'
import FamilyIcon from '../../assets/images/Family.png'
import PopularManIcon from '../../assets/images/PopularMan.png'
import GraduationHatIcon from '../../assets/images/GraduationHat.png'

import helpWomanImage from '../../assets/images/help_bg.png'
import helpBusinessGuyImage from '../../assets/images/helpBusinessGuyImage.png'
import helpFamilyImage from '../../assets/images/helpFamilyImage.png'
import helpSchoolboyImage from '../../assets/images/Schoolboy.png'

import {ChevronUpIcon} from "@heroicons/react/24/solid";
import Container from "../Container";
import HelpBlockCardMobile from "./helpBlockCardMobile";
import {useTranslation} from "react-i18next";


const HelpBlock = ({showModal}) => {
	const {t, i18n} = useTranslation('translation', {keyPrefix: 'main.help'})
	const [hoveredCard, setHoveredCard] = useState(1);
	return (
		<div className="relative bg-[#EAF8FC] pt-8 overflow-hidden">
			<div
				className="hidden xl:flex [&>*:not(:first-child)]:scale-[82%] 2xl:[&>*:not(:first-child)]:scale-[97%] 3xl:[&>*:not(:first-child)]:scale-100 transform-gpu flex-col gap-40">
				<div className="flex flex-col gap-4 select-none">
                    <span
											className='font-bold text-[48px] leading-[56.4px]'>{t('title.0')}&nbsp;{t('title.1')}</span>
					<p className='text-center text-[20px] leading-[23.5px] text-[#515151]'>
						{t('description.0')}<br/>
						{t('description.1')}
					</p>
				</div>

				<div
					className="relative mx-auto bottom-2 w-fit grid grid-cols-2 gap-2 grid-rows-2">
					{/*Секторы*/}
					<div
						className={`w-[270px] h-[270px] bg-[#FFD542] rounded-tl-full
                        ${hoveredCard === 1 ? 'scale-[110%] -translate-x-[15px] -translate-y-[15px]' : ''}
                        transition-all transform-gpu`}></div>
					<div
						className={`w-[270px] h-[270px] bg-[#47DAAC] rounded-tr-full
                        ${hoveredCard === 2 ? 'scale-[110%] translate-x-[15px] -translate-y-[15px]' : ''}
                        transition-all transform-gpu`}></div>
					<div
						className={`w-[270px] h-[270px] bg-[#EB5652] rounded-bl-full
                        ${hoveredCard === 3 ? 'scale-[110%] -translate-x-[15px] translate-y-[15px] ' : ''}
                        transition-all transform-gpu`}></div>
					<div
						className={`w-[270px] h-[270px] bg-[#50D3FF] rounded-br-full
                        ${hoveredCard === 4 ? 'scale-[110%] translate-x-[15px] translate-y-[15px]' : ''}
                        transition-all transform-gpu`}></div>
					{/*круги*/}
					<div
						className="w-[500px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  h-[500px] bg-[#EAF8FC] rounded-full shadow-[inset_rgba(34,36,38,0.15)_0px_0px_3px_2px]"></div>
					<div
						className="w-[450px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  h-[450px] bg-white rounded-full"></div>
					{/*Стрелки*/}
					<div className='absolute top-[-80px] left-[-20px] z-10 '>
						<svg width="138" height="140" viewBox="0 0 138 140" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd"
										d="M11.7814 49.3189C12.3908 50.0577 13.5501 50.1506 14.3914 49.5394C24.4289 42.4792 34.2445 39.3086 43.5531 40.0383C52.6168 40.7499 61.1042 45.2591 68.7792 53.3771C70.4904 55.1522 71.632 55.9328 73.6489 60.1011C69.1216 63.5159 66.0875 68.0225 64.7191 71.4674C62.6996 76.5555 65.734 82.61 67.9996 85.2039C70.2652 87.7978 71.8528 88.7243 73.3133 89.1941C75.1832 89.7928 77.9611 90.3342 81.4219 88.184C85.2144 85.8277 87.4629 81.5887 87.144 76.2034C86.9733 73.3722 84.2534 66.1149 83.0866 63.1709C85.5068 62.0025 88.4778 62.192 91.3341 62.7524C97.387 63.8992 102.371 66.0888 108.948 71.6361C114.074 75.9595 124.49 86.7763 127.833 91.7944C130.75 96.1731 131.594 97.7639 133.009 99.4942C135.172 102.169 135.878 99.8952 136.261 96.8591C136.204 90.0023 124.404 79.9047 119.679 73.8746C112.263 65.8001 102.769 58.9055 93.0708 56.8207C88.5205 55.8635 83.2754 55.2997 79.7007 57.1746C77.3686 52.9458 75.7755 50.7535 73.9208 48.9184C64.715 40.0015 54.6883 35.3417 44.2543 34.9828C33.8314 34.6192 22.9762 38.4952 12.2017 46.8638C11.3647 47.4852 11.1721 48.5801 11.7814 49.3189ZM79.4402 70.9925C80.364 73.2655 80.91 75.5553 80.7724 77.0693C80.594 78.9943 79.2592 81.5623 77.4543 82.6802C77.149 82.8664 76.0778 83.9911 73.3844 82.9616C69.6454 80.315 70.346 76.462 70.5316 75.382C71.1111 70.6503 74.6504 68.1634 77.1202 65.8436C78.147 67.8104 79.4008 70.77 79.4402 70.9925Z"
										fill="#FFD542"/>
							<path fillRule="evenodd" clipRule="evenodd"
										d="M73.2831 81.3909C73.1184 81.28 72.9552 81.2043 72.9454 81.3397C72.937 81.5104 73.0622 81.4944 73.2831 81.3909Z"
										fill="#FFD542"/>
							<path fillRule="evenodd" clipRule="evenodd"
										d="M16.5016 45.9907C17.6579 44.2936 18.8056 42.4806 19.7044 40.7349C21.094 38.0096 21.9307 35.4776 21.5251 33.5437C21.4602 32.5914 20.5639 31.9237 19.5396 32.0614C18.5112 32.1889 17.7305 33.0736 17.8066 34.0213C17.7757 34.4879 17.5198 34.9522 17.2624 35.4768C16.6675 36.7145 15.604 37.9671 14.4491 39.3173C12.9736 41.0392 11.2644 42.7385 9.78314 44.3195C8.43693 45.7489 7.2891 47.0843 6.51969 48.1554C5.30578 49.8406 4.99511 51.3188 5.04592 52.2053C5.06284 52.4372 6.46758 55.9873 10.1255 54.9496C11.4339 54.5748 14.7076 53.4694 14.8694 53.4143C16.8559 52.6391 20.5377 51.2453 23.9157 50.646C24.9835 50.4545 26.0105 50.2917 26.9124 50.3361C26.9462 50.4176 26.98 50.4992 27.025 50.576C27.664 51.7683 29.244 52.1766 30.5749 51.5059C31.3022 51.1926 32.3503 50.221 32.6921 49.6137C33.3503 48.4334 33.0307 47.4074 31.9641 46.4878C30.5105 45.2389 28.1819 44.7108 25.4651 44.6779C22.6076 44.6555 19.2507 45.3057 16.5016 45.9907ZM12.5265 51.3873C12.2621 51.7357 12.1468 51.9985 12.4056 52.0823C12.6955 52.1772 12.58 51.6757 12.5265 51.3873Z"
										fill="#FFD542"/>
						</svg>
					</div>
					<div className='absolute top-[-100px] right-[-20px] z-10 md:block hidden'>
						<svg width="131" height="128" viewBox="0 0 131 128" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd"
										d="M119.929 33.0272C119.458 33.867 118.276 34.1712 117.27 33.723C105.317 28.6055 94.4434 27.2807 84.9458 29.7067C75.6983 32.07 67.8768 38.0703 61.685 47.4783C60.297 49.5415 59.2828 50.5202 58.0994 54.9981C63.5406 57.5333 67.6708 61.4184 69.8416 64.5626C73.0461 69.2069 71.2184 75.7302 69.4337 78.7021C67.6489 81.6739 66.2041 82.8781 64.7914 83.6089C62.9821 84.5419 60.2184 85.5849 56.1593 84.1003C51.7112 82.4735 48.4544 78.7081 47.6109 73.3422C47.1706 70.5206 48.4097 62.8695 48.9783 59.754C46.2128 59.0463 43.172 59.7779 40.3311 60.8539C34.302 63.0941 29.6093 66.1658 23.996 72.839C19.6211 78.0398 11.1743 90.6103 8.80052 96.1688C6.72918 101.019 6.20063 102.742 5.1093 104.707C3.4487 107.739 2.22088 105.628 1.16115 102.706C-0.275056 95.9378 9.76466 83.8223 13.352 77.0128C19.2837 67.6953 27.6295 59.1595 37.2361 55.3265C41.7478 53.5487 47.066 52.0312 51.1833 53.2236C52.6804 48.6283 53.8551 46.1755 55.3789 44.0268C62.9847 33.5508 72.3701 27.1198 83.1161 24.8528C93.8493 22.5833 105.956 24.4127 118.958 30.6846C119.962 31.1436 120.4 32.1873 119.929 33.0272ZM54.4665 66.794C54.0038 69.2035 53.9367 71.5603 54.4096 73.0272C55.0143 74.8916 56.959 77.1778 59.0752 77.9486C59.4325 78.0761 60.7889 78.9881 63.3586 77.4796C66.6604 74.1856 65.0934 70.5168 64.6654 69.4865C63.0324 64.9294 58.8185 63.1274 55.7505 61.2941C55.1142 63.4207 54.4588 66.5675 54.4665 66.794Z"
										fill="#47DAAC"/>
							<path fillRule="evenodd" clipRule="evenodd"
										d="M63.1212 75.913C63.2678 75.7735 63.4206 75.669 63.4604 75.8006C63.5063 75.9673 63.3729 75.9745 63.1212 75.913Z"
										fill="#47DAAC"/>
							<path fillRule="evenodd" clipRule="evenodd"
										d="M114.307 30.6126C112.737 29.1521 111.151 27.5757 109.838 26.0201C107.802 23.589 106.382 21.247 106.381 19.2667C106.241 18.3162 107.025 17.4938 108.118 17.4417C109.212 17.3788 110.215 18.1076 110.343 19.0555C110.477 19.5097 110.843 19.9204 111.225 20.3902C112.112 21.5009 113.488 22.5404 114.981 23.6593C116.887 25.0858 119.031 26.4471 120.912 27.7336C122.62 28.8955 124.102 30.0011 125.134 30.9157C126.761 32.3539 127.405 33.7537 127.546 34.6368C127.579 34.8684 126.896 38.6247 122.875 38.2728C121.436 38.1434 117.798 37.6542 117.619 37.6296C115.389 37.2299 111.265 36.5313 107.631 36.5602C106.481 36.5672 105.38 36.5951 104.454 36.8042C104.437 36.8908 104.42 36.9773 104.39 37.0613C103.987 38.3535 102.437 39.0457 100.91 38.6287C100.087 38.4533 98.7879 37.688 98.3009 37.1521C97.3606 36.1095 97.4684 35.0397 98.3744 33.9379C99.61 32.4405 101.911 31.493 104.722 30.9624C107.681 30.4164 111.305 30.4416 114.307 30.6126ZM119.607 35.2023C119.957 35.4972 120.134 35.735 119.884 35.8651C119.604 36.0118 119.614 35.4963 119.607 35.2023Z"
										fill="#47DAAC"/>
						</svg>
					</div>
					<div className='absolute bottom-[30px] left-[-100px] z-10 md:block hidden'>
						<svg width="151" height="154" viewBox="0 0 151 154" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd"
										d="M15.3382 53.0148C16.2885 52.8396 17.3059 53.5794 17.6352 54.6711C21.7052 67.5066 27.9344 77.006 36.093 82.9599C44.0377 88.7562 53.7923 91.0422 65.0474 89.8061C67.5358 89.559 68.9528 89.7242 73.1366 87.7356C71.4397 81.758 71.6387 75.9384 72.5795 72.159C73.9702 66.578 80.134 63.7634 83.5755 63.2371C87.0169 62.7107 88.8916 63.0704 90.3857 63.7141C92.297 64.5405 94.9268 66.0526 96.5011 70.2459C98.2264 74.8411 97.5371 79.889 94.0282 84.0674C92.1809 86.262 85.5533 90.2813 82.812 91.8671C84.1149 94.5254 86.6929 96.4612 89.3989 98.013C95.1092 101.335 100.561 103.052 109.357 103.145C116.212 103.217 131.366 101.703 137.162 99.9533C142.218 98.4261 143.877 97.7194 146.093 97.3009C149.498 96.6362 148.713 98.9918 147.202 101.744C143.024 107.31 127.155 107.261 119.604 108.866C108.59 110.249 96.5638 109.205 87.2655 104.085C82.9155 101.665 78.2265 98.4352 76.3918 94.3868C71.9103 96.203 69.2684 96.8744 66.6248 97.0695C53.6189 97.8815 42.4972 94.6355 33.6287 87.5865C24.7667 80.5492 18.1003 69.7416 14.2075 55.3177C13.8878 54.2207 14.3878 53.19 15.3382 53.0148ZM84.5006 82.9065C86.636 81.6983 88.4683 80.2104 89.2663 78.8758C90.2781 77.1765 90.7184 74.1377 89.8952 71.9535C89.7542 71.5865 89.5436 69.9132 86.6899 68.8596C81.9948 68.3922 80.2546 72.0353 79.7577 73.0488C77.3876 77.3251 78.8243 81.8489 79.4747 85.4834C81.5111 84.5981 84.3339 83.0607 84.5006 82.9065Z"
										fill="#EB5652"/>
							<path fillRule="evenodd" clipRule="evenodd"
										d="M85.6595 70.0724C85.4562 70.0472 85.2752 69.9942 85.3487 69.8766C85.4445 69.7311 85.5387 69.8324 85.6595 70.0724Z"
										fill="#EB5652"/>
							<path fillRule="evenodd" clipRule="evenodd"
										d="M17.2472 59.0573C17.1835 61.2584 17.043 63.5484 16.7366 65.6081C16.247 68.814 15.4153 71.4729 13.914 72.7685C13.2864 73.5015 12.1408 73.4166 11.3745 72.5833C10.5984 71.7553 10.4841 70.4827 11.1182 69.7614C11.3737 69.3582 11.4412 68.7985 11.5438 68.1885C11.7961 66.7579 11.6689 64.9855 11.5247 63.0689C11.3385 60.6228 10.9449 58.0308 10.6691 55.6959C10.414 53.5799 10.2667 51.6804 10.274 50.2633C10.2826 48.0314 10.9156 46.6042 11.4918 45.9152C11.6455 45.7375 14.9492 43.8236 17.3573 47.246C18.2164 48.4731 20.2652 51.6806 20.3662 51.8395C21.5464 53.8711 23.7597 57.6016 26.1998 60.4686C26.9699 61.3766 27.7235 62.2324 28.4981 62.8308C28.5752 62.7879 28.6523 62.745 28.736 62.7138C29.9842 62.1887 31.5404 62.9668 32.2399 64.4517C32.6543 65.2196 32.938 66.7514 32.8555 67.4885C32.6903 68.9166 31.8072 69.5306 30.3687 69.5318C28.4109 69.53 26.1618 68.3231 23.889 66.4382C21.506 64.4457 19.1141 61.5519 17.2472 59.0573ZM17.2022 51.848C17.1928 51.3771 17.2554 51.0811 17.5206 51.1948C17.8182 51.3211 17.4203 51.6499 17.2022 51.848Z"
										fill="#EB5652"/>
						</svg>
					</div>
					<div className='absolute bottom-[0px] right-[-80px] z-10 md:block hidden'>
						<svg width="147" height="149" viewBox="0 0 147 149" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd"
										d="M132.29 58.7432C131.429 58.4736 130.377 59.0623 129.917 60.059C124.326 71.7556 117.189 80.0607 108.751 84.8012C100.534 89.4161 91.1228 90.5128 80.8064 88.1139C78.5219 87.6082 77.1781 87.6097 73.5576 85.2716C75.9767 79.8026 76.6067 74.2761 76.2595 70.5983C75.7454 65.1673 70.3953 61.8315 67.2616 60.9576C64.128 60.0838 62.3305 60.2192 60.8478 60.6648C58.9506 61.2377 56.2879 62.3807 54.233 66.1752C51.9811 70.3334 51.9162 75.1836 54.6009 79.5193C56.015 81.7971 61.6287 86.323 63.9613 88.1226C62.3744 90.4948 59.7006 92.0442 56.9611 93.2165C51.1738 95.7348 45.8522 96.7632 37.6415 95.8902C31.2423 95.2098 17.3311 92.1223 12.1752 89.8337C7.6762 87.8366 6.22987 86.987 4.22285 86.349C1.14257 85.3483 1.54386 87.6621 2.56665 90.4304C5.6806 96.1516 20.4767 97.8395 27.2897 100.182C37.3603 102.694 48.7153 103.02 58.0987 99.1928C62.4918 97.3795 67.3145 94.8365 69.5917 91.2074C73.5139 93.4151 75.8821 94.3387 78.3185 94.8121C90.326 97.0011 101.146 95.1456 110.399 89.4468C119.645 83.7583 127.372 74.2636 133.021 61.0451C133.473 60.0423 133.151 59.0128 132.29 58.7432ZM63.6432 79.4622C61.8224 78.086 60.3231 76.4784 59.7664 75.1288C59.0616 73.4109 59.077 70.4883 60.1503 68.5122C60.3331 68.1804 60.7639 66.6207 63.5712 65.9358C68.0124 66.0066 69.1238 69.6428 69.4448 70.6558C71.0545 74.9597 69.0816 79.0819 67.9661 82.4488C66.1923 81.3888 63.777 79.6262 63.6432 79.4622Z"
										fill="#50D3FF"/>
							<path fillRule="evenodd" clipRule="evenodd"
										d="M64.3615 67.1955C64.5545 67.194 64.7306 67.1636 64.6786 67.0443C64.6097 66.8963 64.5077 66.9817 64.3615 67.1955Z"
										fill="#47DAAC"/>
							<path fillRule="evenodd" clipRule="evenodd"
										d="M129.664 64.2505C129.415 66.3395 129.225 68.521 129.222 70.5027C129.229 73.5887 129.632 76.1946 130.85 77.5843C131.332 78.3462 132.411 78.391 133.242 77.6865C134.082 76.9881 134.367 75.7967 133.877 75.0452C133.695 74.6359 133.711 74.0991 133.7 73.5108C133.666 72.1301 134.033 70.4675 134.436 68.6703C134.952 66.3768 135.682 63.968 136.266 61.7895C136.8 59.8158 137.204 58.0352 137.396 56.6939C137.7 54.5817 137.31 53.1626 136.87 52.4479C136.752 52.2631 133.941 50.0917 131.217 53.0659C130.244 54.1329 127.885 56.943 127.769 57.0823C126.384 58.8751 123.799 62.1619 121.123 64.6073C120.278 65.3821 119.456 66.1093 118.65 66.5906C118.584 66.5416 118.518 66.4927 118.445 66.454C117.355 65.821 115.796 66.3869 114.936 67.7151C114.442 68.3962 113.963 69.8142 113.936 70.5204C113.89 71.8894 114.627 72.5666 115.968 72.7249C117.793 72.9371 120.058 72.0412 122.44 70.5065C124.94 68.8821 127.575 66.4062 129.664 64.2505ZM130.717 57.436C130.791 56.9915 130.774 56.7047 130.511 56.7833C130.216 56.8703 130.541 57.2248 130.717 57.436Z"
										fill="#50D3FF"/>
						</svg>
					</div>
					{/*Карточки*/}
					<div
						className={`select-none absolute bg-[#FFF] p-4 top-[-100px] left-[580px] w-[30em] rounded-2xl ring-1 ring-[#CACACA] z-10`}
						onMouseEnter={() => setHoveredCard(2)}>
						<div className='flex items-center my-2 justify-between gap-10'>
							<div className='flex items-center gap-4'>
								<div
									className='relative grid place-items-center size-[76px] p-[15px] rounded-[11px] bg-[#CBFFEF]'>
									<img src={FamilyIcon} className='pointer-events-none'/>
								</div>
								<div className="pl-2 flex flex-col text-left">
									<span className='text-[24px] font-bold'>{t('familyCard.title')}</span>
									<span
										className={`text-[#848484] text-[16px] font-[500]`}>{t('familyCard.fitsTo')}</span>
								</div>
							</div>
							<div
								className={`${hoveredCard === 2 ? 'rotate-180' : ''} p-1 transition-all rounded-full ring-[#AAAAAA] ring-1 `}>
								<ChevronUpIcon
									className={`size-6 text-[#AAAAAA]`}/>
							</div>
						</div>
						<div
							className={`${hoveredCard === 2 ? 'flex' : 'hidden'}  flex-col gap-2`}>
							<div className='text-left leading-[1.2] text-[16px] text-black'>
								<span>{t('familyCard.does')}</span>
							</div>
							<div className='flex justify-between items-center w-full gap-2'>
								<div
									className="flex flex-col text-[14px] font-[500] text-[#727272] text-left leading-[16.8px]">
									<div>{t('familyCard.pricing')}</div>
									<div>{t('familyCard.duration')}</div>
								</div>
								<button
									className='flex justify-start gap-2.5 items-center flex-row transition-colors duration-300 ease-out px-[15px] py-[8px] text-white hover:text-[#474BB1] rounded-lg font-[500] hover:bg-white bg-[#474BB1] text-[16px]'
									onClick={showModal}>
									<span>{t('leaveRequestButton')}</span>
									<ChevronUpIcon className="size-5 rotate-90"/>
								</button>
							</div>
						</div>
					</div>
					<div
						className={`select-none absolute bg-[#FFF] p-4 top-[-120px] left-[-500px] w-[29em] rounded-2xl ring-1 ring-[#CACACA] z-10`}
						onMouseEnter={() => setHoveredCard(1)}>
						<div className='flex items-center my-2 justify-between gap-10'>
							<div className='flex items-center gap-4'>
								<div
									className='relative grid place-items-center size-[76px] p-[15px] rounded-[11px] bg-[#FFF6D4]'>
									<img src={PopularManIcon} className='pointer-events-none'/>
								</div>
								<div className="pl-2 flex flex-col text-left">
									<span className='text-[24px] font-bold'>{t('specialistCard.title')}</span>
									<span
										className={`text-[#848484] text-[16px] font-[500]`}>{t('specialistCard.fitsTo')}</span>
								</div>
							</div>
							<div
								className={`${hoveredCard === 1 ? 'rotate-180' : ''} p-1 transition-all rounded-full ring-[#AAAAAA] ring-1 `}>
								<ChevronUpIcon
									className={`size-6 text-[#AAAAAA]`}/>
							</div>
						</div>
						<div
							className={`${hoveredCard === 1 ? 'flex' : 'hidden'}  flex-col gap-2`}>
							<div className='text-left leading-[1.2] text-[16px] text-black'>
								<span>{t('specialistCard.does')}</span>
							</div>
							<div className='flex justify-between items-center w-full gap-2'>
								<div
									className="flex flex-col text-[14px] font-[500] text-[#727272] text-left leading-[16.8px]">
									<div>{t('specialistCard.pricing')}</div>
									<div>{t('specialistCard.duration')}</div>
								</div>
								<button
									className='flex justify-start gap-2.5 items-center flex-row transition-colors duration-300 ease-out px-[15px] py-[8px] text-white hover:text-[#474BB1] rounded-lg font-[500] hover:bg-white bg-[#474BB1] text-[16px]'
									onClick={showModal}>
									<span>{t('leaveRequestButton')}</span>
									<ChevronUpIcon className="size-5 rotate-90"/>
								</button>
							</div>
						</div>
					</div>
					<div
						className={`select-none absolute bg-[#FFF] p-4 top-[145px] left-[-510px] w-[29em] rounded-2xl ring-1 ring-[#CACACA] z-10`}
						onMouseEnter={() => setHoveredCard(3)}>
						<div className='flex items-center my-2 justify-between gap-10'>
							<div className='flex items-center gap-4'>
								<div
									className='relative grid place-items-center min-h-[76px] min-w-[76px] size-[76px] p-[15px] rounded-[11px] bg-[#FFD8D7]'>
									<img src={BusinessIcon} className='pointer-events-none'/>
								</div>
								<div className="pl-2 flex flex-col text-left">
                                    <span
																			className='text-[24px] font-bold leading-[29.05px]'>{t('businessCard.title')}</span>
									<span
										className={`text-[#848484] text-[16px] font-[500]`}>{t('businessCard.fitsTo')}</span>
								</div>
							</div>
							<div
								className={`${hoveredCard === 3 ? 'rotate-180' : ''} p-1 transition-all rounded-full ring-[#AAAAAA] ring-1 `}>
								<ChevronUpIcon
									className={`size-6 text-[#AAAAAA]`}/>
							</div>
						</div>
						<div
							className={`${hoveredCard === 3 ? 'flex' : 'hidden'}  flex-col gap-2`}>
							<div className='text-left leading-[1.2] text-[16px] text-black'>
								<span>{t('businessCard.does')}</span>
							</div>
							<div className='flex justify-between items-center w-full gap-2'>
								<div
									className="flex flex-col text-[14px] font-[500] text-[#727272] text-left leading-[16.8px]">
									<div>{t('businessCard.pricing')}</div>
									<div>{t('businessCard.duration')}</div>
								</div>
								<button
									className='flex justify-start gap-2.5 items-center flex-row transition-colors duration-300 ease-out px-[15px] py-[8px] text-white hover:text-[#474BB1] rounded-lg font-[500] hover:bg-white bg-[#474BB1] text-[16px]'
									onClick={showModal}>
									<span>{t('leaveRequestButton')}</span>
									<ChevronUpIcon className="size-5 rotate-90"/>
								</button>
							</div>
						</div>
					</div>
					<div
						className={`select-none absolute bg-[#FFF] p-4 z-[10] top-[180px] left-[620px] w-[27em] rounded-2xl ring-1 ring-[#CACACA]`}
						onMouseEnter={() => setHoveredCard(4)}>
						<div className='flex items-center my-2 justify-between gap-10'>
							<div className='flex items-center gap-4'>
								<div
									className='relative grid place-items-center min-h-[76px] min-w-[76px] size-[76px] p-[15px] rounded-[11px] bg-[#CFF3FF]'>
									<img src={GraduationHatIcon} className='pointer-events-none'/>
								</div>
								<div className="pl-2 flex flex-col text-left">
									<span className='text-[24px] font-bold'>{t('schoolCard.title')}</span>
									<span
										className={`text-[#848484] text-[16px] font-[500]`}>{t('schoolCard.fitsTo')}</span>
								</div>
							</div>
							<div
								className={`${hoveredCard === 4 ? 'rotate-180' : ''} p-1 transition-all rounded-full ring-[#AAAAAA] ring-1 `}>
								<ChevronUpIcon
									className={`size-6 text-[#AAAAAA]`}/>
							</div>
						</div>
						<div
							className={`${hoveredCard === 4 ? 'flex' : 'hidden'}  flex-col gap-2`}>
							<div className='text-left leading-[1.2] text-[16px] text-black'>
								<span>{t('schoolCard.does')}</span>
							</div>
							<div className='flex justify-between items-center w-full gap-2'>
								<div
									className="flex flex-col text-[14px] font-[500] text-[#727272] text-left leading-[16.8px]">
									<div>{t('schoolCard.pricing')}</div>
									<div>{t('schoolCard.duration')}</div>
								</div>
								<button
									className='flex justify-start gap-2.5 items-center flex-row transition-colors duration-300 ease-out px-[15px] py-[8px] text-white hover:text-[#474BB1] rounded-lg font-[500] hover:bg-white bg-[#474BB1] text-[16px]'
									onClick={showModal}>
									<span>{t('leaveRequestButton')}</span>
									<ChevronUpIcon className="size-5 rotate-90"/>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Картинки людей */}
			<img src={helpWomanImage}
					 className={`hidden brightness-110 pointer-events-none select-none bottom-0 absolute left-1/2 -translate-x-1/2 object-cover h-[610px]
					 ${(hoveredCard === 1 || hoveredCard == null) && 'xl:block'}`}
					 alt={"BusinessWoman"}/>
			<img src={helpFamilyImage}
					 className={`hidden pointer-events-none select-none bottom-0 absolute left-1/2 -translate-x-1/2 object-cover h-[35em]
					 ${hoveredCard === 2 && 'xl:block'}`}
					 alt={"Family"}/>
			<img src={helpBusinessGuyImage}
					 className={`hidden pointer-events-none select-none bottom-0 absolute left-1/2 -translate-x-1/2 object-cover h-[36em]
					 ${hoveredCard === 3 && 'xl:block'}`}
					 alt={"Business"}/>
			<img src={helpSchoolboyImage}
					 className={`hidden pointer-events-none select-none bottom-[-3em] absolute left-1/2 -translate-x-1/2 translate-y-[8em] object-cover h-[50em]
					 ${hoveredCard === 4 && 'xl:block'}`}
					 alt={"Kid"}/>

			{/* mobile */}
			<div className={`xl:hidden`}>
				<Container>
					<div
						className="flex flex-col gap-4 md:gap-8 pb-14">
						<div className="flex flex-col gap-2 select-none text-start md:text-center">
                            <span
															className='font-bold text-[18px] md:text-[48px]'>{t('title.0')}&nbsp;{t('title.1')}</span>
							<p className='text-[12px] md:text-[20px] leading-[14.1px] md:leading-[23.5px] text-[#515151]'>
								{t('description.0')}{t('description.1')}
							</p>
						</div>
						<div className='grid grid-cols-1 xs:grid-cols-2 md:gap-4 gap-2'>
							<HelpBlockCardMobile icon={BusinessIcon} title={t('businessCard.title')}
																	 iconBgColor={'#FFD8D7'}
																	 content={t('businessCard.does')}/>

							<HelpBlockCardMobile icon={FamilyIcon} title={t('familyCard.title')} iconBgColor={'#CBFFEF'}
																	 content={t('familyCard.does')}/>

							<HelpBlockCardMobile icon={GraduationHatIcon} title={t('schoolCard.title')}
																	 iconBgColor={'#CFF3FF'}
																	 content={t('schoolCard.does')}/>

							<HelpBlockCardMobile icon={PopularManIcon} title={t('specialistCard.title')}
																	 iconBgColor={'#FFF6D4'}
																	 content={t('specialistCard.does')}/>

						</div>
						<button
							className='w-full px-[35px] py-[13px] bg-[#393DA1] text-white font-bold rounded-[10px] text-[14px] md:text-[20px] transition-colors hover:bg-[#474BB1]/80'
							onClick={showModal}>
							{t('leaveRequestButton')}
						</button>
					</div>
				</Container>
			</div>
		</div>
	)
		;
}

export default HelpBlock;
