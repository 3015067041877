import React, { useState, useEffect } from 'react';
import '../../../assets/css/main.css';
import testRes1Image from '../../../assets/images/test_res_1.png';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    ArcElement,
} from 'chart.js';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchGallupById, fetchGallupProfReports } from '../../../actions/gallupActions';
import { Radar } from 'react-chartjs-2';
import { useTranslation } from "react-i18next";
import { RU_EN_MAPPING } from './utils';
import Loader from "../layout/Loader";

const GarnerMIT = () => {
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'dashboard.garnerMIT' });
    const [activeLink, setActiveLink] = useState(1);

    const handleLinkClick = (index) => {
        setActiveLink(index);
    };

    const { user_id } = useParams();
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const gallupObjectState = useSelector(state => state.gallup.gallupObject);
    const loading = useSelector(state => state.profile.loading);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [mitLabels, setMitLabels] = useState([]);
    const [mitValues, setMitValues] = useState([]);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/login');
        } else {
            dispatch(fetchGallupById(user_id));
            dispatch(fetchGallupProfReports(user_id));
        }
    }, [dispatch]);

    const data = {
        labels: mitLabels,
        datasets: [
            {
                label: false,
                data: mitValues,
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
                pointRadius: 4,
                pointHoverRadius: 6,
            },
        ],
    };

    useEffect(() => {
        if (gallupObjectState.hasOwnProperty('MIT')) {
            let mitLabels = [];
            let mitValues = [];

            try {
                let mitRadarData = JSON.parse(gallupObjectState.MIT);
                Object.keys(mitRadarData).forEach(item => {
                    if (i18n.resolvedLanguage === 'ru') {
                        const translatedLabel = RU_EN_MAPPING[item] || item;
                        mitLabels.push(translatedLabel);
                    } else {
                        mitLabels.push(item);
                    }
                });
                Object.values(mitRadarData).forEach(item => {
                    mitValues.push(parseInt(item));
                });
            } catch (exc) {
                let mitRadarData = JSON.parse(
                    "{\"Linguistic\":\"8\",\"Logical–mathematical\":\"8\",\"Musical\":\"8\",\"Bodily–kinesthetic\":\"8\",\"Spatial\":\"8\",\"Interpersonal\":\"8\",\"Intra–personal\":\"8\",\"Naturalistic\":\"8\",\"Existential\":\"8\"}"
                );
                Object.keys(mitRadarData).forEach(item => {
                    mitLabels.push(item);
                });
                Object.values(mitRadarData).forEach(item => {
                    mitValues.push(parseInt(item));
                });
            }

            setMitLabels(mitLabels);
            setMitValues(mitValues);
        }
    }, [gallupObjectState]);

    const options = {
        plugins: {
            legend: {
                display: false,
                responsive: true,
            },
        },
        scales: {
            r: {
                pointLabels: {
                    font: {
                        size: 14,
                    },
                },
                ticks: {
                    backdropColor: 'transparent',
                },
                min: 0,   // Установите минимальное значение
				max: 100, // Установите максимальное значение
            },
        },
    };

    if (loading) {
        return <Loader />;
    }

    const mitDescriptions = [
        "интеллект или 'словесные способности', это умение эффективно использовать слова для выражения идей и понимания сложной лексики. Он характерен для писателей, ораторов и преподавателей языков. Этот вид интеллекта связан с решением академических задач и абстрактным мышлением. Люди с высоким лингвистическим интеллектом любят кроссворды, чтение и всевозможные словесные игры.",
        "интеллект или 'числовые способности', это способность работать с числами и научными идеями. Он включает в себя умение рассчитывать, квантифицировать и мыслить логически. Такой тип интеллекта часто встречается у математиков, детективов и ученых. Люди с высоким уровнем такого интеллекта увлекаются логическими играми и экспериментами.",
        "интеллект или 'музыкальные способности', это способность распознавать и ценить элементы музыки. Люди с высоким уровнем этого интеллекта обладают отличным слухом и способностью создавать музыкальные композиции. Они часто поют или играют на музыкальных инструментах и лучше всего учатся с помощью музыкальных ритмов.",
        "интеллект или 'физические способности', это умение использовать тело для решения задач и обработки информации через движение. Этот вид интеллекта ассоциируется с ловкостью и координацией. Танцоры, спортсмены и хирурги обладают высоким кинестетическим интеллектом. Люди с таким интеллектом лучше учатся через физическое взаимодействие.",
        "интеллект или 'визуальные способности', это способность мыслить визуально и пространственно. Люди с высоким уровнем этого интеллекта часто занимаются искусством, дизайном и архитектурой. Они умеют работать с цветом, интерпретировать визуальные изображения и видеть узоры в окружающем мире.",
        "интеллект или 'социальные способности', это умение эффективно взаимодействовать с другими людьми. Люди с высоким уровнем этого интеллекта обладают развитыми коммуникативными навыками и эмпатией. Они успешны в решении конфликтов и создании позитивных отношений.",
        "интеллект или 'самопознание', это способность понимать себя и использовать это понимание в принятии решений. Люди с высоким уровнем этого интеллекта осознают свои мысли, чувства и мотивации. Они склонны к глубокому самоанализу и креативному самовыражению.",
        "интеллект или 'экологические способности', это способность чувствовать связь с природой и понимать ее законы. Люди с высоким уровнем этого интеллекта интересуются изучением природы и охраной окружающей среды. Они хорошо замечают закономерности в природе и любят активный отдых на свежем воздухе.",
        "интеллект или 'философские способности', это стремление понять смысл жизни и задавать глубокие вопросы. Люди с высоким уровнем этого интеллекта интересуются философией, духовностью и общественными проблемами. Они учатся, рассматривая различные точки зрения и соединяя идеи."
    ];

    return (
        <>
            <div className='flex justify-center p-4 mb-2'>
							<Radar data={data} options={{...options, responsive: true,}}
										 className={`lg:max-h-[15em]`}/>
            </div>
            {mitLabels.map((label, index) => (
                <div key={index}>
                    <div className='flex justify-between items-center gap-4 p-4 mb-2 rounded-lg border-b border-gray-300'>
                        <span className={`p-3 ${getColorByValue(mitValues[index])} text-white font-[700] rounded-lg`}>
                            {mitValues[index]}%
                        </span>
                        <div className='text-[16px] text-justify'>
                            <span className='text-[#393DA1] font-[700]'>{label}&nbsp;</span>
                            {mitDescriptions[index]}
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

const getColorByValue = (value) => {
    if (value >= 90) return 'bg-[#FF6384]'; // High
    if (value >= 70) return 'bg-[#FFCE56]'; // Medium
    if (value >= 50) return 'bg-[#4BC0C0]'; // Low
    return 'bg-[#36A2EB]'; // Very Low
};

export default GarnerMIT;