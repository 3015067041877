import React from 'react';
import {useLocation} from "react-router-dom";

function Container({children}) {
	const locationPath = useLocation().pathname;
	const landingURLs = ['/', '/login', '/news', '/faq']
	const shouldApplyClass = landingURLs.includes(locationPath);
	return (
		<div
			className={`mx-auto relative  px-4
			${shouldApplyClass ?
				'container qhd:px-[650px] fhd:px-[350px] 2xl:px-[200px] md:px-[60px]' : 'container-dashboard'}`}>
			{children}
		</div>
	);
}

export default Container;