import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useSelector, useDispatch } from 'react-redux'
import { Circles } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Loader from "../layout/Loader";
import { fetchGallupProfReports, downloadFile, chooseProfFields, fetchGallupById_new, } from '../../../actions/gallupActions';
import { setupAxiosInterceptors } from "../../../interceptor/Interceptor";


const SpheresList = () => {
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'dashboard.spheresList' })
	const { gallupId } = useParams();

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
	const [loading, setLoading] = useState(true);

	const gallupProfReports = useSelector(state => state.gallup.profReports);
	const gallupObjectState = useSelector(state => state.gallup.gallupObject);

	const [activeSwitch, setActiveSwitch] = useState(t('all'));
	const [showRegenerateButton, setShowRegenerateButton] = useState(false); // State to control button visibility

	ChartJS.register(
		CategoryScale,
		LinearScale,
		BarElement,
		Title,
		Tooltip,
		Legend
	);

	const fields = [
        "ИТ",
        "Естественные науки, математика и статистика",
        "Инженерия",
        "Гуманитарные науки",
        "Искусство",
        "Образование",
        "Здравоохранение",
        "Сельское хозяйство и биоресурсы",
        "Социальные науки",
        "Национальная оборона и безопасность",
        "Бизнес и управление",
        "Услуги"
    ];

	const options = {
		responsive: true,
		aspectRatio: false,
		plugins: {
			legend: {
				position: 'bottom',
			},
			title: {
				display: true,
				text: 'Распределение сфер деятельности в заданном интервале',
			},
		},
	};

	const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

	const data = {
		labels,
		datasets: [
			{
				label: 'Социальные науки',
				data: [20, 40, 50],
				backgroundColor: 'rgba(255, 99, 132, 0.5)',
			},
			{
				label: 'Гуманитарные науки',
				data: [20, 40, 60],
				backgroundColor: 'rgba(53, 162, 235, 0.5)',
			},
		],
	};

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/login');
        } else {
			setLoading(true);
            dispatch(fetchGallupProfReports(gallupId)).then(() => {
				setLoading(false);
                setShowRegenerateButton(true); // Show button after receiving response
            });
			setupAxiosInterceptors(navigate);
        }
    }, [dispatch, isLoggedIn, gallupId, navigate]);

    const regenerateReport = () => {
        setShowRegenerateButton(false); // Hide button during regeneration
        dispatch(fetchGallupProfReports(gallupId, true)).then(() => {
            setShowRegenerateButton(true); // Show button after receiving response
        });
    };

	const [uniqueSpheres, setUniqueSpheres] = useState([]);
	const [uniqueFields, setUniqueFields] = useState([]);
	const [selectedSphere, setSelectedSphere] = useState('Все');
	const [selectedField, setSelectedField] = useState('');
	const [profReportsList, setProfReportsList] = useState([]);
	const [selectedCheckboxesList, setSelectedCheckboxesList] = useState([]);
	const { pdfId } = useParams();
    const [showPopup, setShowPopup] = useState(false);
    const [Prof_fields, setProf_fields] = useState([]);


	const [range, setRange] = useState({ min: 0, max: 100 });

	const handleFieldToggle = (field) => {
        const updatedFields = [...Prof_fields];
        const index = updatedFields.indexOf(field);
        if (index > -1) {
            updatedFields.splice(index, 1);
        } else {
            if (updatedFields.length < 4) {
                updatedFields.push(field);
            }
        }
        setProf_fields(updatedFields);
    };

	const handleSubmitFields = async () => {
        if (Prof_fields.length < 1) {
            alert('Please select at least one field.');
            return;
        }
        dispatch(chooseProfFields(gallupId, Prof_fields))
            .then(() => {
                setShowPopup(false);
            })
            .catch((error) => {
                console.error('Error submitting fields:', error);
            });
    };

	useEffect(() => {
		if (gallupProfReports.length > 0) {
			setProfReportsList(gallupProfReports)
			let domains = new Set()
			let fields = new Set()

			gallupProfReports.forEach(profReport => {
				if (profReport.Domains !== '') domains.add(profReport.Domains.trim().split(' ')[0])
			})
			setUniqueSpheres(Array.from(domains))

			gallupProfReports.forEach(profReport => {
				if (profReport.Field !== '') fields.add(profReport.Field.trim())
			})
			setUniqueFields(Array.from(fields))
		}
	}, [gallupProfReports])

	const toggleSwitch = (value) => {
		setActiveSwitch(value);
		setSelectedSphere(value);
	
		let filteredList = gallupProfReports;
	
		if (value !== 'Все') {
			filteredList = filteredList.filter(profReport => profReport.Domains.trim().split(' ')[0] === value.trim());
		}
		setProfReportsList(filteredList);
	};

	const handleCheckboxClick = (event) => {
		const { id, checked } = event.target;
	
		setSelectedField(id);
	
		let updatedCheckboxesList;
	
		if (checked) {
			updatedCheckboxesList = [...selectedCheckboxesList, id];
		} else {
			updatedCheckboxesList = selectedCheckboxesList.filter(item => item !== id);
		}
	
		setSelectedCheckboxesList(updatedCheckboxesList);
	
		let filteredFieldsList = gallupProfReports; // Start with the full list of reports
	
		if (updatedCheckboxesList.length > 0) {
			filteredFieldsList = gallupProfReports.filter(profReport =>
				updatedCheckboxesList.includes(profReport.Field.trim())
			);
		}
	
		setProfReportsList(filteredFieldsList);
	};

	const handleFieldsClick = async () => {
		if (Prof_fields.length === 0) { // Check if Prof_fields are empty
			dispatch(fetchGallupById_new(gallupId))
				.then((response) => {
					if (response.Prof_fields) {
						setProf_fields(response.Prof_fields); // Set the Prof_fields from the response
					}
					setShowPopup(true); // Show the popup regardless
				})
				.catch((error) => {
					console.error(error);
				});
		} else {
			setShowPopup(true); // Show the popup if fields are already selected
		}
	};
	
	const fetchPdfData = async () => {
		
		try {
			const data = await dispatch(fetchGallupById_new(gallupId));
	
			if (data.Prof_fields) {
				window.location.href = `/reports/5/${gallupId}`;
			} else {
				setShowPopup(true);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleNextClick = () => {
        fetchPdfData();
    };

	const calculateChartData = () => {
        if (!gallupProfReports) {
            return {
                labels: [],
                datasets: [],
            };
        }

		let filteredFieldsList = gallupProfReports;

        const filteredData = filteredFieldsList.filter(data => {
            const place = Number(data.Place);
            return place >= range.min && place <= range.max;
        });

        const fieldCounts = filteredData.reduce((counts, data) => {
            counts[data.Field] = (counts[data.Field] || 0) + 1;
            return counts;
        }, {});

        return {
            labels: Object.keys(fieldCounts),
            datasets: [{
                label: 'Number of Fields',
                data: Object.values(fieldCounts),
                backgroundColor: 'rgba(219, 112, 147, 0.8)', // Change to any color you like
            }],
        };
    };

	const chartData = calculateChartData();

	const handleRangeChange = (boundary, value) => {
        setRange(prevRange => ({
            ...prevRange,
            [boundary]: Number(value),
        }));
    };

	const searchInput = useRef(null)

	const searchProfessions = (enteredName) => {
		if (enteredName !== '') {
			let filteredList = profReportsList.filter(profReport => profReport
				.Professions.toLowerCase()
				.includes(enteredName.toLowerCase()));
			setProfReportsList(filteredList);
		} else {
			setProfReportsList(gallupProfReports);
		}
	}

	return (
		<div className={`bg-[#EEF1F5]`}>
		{loading ? (
            <div className="flex justify-center items-center min-h-screen">
                <Circles color="#00BFFF" height={80} width={80} />
            </div>
        ) : (
			<div className="container-dashboard mx-auto flex flex-col">
				<div className="bg-white p-3 text-center col-span-12 rounded-lg">
					<p className="text-2xl font-bold">{t('bestCareerWays')}</p>
				</div>

				<div className='flex justify-center gap-4 mt-3 mb-1'>
					<button
						className='bg-[#393DA1] p-3 rounded-[10px] text-white'
						onClick={() => {
							navigate(`/spheres_new/${gallupId}`);
						}}
					>
						Назад
					</button>
                    {showRegenerateButton && (
                        <button
                            className='bg-green-800 p-3 rounded-[10px] text-white'
                            onClick={regenerateReport}
                        >
                            Сгенерировать
                        </button>
                    )}
					<button
						className='bg-green-800 p-3 rounded-[10px] text-white shadow-md'
						onClick={handleFieldsClick}
					>
                        Выбор сфер
                    </button>
                    <button
                        className='bg-green-800 p-3 rounded-[10px] text-white'
                        onClick={() => {
							dispatch(downloadFile(gallupId));
                        }}
                    >
                        Загрузить
                    </button>
                    <button
                        className='bg-[#393DA1] p-3 rounded-[10px] text-white'
						onClick={handleNextClick}
                    >
                        Вперед
                    </button>
                </div>

				{showPopup && (
				<div className="modal fixed inset-0 flex items-center justify-center z-50">
					<div className="modal-overlay absolute inset-0 bg-gray-500 opacity-75"></div>
					<div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
						<div className="modal-content py-4 text-left px-6">
							<div className="modal-header flex justify-between items-center pb-3">
								<p className="text-2xl font-bold">Выберите область</p>
								<div className="modal-close cursor-pointer z-50" onClick={() => setShowPopup(false)}>
									<span>&times;</span>
								</div>
							</div>
							<div className="modal-body">
								{fields.map((field) => (
									<div className="form-check" key={field}>
										<input
											className="form-check-input"
											type="checkbox"
											value={field}
											id={`field-${field}`}
											checked={Prof_fields.includes(field)}
											onChange={() => handleFieldToggle(field)}
										/>
										<label className="form-check-label" htmlFor={`field-${field}`}>
											{field}
										</label>
									</div>
								))}
							</div>
							<div className="modal-footer flex justify-end pt-2">
								<button type="button" className="btn btn-secondary mr-2" onClick={() => setShowPopup(false)}>Отмена</button>
								<button type="button" className="btn btn-primary" onClick={handleSubmitFields}>Submit</button>
							</div>
						</div>
					</div>
				</div>
			)}

				<div className="flex flex-col md:flex-row justify-between items-stretch md:items-center mt-2">
					<div className="flex flex-row justify-center items-center gap-1 md:bg-white bg-[#F9F9F9] text-black p-2 rounded-[15px]
								 *:rounded-[11px] *:cursor-pointer *:px-[13px] *:py-[6px] *:transition-colors duration-[400ms]
								 md:text-[16px] md:leading-[20.8px] text-[12px] leading-[15.6px]" style={{ minWidth: '45%' }}>
						<span className={`${activeSwitch === t('all') && 'bg-[#E3F0FF] font-bold'}`}
							onClick={() => toggleSwitch(t('all'))}>{t('all')}</span>
						{uniqueSpheres.map(sphere => (
							<span key={sphere} className={`${activeSwitch === sphere && 'bg-[#E3F0FF] font-bold'}`} onClick={() => toggleSwitch(sphere)}>{sphere}</span>
						))}
					</div>
					<div className='md:w-80 w-full' style={{ minWidth: '53%' }}>
						<input
							ref={searchInput}
							onChange={e => searchProfessions(e.target.value)}
							aria-autocomplete="list" type="text" id={``} placeholder={`Поиск по специальности`}
							className={` w-full shadow-none outline-none focus:border-[#4285F4] placeholder:font-light font-light px-2 py-2 rounded-[9px] border border-[#ADADAD]`} />
					</div>
				</div>

				<div className="flex justify-between flex-col xl:flex-row 2xl:flex-row gap-4 *:*:max-h-[30em] my-2">
					<div className="lg:basis-2/5 text-[14px]" style={{ minWidth: '45%' }}>
						<div className="col-span-1 md:col-span-1 pt-3 rounded-tl-[25px] rounded-tr-[25px] bg-[#FFFFFF]">
							<h2 className="text-2xl font-[700]">{t('chooseInteresting')}</h2>
						</div>
						<div className='flex p-4 bg-[#FFFFFF] rounded-bl-[25px] rounded-br-[25px] gap-5'>
							{uniqueFields.length > 6 && (
								<div className='flex flex-col items-start text-left'>
									{uniqueFields.slice(6).map((checkbox, index) => (
										<div key={index} className='my-1'>
											<input type='checkbox' id={checkbox} name='sphere' value={checkbox} onClick={handleCheckboxClick} />
											<label className='inline-block pl-[0.5rem] hover:cursor-pointer' htmlFor={checkbox}>
												{checkbox}
											</label>
										</div>
									))}
								</div>
							)}
							<div className='flex flex-col items-start text-left'>
								{uniqueFields.slice(0, 6).map((checkbox, index) => (
									<div key={index} className='my-1'>
										<input type='checkbox' id={checkbox} name='sphere' value={checkbox} onClick={handleCheckboxClick} />
										<label className='inline-block pl-[0.5rem] hover:cursor-pointer' htmlFor={checkbox}>
											{checkbox}
										</label>
									</div>
								))}
							</div>
						</div>
						<div className="col-span-1 bg-white p-3 relative mt-2 bg-[#FFFFFF] rounded-[25px] flex flex-col" style={{ minHeight: '550px' }}>
							<h2 className="text-2xl font-[700] my-4">{t('spheresDistribution')}</h2>
							<div className='flex justify-between'>
								<div className='flex gap-2 items-center'>
									<label>Min:</label>
									<input aria-autocomplete="list" value={range.min} onChange={e => handleRangeChange('min', e.target.value)} type="number" id={``} placeholder={`+7 ...`}
										className={` shadow-none outline-none focus:border-[#4285F4] placeholder:font-light font-light px-2 py-2 rounded-[9px] border border-[#ADADAD]`} />
								</div>
								<div className='flex gap-2 items-center'>
									<label>Max:</label>
									<input aria-autocomplete="list" value={range.max} onChange={e => handleRangeChange('max', e.target.value)} type="number" id={``} placeholder={`+7 ...`}
										className={` shadow-none outline-none focus:border-[#4285F4] placeholder:font-light font-light px-2 py-2 rounded-[9px] border border-[#ADADAD]`} />
								</div>
							</div>
							<div className='chart-container' style={{ minHeight: '410px' }}>
  								<Bar options={options} data={chartData} />
							</div>
						</div>
					</div>
					<div className="lg:basis-3/5 bg-[#FFFFFF] p-4 rounded-[25px] text-[14px] select-none flex flex-col items-start" style={{ minWidth: '55%' }}>
					<div className="h-full w-full overflow-auto" style={{ minHeight: '800px'}}>
                            <table
                                className='block w-full overflow-auto rounded-[9px] relative table-auto text-left h-full pr-1' style={{ minWidth: '55%' }}>
                                <thead
                                    className={`sticky top-[-1px] after:absolute after:block after:bg-[#C1C1C1] after:w-full after:h-[2px] bottom-0 inset-x-0`}>
                                    <tr
                                        className={`bg-white md:text-[18px] md:leading-[21.15px] md:[&>*:not(:first-child)]:ps-4 [&>*:not(:first-child)]:ps-2`}>
                                        <th>{t('position')}</th>
                                        <th>{t('sphere')}</th>
                                        <th>Подсфера</th>
                                        <th>{t('profession')}</th>
                                        <th>%</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {profReportsList && profReportsList.map((profReport, idx) => {
                                        return (
                                            <tr
                                                key={idx}
                                                className="even:bg-[#F8F8F8] *:py-4 md:*:ps-4 *:ps-2">
                                                <td className="md:text-[18px] md:leading-[23.5px]">{profReport.Place}</td>
                                                <td className="md:text-[14px] md:leading-[18.8px]">{profReport.Field}</td>
                                                <td className="md:text-[14px] md:leading-[18.8px]">{profReport.Subfield}</td>
                                                <td className="font-bold md:text-[14px] md:leading-[18.8px]"><a href={profReport.Links} target="_blank" rel="noopener noreferrer">{profReport.Professions}</a></td>
                                                <td className="font-semibold md:text-[16px] md:leading-[21.15px]">{profReport['Percentage fitting']}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                                <tbody
                                    className={`${gallupProfReports.length < 1 ? 'block' : 'hidden'} size-full grid place-items-center md:text-[20px] md:leading-[23.5px] py-10`}>
                                    <tr>
                                        <td>
                                            <Circles
                                                color="#cdebff"
                                                height={100}
                                                width={100}
                                                timeout={5000}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="lg:hidden">
                    </div>
                </div>

                <div className="flex justify-between flex-col lg:flex-row 2xl:flex-row gap-4 *:*:max-h-[30em] *:shadow-lg my-2">
                </div>
            </div>
		)}
        </div >
    );
}

export default SpheresList;
