import React, {useEffect, useState} from 'react'
import '../../assets/css/news.css'
import Navbar from './Navbar';
import {ChevronRightIcon, ChevronDownIcon} from '@heroicons/react/24/solid'
import news1Image from '../../assets/images/news_1.jpg'
import news2Image from '../../assets/images/news_2.jpg'
import news3Image from '../../assets/images/news_3.jpg'
import Container from "../Container";
import Footer from "../main/Footer";
import NewsArticle from "../NewsPage/NewsArticle";
import NewsTags from "../NewsPage/NewsTags";
import TopPosts from "../NewsPage/TopPosts";

import {news} from "../NewsPage/news"

const News = () => {
	const [currentArticle, setCurrentArticle] = useState(0);
	const [articleHeading, setArticleHeading] = useState(news[currentArticle].heading)
	const [articleHeroProvided, setArticleHeroProvided] = useState(news[currentArticle].heroProvided);
	const [articleHeroImage, setArticleHeroImage] = useState(news[currentArticle].heroImage);
	const [articleHeroDate, setArticleHeroDate] = useState('')
	const [articleTagList, setArticleTagList] = useState(news[currentArticle].tags);
	const [articleFragmentsList, setArticleFragmentsList] = useState(news[0].fragments);
	useEffect(() => {
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
		setArticleHeading(news[currentArticle].heading)
		setArticleHeroProvided(news[currentArticle].heroProvided)
		setArticleHeroImage(news[currentArticle].heroImage)
		setArticleTagList(news[currentArticle].tags)
		setArticleFragmentsList(news[currentArticle].fragments)
	}, [currentArticle]);
	const topPostsList = [0, 1]; // индексы топ постов, добавить нужные при необходимости

	return (
		<div className="bg-[#EAF8FC] ">
			{/*<Navbar/>*/}
			<Container>
				<div className="relative flex xl:flex-row flex-col gap-4 xl:gap-2 pb-20 min-h-screen">
					<NewsArticle heading={articleHeading} heroProvided={articleHeroProvided}
											 heroImage={articleHeroImage}
											 heroDate={articleHeroDate}
											 articleFragmentsList={articleFragmentsList}/>
					<div className="xl:basis-[30%] relative xl:order-none order-first">
						<div className="xl:sticky xl:top-4 flex flex-col gap-4 ">
							<NewsTags tagList={articleTagList}/>
							<div className={`hidden xl:block`}><TopPosts topPostList={topPostsList}
																													 setCurrentArticle={setCurrentArticle}/></div>
						</div>
					</div>
					<div className={`xl:hidden block`}><TopPosts topPostList={topPostsList}
																											 setCurrentArticle={setCurrentArticle}/></div>
				</div>
			</Container>
		</div>
	);
}

export default News;
