import React from 'react';

function ReviewCard({name, image, job, review, isShown, positionLeft, positionTop, inCarousel}) {
    return (
        <div
            style={{
                left: `${positionLeft}px`,
                top: `${positionTop}px`,
            }}
            className={`${(!inCarousel && isShown) ? 'md:flex hidden' : (!inCarousel && !isShown) ? 'md:flex md:invisible hidden' : 'flex'}
            relative md:absolute reviewCard transition-all md:shadow-[0px_2px_25px_0px_rgba(0,0,0,0.1)] shadow-xl
            bg-white p-4 flex-col md:gap-4 gap-2 rounded-[10px] text-left w-full max-w-[25em] xl:max-w-[30em]`}>
            <div className="flex flex-row gap-2">
                <div className="rounded-full size-[47px]">
                    <img src={image} alt="" className={`object-cover size-full`}/>
                </div>
                <div className="flex flex-col tracking-[0.02em]">
                    <span className={`font-[500] text-[14px] md:text-[17px] text-[#353535]`}>{name}</span>
                    <span className={`font-normal text-[12px] md:text-[13px] text-[#8E8E8E]`}>{job}</span>
                </div>
            </div>
            <div
                className={`font-normal text-[12px] md:text-[20px] leading-[15.6px] md:leading-[26px] text-[#585858]`}>{review}</div>
        </div>
    );
}

export default ReviewCard;