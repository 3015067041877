export const RU_EN_MAPPING = {
    "Linguistic": "Лингвистический",
    "Logical–mathematical": "Логико-математический",
    "Musical": "Музыкальный",
    "Bodily–kinesthetic": "Кинестетический",
    "Spatial": "Пространственный",
    "Interpersonal": "Межличностный",
    "Intra–personal": "Внутриличностный",
    "Naturalistic": "Натуралистический",
    "Existential": "Экзистенциальный",
}
