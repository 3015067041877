import axios from "axios";
import {API_URL} from "../services/constants";

const axiosInstance = axios.create({
	baseURL: API_URL,
});

const setupAxiosInterceptors = (navigate) => {
	axiosInstance.interceptors.response.use(
		(response) => {
			// If the response is successful, just return the response.
			return response;
		},
		(error) => {
			if (error.response && error.response.status === 401) {
				// Handle the 401 error: remove the token and navigate
				localStorage.removeItem('access_token');
				navigate('/login'); // Redirect to the login page
                window.location.reload();
			}
			// Reject the promise without displaying an error message
			return Promise.reject();
		}
	);
};

export { axiosInstance, setupAxiosInterceptors };
