import React from 'react';
import instaImage from '../../assets/images/insta.png';
import telegramImage from '../../assets/images/telegram.png';
import vkImage from '../../assets/images/vk.png';
import youtubeImage from '../../assets/images/youtube.png';
import Container from "../Container";
import {useTranslation} from "react-i18next";

const Footer = () => {
    const {t, i18n} = useTranslation('translation', {keyPrefix: 'footer'})
    return (
        <div className={`bg-[#393DA1] py-14 `}>
            <Container>
                <div className="grid xl:grid-cols-5 grid-cols-1 gap-y-12 text-white text-left select-none">
                    {/* Левая часть */}
                    <div className={`xl:col-span-3 flex flex-col gap-4`}>
                        <span className="text-[20px] md:text-[60px] font-bold">TANU PRO</span>
                        <span className="text-[#D2D2D2] font-[500] text-[12px] md:text-[24px] leading-[28.2px]">
                            {t('description')}</span>
                        <div className="flex items-center gap-4">
                            <a href="#"><img className="size-[30px] md:size-[40px]" src={instaImage}
                                             alt="Instagram"/></a>
                            <a href="#"><img className="size-[30px] md:size-[40px]" src={telegramImage} alt="Telegram"/></a>
                            <a href="#"><img className="size-[30px] md:size-[40px]" src={vkImage} alt="VK"/></a>
                        </div>
                    </div>
                    {/* Правая часть */}
                    <div
                        className="flex sm:flex-row w-full justify-start flex-col gap-4 gap-y-12 xl:col-span-2 text-[20px]">
                        <div className="flex flex-col gap-4 xl:basis-full basis-1/2">
                            <span className="font-bold">{t('linkSections.0')}</span>
                            <div
                                className="flex flex-col gap-4 md:text-[20px] text-[14px]">
                                <a href={`#`}
                                   className={`block w-fit relative after:duration-300 after:block hover:after:w-full after:w-0 after:h-[1px] after:absolute after:bottom-0 after:bg-white`}>Создатели</a>
                                <a href={`#`}
                                   className={`block w-fit relative after:duration-300 after:block hover:after:w-full after:w-0 after:h-[1px] after:absolute after:bottom-0 after:bg-white`}>Почта</a>
                                <a href={`#`}
                                   className={`block w-fit relative after:duration-300 after:block hover:after:w-full after:w-0 after:h-[1px] after:absolute after:bottom-0 after:bg-white`}>Карьера</a>
                                <a href={`#`}
                                   className={`block w-fit relative after:duration-300 after:block hover:after:w-full after:w-0 after:h-[1px] after:absolute after:bottom-0 after:bg-white`}>Поддержка</a>
                            </div>
                        </div>
                        <div className="flex flex-col gap-4 xl:basis-full basis-1/2">
                            <span className="font-bold text-[20px]">{t('linkSections.1')}</span>
                            <div
                                className="flex flex-col gap-4 md:text-[20px] text-[14px]">
                                <a href={`#`} style={{transition: 'width 0.3s ease-in'}}
                                   className={`block w-fit relative after:duration-300 after:block hover:after:w-full after:w-0 after:h-[1px] after:absolute after:bottom-0 after:bg-white`}>Блог</a>
                                <a href={`#`} style={{transition: 'width 0.3s ease-in'}}
                                   className={`block w-fit relative after:duration-300 after:block hover:after:w-full after:w-0 after:h-[1px] after:absolute after:bottom-0 after:bg-white`}>Лицензия</a>
                                <a href={`#`} style={{transition: 'width 0.3s ease-in'}}
                                   className={`block w-fit relative after:duration-300 after:block hover:after:w-full after:w-0 after:h-[1px] after:absolute after:bottom-0 after:bg-white`}>Правила</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Footer;
