import React, { useState, useEffect, useRef } from 'react';
import '../../../assets/css/main.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTestResults } from '../../../actions/testResultActions';

const themes_descriptions = {
    "Achiever": "Люди с талантом Achiever (Достижение) усердно работают и обладают большой выносливостью. Они получают огромное удовлетворение от занятости и продуктивности. Они стремятся всегда быть занятыми.",
    "Activator": "Люди с талантом Activator (Катализатор) умеют воплощать мысли в действия. Они стремятся к немедленным действиям, а не к пустым разговорам. Их подход - двигаться вперед и добиваться целей.",
    "Adaptability": "Люди с талантом Adaptability (Приспособляемость) предпочитают идти по течению. Они живут в настоящем и воспринимают вещи по мере их поступления, принимая будущее день за днем.",
    "Analytical": "Люди с талантом Analytical (Аналитик) ищут причины и следствия. Они умеют учитывать все факторы, которые могут повлиять на ситуацию, анализируя каждую деталь для лучшего понимания.",
    "Arranger": "Люди с талантом Arranger (Организатор) умеют организовывать и обладают гибкостью, дополняющей эту способность. Им нравится, когда ресурсы использованы для максимальной продуктивности.",
    "Belief": "Люди с талантом Belief (Убеждение) имеют неизменные ключевые ценности. Эти ценности формируют четкую цель в их жизни, предоставляя им направление и смысл в каждом их действии.",
    "Command": "Люди с талантом Command (Распорядитель) обладают харизмой и способны взять ситуацию под контроль и принимать решения. Они уверены в себе и могут уверенно вести других за собой.",
    "Communication": "Люди с талантом Communication (Коммуникация) легко выражают свои мысли словами. Они хорошие собеседники и ораторы, умеющие ясно излагать свои идеи и привлекать внимание аудитории.",
    "Competition": "Люди с талантом Competition (Конкуренция) оценивают свой прогресс по сравнению с достижениями других. Они стремятся к победе и наслаждаются соревнованиями, всегда стремясь быть первыми.",
    "Connectedness": "Люди с талантом Connectedness (Взаимосвязанность) верят в связи между всеми вещами. Они считают, что каждое событие имеет смысл и значение, объединяя людей и явления в единое целое.",
    "Consistency": "Люди с талантом Consistency (Последовательность) осознают необходимость одинакового отношения к людям. Они стремятся к стабильным рутинам и четким правилам, создавая справедливую среду.",
    "Context": "Люди с талантом Context (Контекст) любят думать о прошлом. Они понимают настоящее, исследуя его историю, анализируя предшествующие события для лучшего осмысления текущих ситуаций.",
    "Deliberative": "Люди с талантом Deliberative (Осмотрительность) тщательно принимают решения и выбор. Они предвидят возможные препятствия и риски, взвешивая все варианты и продумывая каждый шаг.",
    "Developer": "Люди с талантом Developer (Развитие) распознают и развивают потенциал в других. Они замечают каждое улучшение и получают удовлетворение от прогресса, помогая людям расти и достигать целей.",
    "Discipline": "Люди с талантом Discipline (Дисциплинированность) любят рутину и структуру. Создают порядок вокруг, устанавливая правила и процедуры, чтобы организовать эффективную среду.",
    "Empathy": "Люди с талантом Empathy (Эмпатия) чувствуют эмоции других людей, представляя себя в их жизни или ситуациях. Они понимают чувства и мысли других, создавая глубокие и значимые связи.",
    "Focus": "Люди с талантом Focus (Сосредоточенность) умеют выбирать направление, следовать ему и корректировать свои действия по необходимости. Они приоритетизируют задачи, для достижения поставленных целей.",
    "Futuristic": "Люди с талантом Futuristic (Будущее) вдохновлены будущим и тем, что может быть. Они заряжают других своими видениями будущего, мотивируя и вдохновляя на создание новых возможностей.",
    "Harmony": "Люди с талантом Harmony (Гармония) ищут консенсус и избегают конфликтов, предпочитая искать области согласия. Они способствуют мирному взаимодействию и кооперации среди людей.",
    "Ideation": "Люди с талантом Ideation (Генератор идей) увлечены идеями и могут находить связи между, казалось бы, несвязанными явлениями. Они обладают творческим мышлением и генерируют идеи.",
    "Includer": "Люди с талантом Includer (Включенность) принимают других. Они замечают тех, кто чувствует себя оставленным, и стараются включить их, создавая атмосферу принятия и принадлежности для всех.",
    "Individualization": "Люди с талантом Individualization (Индивидуализация) заинтересованы уникальными качествами каждого человека. Они понимают, как разные люди могут эффективно работать вместе.",
    "Input": "Люди с талантом Input (Вклад) склонны к накоплению информации, идей, артефактов или даже отношений. Они любят собирать знания и делиться ими с другими, помогая в решении проблем.",
    "Intellection": "Люди с талантом Intellection (Мышление) характеризуются своей интеллектуальной активностью. Они ценят интеллектуальные обсуждения, наслаждаясь глубокими и содержательными беседами.",
    "Learner": "Люди с талантом Learner (Ученик) имеют сильное желание учиться и стремятся постоянно улучшаться. Их увлекает сам процесс обучения, а не только его результат, что помогает им развиваться.",
    "Maximizer": "Люди с талантом Maximizer (Максимизатор) фокусируются на сильных сторонах, стремясь стимулировать личное и групповое совершенство. Они стремятся превратить что-то хорошее во что-то превосходное.",
    "Positivity": "Люди с талантом Positivity (Позитивность) обладают заразительным энтузиазмом. Они оптимистичны и могут увлечь других своими планами, создавая атмосферу радости и вдохновения вокруг себя.",
    "Relator": "Люди с талантом Relator (Отношения) наслаждаются близкими отношениями с другими. Они получают глубокое удовлетворение от работы с друзьями для достижения общей цели, укрепляя дружеские связи.",
    "Responsibility": "Люди с талантом Responsibility (Ответственность) принимают ответственность за свои слова и действия. Они привержены своим ценностям, и всегда стремятся выполнять обещания.",
    "Restorative": "Люди с талантом Restorative (Восстановление) умеют справляться с проблемами. Они хороши в выявлении и устранении неполадок, быстро находя решения и исправляя ошибки.",
    "Self-Assurance": "Люди с талантом Self-Assurance (Уверенность) уверены в своих способностях рисковать и управлять своей жизнью. У них есть внутренний компас, который дает им уверенность в своих решениях.",
    "Significance": "Люди с талантом Significance (Значимость) стремятся оказать большое влияние. Они независимы и выбирают проекты с учетом их значимости для окружающих, стремясь к достижению значимых целей.",
    "Strategic": "Люди с талантом Strategic (Стратегия) умеют находить альтернативные пути действий. В любой ситуации они быстро замечают основные шаблоны и проблемы, разрабатывая планы для достижения целей.",
    "Woo": "Люди с талантом Woo (Обаяние) любят знакомиться с людьми и завоевывать их доверие. Они получают удовлетворение от установления контактов, находя общий язык с окружающими и привлекая их на свою сторону."
};

const ClifftonStrength = () => {
    const [activeLink, setActiveLink] = useState(1);
    const { user_id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const testResults = useSelector(state => state.testResult.testResults);

    const [gallupArray, setGallupArray] = useState([]);
    const [domainData, setDomainData] = useState([]);
	const [placeNameDict, setPlaceNameDict] = useState({});

	const domainColors = {
		executing: '#7b2481',
		influencing: '#e97200',
		relationship_building: '#0070cd',
		strategic_thinking: '#00945d'
	};

    useEffect(() => {
        if (testResults.hasOwnProperty('Gallup')) {
            const sortedArray = Object.entries(testResults.Gallup)
                .map(([name, { place, domain, domain_order }]) => ({
                    name,
                    place,
                    domain,
                    domain_order
                }))
                .sort((a, b) => a.domain_order - b.domain_order);

            setGallupArray(sortedArray);

            // Create dictionary where key is place and value is name of theme
            const placeNameDict = sortedArray.reduce((acc, { name, place, domain}) => {
                acc[place] = {name, domain}; // Add domain to the dictionary
                return acc;
            }, {});

            // Set the dictionary state
            setPlaceNameDict(placeNameDict);
        }
        if (testResults.hasOwnProperty('Domains')) {
            const sortedDomains = Object.entries(testResults.Domains)
                .map(([name, value]) => ({ name, value }))
                .sort((a, b) => b.value - a.value); // Sort by value descending

            setDomainData(sortedDomains);
        }
    }, [testResults]);

    const executingDiv = useRef(null);
    const influencingDiv = useRef(null);
    const relationshipDiv = useRef(null);
    const strategicDiv = useRef(null);

    useEffect(() => {
        if (executingDiv.current) executingDiv.current.innerHTML = '';
        if (influencingDiv.current) influencingDiv.current.innerHTML = '';
        if (relationshipDiv.current) relationshipDiv.current.innerHTML = '';
        if (strategicDiv.current) strategicDiv.current.innerHTML = '';

        gallupArray.forEach((testResult) => {
            const div = document.createElement('div');
            const { place, domain, name } = testResult;

            if (domain.toLowerCase() === "executing") {
                div.className = place > 12
                    ? 'col-span-1 flex flex-col text-center p-3 border-solid border-2 bg-[#ebdcec]' 
                    : 'col-span-1 text-white flex flex-col text-center p-3 border-solid border-2 bg-[#7b2481]';
                
                div.innerHTML = `
                    <span>${place}</span>
                    <span>${name}</span>
                `;
                if (executingDiv.current) executingDiv.current.appendChild(div);
            } else if (domain.toLowerCase() === "influencing") {
                div.className = place > 12 
                    ? 'col-span-1 flex flex-col text-center p-3 border-solid border-2 bg-[#fcead9]' 
                    : 'col-span-1 text-white flex flex-col text-center p-3 border-solid border-2 bg-[#e97200]';
                
                div.innerHTML = `
                    <span>${place}</span>
                    <span>${name}</span>
                `;
                if (influencingDiv.current) influencingDiv.current.appendChild(div);
            } else if (domain.toLowerCase() === "relationship building") {
                div.className = place > 12 
                    ? 'col-span-1 flex flex-col text-center p-3 border-solid border-2 bg-[#e0e8f5]' 
                    : 'col-span-1 text-white flex flex-col text-center p-3 border-solid border-2 bg-[#0070cd]';
                
                div.innerHTML = `
                    <span>${place}</span>
                    <span>${name}</span>
                `;
                if (relationshipDiv.current) relationshipDiv.current.appendChild(div);
            } else if (domain.toLowerCase() === "strategic thinking") {
                div.className = place > 12
                    ? 'col-span-1 flex flex-col text-center p-3 border-solid border-2 bg-[#d8eee5]' 
                    : 'col-span-1 text-white flex flex-col text-center p-3 border-solid border-2 bg-[#00945d]';
                
                div.innerHTML = `
                    <span>${place}</span>
                    <span>${name}</span>
                `;
                if (strategicDiv.current) strategicDiv.current.appendChild(div);
            }
        });
    }, [gallupArray]);


    const handleLinkClick = (index) => {
        setActiveLink(index);
    };

    return (
        <>
            <div className='flex flex-col text-left'>
                {domainData.length > 0 && (
                    <span className='text-[28px]'>Вы лидируете с темами ClifftonStrengths в области <span className='font-[700]'>{domainData[0].name.replace('_', ' ')}</span></span>
                )}
                <span className='my-4'>
                    Вы обладаете ведущими темами ClifftonStrengths в областях
                </span>
                <div className='flex justify-between *:w-full gap-1'>
                    {domainData.map(domain => (
                        <div key={domain.name} className='flex flex-col' style={{ width: `${domain.value}%` }}>
                            <span className={`p-4 bg-${
                                domain.name.toLowerCase() === 'executing' ? '[#7b2481]' :
                                domain.name.toLowerCase() === 'influencing' ? '[#e97200]' :
                                domain.name.toLowerCase() === 'relationship_building' ? '[#0070cd]' :
                                domain.name.toLowerCase() === 'strategic_thinking' ? '[#00945d]' : ''
                            }`}></span>
                            {domain.name.replace('_', ' ')}
                        </div>
                    ))}
                </div>
            </div>
            <div className='flex flex-col italic my-2 text-justify'>
                Эта диаграмма показывает относительное распределение ваших уникальных результатов ClifftonStrengths 34 по четырем доменам. Эти категории являются хорошей отправной точкой для анализа ваших наибольших потенциальных возможностей для достижения отличных результатов и понимания того, как вы можете наилучшим образом внести вклад в работу команды.
                <span className='mt-3'> Посмотрите диаграмму ниже, чтобы узнать больше о ваших ClifftonStrengths по доменам.</span>
            </div>
            <div className='flex flex-col my-2 text-[24px] font-[700]'>
                <span className='mt-3'>Ваши ClifftonStrengths по доменам</span>
            </div>
            <div className='flex flex-col lg:flex lg:flex-row justify-between my-2 *:w-full gap-1 text-[12px]'>
                <div className='flex flex-col'>
                    <span className='text-center font-[700] uppercase'>executing</span>
                    <span className='py-1 bg-[#7b2481]'></span>
                    <div className='grid grid-cols-2 mt-2 gap-1' ref={executingDiv}></div>
                </div>
                <div className='flex flex-col'>
                    <span className='text-center font-[700] uppercase'>influencing</span>
                    <span className='py-1 bg-[#e97200]'></span>
                    <div className='grid grid-cols-2 mt-2 gap-1' ref={influencingDiv}></div>
                </div>
                <div className='flex flex-col'>
                    <span className='text-center font-[700] uppercase'>relationship building</span>
                    <span className='py-1 bg-[#0070cd]'></span>
                    <div className='grid grid-cols-2 mt-2 gap-1' ref={relationshipDiv}></div>
                </div>
                <div className='flex flex-col'>
                    <span className='text-center font-[700] uppercase'>strategic thinking</span>
                    <span className='py-1 bg-[#00945d]'></span>
                    <div className='grid grid-cols-2 mt-2 gap-1' ref={strategicDiv}></div>
                </div>
            </div>
            <div className='flex flex-col text-left'>
                <span className='text-[32px]'>Ваши лучшие ClifftonStrengths</span>
            </div>
			<div className='flex flex-col lg:flex lg:flex-row justify-between my-2 *:w-full gap-1 text-[14px]'>
			<div className='lg:col-span-1 flex flex-col items-start text-left'>
				{Object.keys(placeNameDict).slice(0, 6).map((key, index) => {
					const domain = placeNameDict[key].domain.toLowerCase();
					const color =
						domain === 'executing' ? '#7b2481' :
						domain === 'influencing' ? '#e97200' :
						domain === 'relationship building' ? '#0070cd' :
						domain === 'strategic thinking' ? '#00945d' : '#3B3B3B';

					return (
						<div key={index} className='flex my-2 items-stretch'>
							<div className='h-full w-5 mr-2' style={{ backgroundColor: color }}></div>
							<div className='flex flex-col'>
								<span className='font-[600]' style={{ color }}>{index + 1}. {placeNameDict[key].name}</span>
								<span className='uppercase mt-2' style={{ color }}>{placeNameDict[key].domain}</span>
								<div>
									{themes_descriptions[placeNameDict[key].name]}
								</div>
							</div>
						</div>
					);
				})}
			</div>
			<div className='lg:col-span-1 mt-2 lg:mt-0 flex flex-col items-start text-left'>
				{Object.keys(placeNameDict).slice(6, 12).map((key, index) => {
					const domain = placeNameDict[key].domain.toLowerCase();
					const color =
						domain === 'executing' ? '#7b2481' :
						domain === 'influencing' ? '#e97200' :
						domain === 'relationship building' ? '#0070cd' :
						domain === 'strategic thinking' ? '#00945d' : '#3B3B3B';

					return (
						<div key={index} className='flex my-2 items-stretch'>
							<div className='h-full w-5 mr-2' style={{ backgroundColor: color }}></div>
							<div className='flex flex-col'>
								<span className='font-[600]' style={{ color }}>{index + 7}. {placeNameDict[key].name}</span>
								<span className='uppercase mt-2' style={{ color }}>{placeNameDict[key].domain}</span>
								<div>
									{themes_descriptions[placeNameDict[key].name]}
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
        </>
    );
}

export default ClifftonStrength;