import React from 'react'
import requestImage from '../../assets/images/request-image.png';
import '../../assets/css/requestBlock.css';
import './RequestBlock.component.css';
import Container from "../Container";
import {useTranslation} from "react-i18next";


const RequestBlock = ({showModal}) => {
    const {t, i18n} = useTranslation('translation', {keyPrefix: 'main.request'})

    return (
        <div className={`relative md:pt-20 xl:pt-0`}>
            <Container>
                <div
                    className={`xl:size-[370px] md:size-[300px] sm:size-[250px] size-[200px] bg-[#EAF8FC] rounded-full absolute
					  qhd:left-[30em]
					  fhd:left-[12em]
					2xl:top-[4em] xl:top-[2em]
					2xl:left-[7em] xl:left-0
					lg:top-[-3em] lg:left-10
					md:top-[-2em] md:left-[2em]
					sm:top-[0em] sm:left-[1em]
					top-[2.5em] left-0`}></div>
                <div
                    className={`size-[140px] bg-[#50D3FF] rounded-full absolute 2xl:block hidden
                    bottom-[3.5em] right-[6em] fhd:right-[10em] qhd:right-[30em]`}></div>
                <div
                    className={`size-[23px] bg-[#47DAAC] rounded-full absolute 2xl:block hidden
                    bottom-[12.5em]  right-[4em] fhd:right-[10em] qhd:right-[30em]`}></div>
                <div className="flex flex-col py-[6em]  w-full select-none">
                    <span
                        className='z-[10] pl-[50%] md:pl-[45%] lg:pl-[345px] font-bold text-[18px] sm:text-[24px] md:text-[30px] lg:text-[40px] leading-[21.15px] md:leading-[30px] lg:leading-[40px] xl:leading-[47px] text-[#0B0E62] text-left xl:py-10 py-4'>
                        {t('title.0')}<br className={`sm:block hidden`}/> {t('title.1' +
                        '')}</span>
                    <div
                        className="relative xl:py-10 py-4 xl:px-0 px-4 rounded-[10px] bg-[#393DA1] flex flex-col xl:flex-row xl:gap-0 gap-4 bluishLogoBg">
                        <img
                            className='absolute xl:bottom-0 bottom-full left-[1em] sm:left-[2em] block object-cover xl:h-[22em] md:h-[16em] sm:h-[12em] h-[154px] pointer-events-none'
                            src={requestImage}/>
                        <div className={`3xl:w-[27%] xl:w-[29%] xl:block hidden w-0 FILLER-DIV`}></div>
                        <div
                            className="flex justify-start 3xl:w-[43%] xl:w-[41%] w-full xl:ps-0 ps-4">
                            <ul
                                className="flex flex-col justify-center text-left text-[12px] md:text-[16px] leading-[19.2px] md:leading-[25.6px] text-[#DDDEF3]">
                                <li className={`relative w-fit before:block before:bg-[#DDDEF3] before:rounded-full before:size-[3px]
                                before:absolute before:top-1/2 before:-translate-y-1/2 before:left-[-.9em]`}>
                                    {t('reasons.0')}
                                </li>
                                <li className={`relative w-fit before:block before:bg-[#DDDEF3] before:rounded-full before:size-[3px]
                                before:absolute before:top-1/2 before:-translate-y-1/2 before:left-[-.9em]`}>
                                    {t('reasons.1')}
                                </li>
                                <li className={`relative w-fit before:block before:bg-[#DDDEF3] before:rounded-full before:size-[3px]
                                before:absolute before:top-1/2 before:-translate-y-1/2 before:left-[-.9em]`}>
                                    {t('reasons.2')}
                                </li>
                                <li className={`relative w-fit before:block before:bg-[#DDDEF3] before:rounded-full before:size-[3px]
                                before:absolute before:top-1/2 before:-translate-y-1/2 before:left-[-.9em]`}>
                                    {t('reasons.3')}
                                </li>
                            </ul>
                        </div>
                        <div className="flex items-center justify-end xl:pe-20 xl:w-[30%] w-full">
                            <button
                                className="text-white xl:w-auto w-full font-bold md:leading-[23.5px] leading-[16.45px] md:text-[20px] text-[14px] bg-[#50D3FF] hover:bg-[#50D3FF]/80 transition-colors rounded-[10px] py-[13px] px-[35px]"
                                onClick={showModal}>{t('leaveRequestButton')}
                            </button>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default RequestBlock;
