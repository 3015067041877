import React from 'react';
import {CalendarIcon} from "@heroicons/react/24/solid";
import ArticleFragment from "./ArticleFragment";
import WhitePanel from "./WhitePanel";

function NewsArticle({
                         heading = 'heading',
                         heroProvided,
                         heroImage,
                         heroDate = 'postDate',
                         articleFragmentsList
                     }) {
    return (
        <div
            className="xl:basis-[70%]">
            <WhitePanel gap={4} padding={8}>
            <span
                className='text-[20px] md:text-[36px] leading-[23.5px] md:leading-[42.3px] font-bold text-[#1B1818]'>{heading}</span>
                <div className={`flex flex-col gap-1 ${heroProvided ? 'block' : 'hidden'}`}>
                    {heroImage ?
                        <img src={heroImage}
                             className='rounded-[12px] pointer-events-none select-none w-full 3xl:w-4/5 object-cover mx-auto'/> :
                        <div className={`bg-gray-300 animate-pulse w-full h-[50vh] rounded-[12px]`}></div>}
                    <div className='select-none flex-row gap-2 items-center w-fit hidden'>
                        <CalendarIcon className="text-[#3E3232]/50 size-[14px]"/>
                        <span className={`font-[500] font-roboto text-[14px] text-[#3E3232]/75`}>{heroDate}</span>
                    </div>
                </div>
                {
                    articleFragmentsList.map((fragment, idx) => {
                        return <ArticleFragment
                            fragmentName={fragment.name}
                            fragmentContent={fragment.content}
                            fragmentImage={fragment.image}
                            hasImage={fragment.hasImage}
                            key={idx}/>
                    })
                }
            </WhitePanel>
        </div>
    );
}

export default NewsArticle;